import * as React from 'react';
import { useContext } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { prettifyNumber } from 'helper';


import MobileContext from 'pages/Layout/MobileContext';
import { ApproveForm } from 'pages/PageClient/Single/Decision/components/ApproveTab/ApproveForm';
import { ILimitProps } from 'pages/PageClient/Single/Decision/components/ApproveTab/types';
import {useDictionaries} from "models/dictionaries/hooks";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  gap: 24px;
`;

interface IComponentProps {
  decisionHasBeenMade: boolean;
  isAbleToSend: boolean;
  isLoading: boolean;
  isResultsComplete: boolean;
  verification: any;
  verificationId: number;
}

export const ApproveTab: React.FC<IComponentProps> = ({
  decisionHasBeenMade,
  isAbleToSend,
  isLoading,
  verification,
  verificationId,
}) => {
  const [isDictionariesLoading, dictionaries] = useDictionaries([`verificationLimit`]);

  // @ts-ignore
  const limitProps:ILimitProps = _.reduce(dictionaries?.verificationLimit, (res, limit) => {
    _.each([
      { key: `amount`, unit: `₽` },
      { key: `term`, unit: `мес.` },
    ], ({ key, unit }) => {
      if (limit.name === key) {
        // @ts-ignore
        res[`${key}MinRaw`] = limit.min;
        // @ts-ignore
        res[`${key}MaxRaw`] = limit.max;
        // @ts-ignore
        res[`${key}Min`] = `от ${prettifyNumber(limit.min)} ${unit}`;
        // @ts-ignore
        res[`${key}Max`] = `до ${prettifyNumber(limit.max)} ${unit}`;
      }
    });
    return res;
  }, {});

  const mobile = useContext(MobileContext);

  const approveFormContent = (
    <ApproveForm
      decisionHasBeenMade={decisionHasBeenMade}
      isAbleToSend={isAbleToSend}
      isLoading={isLoading || isDictionariesLoading}
      limitProps={limitProps}
      verification={verification}
      verificationId={verificationId}
    />
  );

  return mobile
    ? approveFormContent
    : <Wrapper>{approveFormContent}</Wrapper>;
};
