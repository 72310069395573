import React from 'react';
import styled from 'styled-components';

import { copyToClipboard } from 'helper';

import { CopyOutlined } from '@ant-design/icons';
import MttPhoneButton from 'components/MttPhoneButton';
import { palette } from "styles/theme";

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-weight: 600;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  width: calc(100% + 8px);
  align-items: center;
  margin-left: -8px;
  margin-bottom: 8px;
  min-height: 32px;
`;

export const CopyIcon = styled(CopyOutlined)`
  color: ${palette.primaryColor};
  opacity: 0;
  transition: opacity, .2s ease-out;
  cursor: pointer;
  position: absolute;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Copy = ({ children }: { children: string }) => <CopyIcon onClick={() => copyToClipboard(children)} />;

export const Col = styled.div`
  flex: 1;
  flex-basis: 25%;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;

  &:hover {
    ${CopyIcon} {
      opacity: 1;
    }
  }
`;

export const ColLabel = styled(Col)`
  flex-basis: 220px;
  max-width: 220px;
  min-width: 220px;
  font-weight: 600;
`;

export const Controls = styled.div`
  display: flex;
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const MttPhoneButtonStyled = styled(MttPhoneButton)`
  margin-left: 8px;
`;
