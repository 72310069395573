import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { Menu } from 'antd';

const mainColor = palette.primaryColor;

export const MenuDesktop = styled(Menu)`
  &.ant-menu {
    border: 0;
    line-height: 64px;
    height: 64px;
  }

  &.ant-menu-horizontal > .ant-menu-item,
  &.ant-menu-horizontal > .ant-menu-submenu-horizontal {
    border-bottom: solid 1px transparent !important;
    color: ${palette.black30Color};
    align-items: center;
    display: flex;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
      width: 1px;
      height: 24px;
      background-color: ${getLightStyleOr(palette.black20Color, palette.black30Color)};
    }
  }

  & > .ant-menu-item,
  & > .ant-menu-submenu-horizontal {
    color: rgba(0, 0, 0, 0.85);
  }

  & > * > .ant-menu-submenu-title,
  & > * > .ant-menu-submenu-title:hover {
    color: inherit;
  }

  & > * > .ant-menu-submenu-title > .anticon {
    display: block;
    margin-right: 0;
    margin-bottom: 2px;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: ${mainColor};
    border-bottom-color: ${mainColor};

    &::after {
        border-bottom: 2px solid ${mainColor} !important;
    }

    > .ant-menu-title-content {
      color: ${mainColor};
    }}

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a:hover,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected a,
  & > .ant-menu-item-selected,
  & > .ant-menu-item-selected a {
     color: ${mainColor};
  }

  > .ant-menu-item a {
    font-weight: 500;
    color: ${getLightStyleOr(palette.black80Color, palette.black40Color)};
  }
`;
