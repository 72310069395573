import React, { useState } from 'react';
import ExcelJS from 'exceljs';

import api from 'api/index';
import {
  downloadBlob,
  getPlural,
  validateUuid,
} from 'helper';

import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  notification,
  Progress,
  Space,
  Upload,
} from 'antd';
import _ from "lodash";

interface Person {
  applicationId: string;
  name: string;
  personId: string;
}

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

const DocumentBatchDownloadModal: React.FC<IProps> = ({ isVisible, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [persons, setPersons] = useState<Person[]>([]);

  const parseExcelFile = async (f: File) => {
    const workbook = new ExcelJS.Workbook();
    // @ts-ignore
    await workbook.xlsx.load(f);
    const worksheet = workbook.getWorksheet(1);
    const result: Person[] = [];

    const columnHeaderMap: { [key: string]: number } = {
      applicationid: -1,
      name         : -1,
      personid     : -1,
    };

    // @ts-ignore
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        // @ts-ignore
        const columnHeader = cell.value.toString().toLowerCase();
        if (columnHeader in columnHeaderMap) {
          columnHeaderMap[columnHeader] = colNumber;
        }
      });

      const { applicationid, name, personid } = columnHeaderMap;
      if (name !== -1 && personid !== -1 && rowNumber !== 1) {
        const applicationId = applicationid !== -1 ? (row.getCell(applicationid).value ?? ``).toString() : ``;
        const nameValue = row.getCell(name).value || ``;
        const personIdValue = row.getCell(personid).value || ``;
        if (!validateUuid(personIdValue)) {
          return notification.warning({
            message    : `Ошибка парсинга`,
            description: `Не удалось получить personId из ${nameValue} ${personIdValue} в строке ${rowNumber}, строка не будет обработана`, // eslint-disable-line max-len
          });
        }

        if (applicationId && !validateUuid(applicationId)) {
          notification.warning({
            message    : `Ошибка парсинга`,
            description: `Не удалось получить applicationId из ${nameValue} ${applicationId} в строке ${rowNumber}, для этой строки загрузка будет по personId`, // eslint-disable-line max-len
          });
          return result.push({
            applicationId: ``,
            name         : nameValue.toString(),
            personId     : personIdValue.toString(),
          });
        }

        result.push({
          applicationId,
          name    : nameValue.toString(),
          personId: personIdValue.toString(),
        });
      }
    });

    return result;
  };

  const handleUploadChange = async (info: { file: { originFileObj: React.SetStateAction<File | null>; }; }) => {
    // @ts-ignore
    const parsedPersons = await parseExcelFile(info.file.originFileObj);
    setPersons(parsedPersons);
    setFile(info.file.originFileObj);
    setProgress(0);
  };

  const handleDownload = async (person: Person):Promise<boolean> => {
    const { applicationId, name, personId } = person;
    try {
      const endpoint = applicationId
        ? `/proxy/document/all/application/${applicationId}`
        : `/proxy/document/all/person/${personId}`;
      const { data } = await api.get(endpoint, { responseType: `blob` });
      downloadBlob(data, `${name} ${personId}${applicationId ? ` ${applicationId}` : ``}.zip`);
      return true;
    } catch (e) {
      notification.error({
        message    : `Ошибка загрузки`,
        description: `Не удалось загрузить документы для ${name} ${personId}`,
      });
      return false;
    }
  };

  const startDownload = async () => {
    setIsLoading(true);
    const total = persons.length;
    let success = 0;
    for (const person of persons) {
      if (await handleDownload(person)) success += 1;
      setProgress(prevProgress => prevProgress + 1);
    }
    notification.open({
      type       : total === success ? `success` : `warning`,
      message    : `Выгрузка завершена`,
      description: `Выгружены документы по ${success} из ${total} ${getPlural(total, `клиенту`, `клиентам`, `клиентам`)}.`, // eslint-disable-line max-len
      duration   : 0,
    });
    setIsLoading(false);
    setProgress(0);
    setFile(null);
    setPersons([]);
  };

  return (
    <Modal
      cancelText={isLoading ? `Устал ждать, в печь` : `Отмена`}
      centered
      okButtonProps={{ disabled: isLoading || !persons.length }}
      okText={`Начать загрузку (${persons.length} ${getPlural(persons.length, `клиент`, `клиента`, `клиентов`)})`}
      onCancel={() => onClose()}
      onOk={startDownload}
      open={isVisible}
      title='Массовая выгрузка документов'
    >
      <Space>В файле должны быть заголовки name, personId, applicationId</Space>
      <Space>(порядок неважен, applicationId опциональный как по файлу так и по строке)</Space>
      <Space>Файлы будут загружаться по одному</Space>
      <Space>Рекомендуется включить в браузере разрешение на загрузку нескольких файлов</Space>
      <Space>и отключить запрос места сохранения для каждого файла,</Space>
      <Space>выставив директорию для загрузки по умолчанию</Space>
      <Space />
      {/* @ts-ignore */}
      <Upload
        accept='.xlsx, .csv'
        customRequest={_.noop}
        // @ts-ignore
        fileList={file ? [file] : []}
        onChange={handleUploadChange}
        showUploadList={false}
      >
        <Button
          icon={<DownloadOutlined />}
          style={{ margin: `16px 0` }}
        >
          Загрузите .xlsx или .csv файл со списком клиентов.
        </Button>
      </Upload>
      {persons.length > 0 && <Progress percent={(progress / persons.length) * 100} />}
    </Modal>
  );
};

export default DocumentBatchDownloadModal;
