import * as React from 'react';
import { useContext } from 'react';
import { useSelector } from 'dva';

import { copyToClipboard } from 'helper';

import {
  getClientApplicationsSize as getClientApplicationsSizeSelector,
  getClientFullName as getClientFullNameSelector,
} from 'models/clients/selectors';

import useMobileContext from 'hooks/useMobileContext';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import ClientSubHeaderDesktop from 'pages/PageClient/Single/components/ClientSubHeader/ClientSubHeaderDesktop';
import ClientSubHeaderMobile from 'pages/PageClient/Single/components/ClientSubHeader/ClientSubHeaderMobile';

interface IProps {
  isReloading?: boolean;
  onReload?(): void;
}

const ClientSubHeader: React.FC<IProps> = ({ isReloading, onReload }) => {
  const { mobile } = useMobileContext();
  const { personId } = useContext(ClientMatchContext);
  const fullName = useSelector(state => getClientFullNameSelector(state, personId));
  const applicationsSize = useSelector(state => getClientApplicationsSizeSelector(state, personId));

  return mobile ? (
    <ClientSubHeaderMobile
      applicationsSize={applicationsSize}
      fullName={fullName}
      isReloading={isReloading}
      onReload={onReload}
    />
  ) : (
    <ClientSubHeaderDesktop
      applicationsSize={applicationsSize}
      fullName={fullName}
      isReloading={isReloading}
      onCopyPersonId={() => copyToClipboard(personId)}
      // @ts-ignore
      onReload={onReload}
    />
  );
};

export default ClientSubHeader;
