import _ from 'lodash';
import qs from 'query-string';

import api from 'api/index';

import {
  EXTERNAL,
  EXTERNAL_LINK_URLS,
  NAMES,
} from './constants';

const getApiName = name => _.get(NAMES, name, name);

export const downloadTemplateById = templateId => api.get(
  `/proxy/dictionaries/document-template/v1/${templateId}`,
  { responseType: `blob`, validateStatus: () => true },
);
export const downloadTemplates = templateIds => api.get(
  `/proxy/dictionaries/document-template/batch?${qs.stringify({ id: templateIds })}`,
  { responseType: `blob`, validateStatus: () => true },
);
export const get = entity => api.get(`/dictionaries/${getApiName(entity)}`);
export const getExternal = entity => api.get(`/proxy/${EXTERNAL[entity]}`);
export const getSingle = (entity, id) => api.get(`/dictionaries/${getApiName(entity)}/${id}`);
export const linkExternal = (entity, data) => api.post(`/proxy/${EXTERNAL_LINK_URLS[entity]}`, data);
export const unlinkExternal = (entity, data) => api.post(`/proxy/${EXTERNAL_LINK_URLS[entity]}/delete`, data);
export const postExternal = (entity, data) => api.post(`/proxy/${EXTERNAL[entity]}`, data);
export const postExternalCustomPath = (path, data) => api.post(path, data);
export const remove = (entity, ids) => api.delete(`/dictionaries/${getApiName(entity)}`, { data: { ids } });
export const removeSingle = (entity, id) => api.delete(`/dictionaries/${getApiName(entity)}/${id}`);
export const create = (entity, data) => api.post(`/dictionaries/${getApiName(entity)}`, data);
export const update = (entity, id, data) => api.patch(`/dictionaries/${getApiName(entity)}/${id}`, data);
export const uploadExternal = (path, file) => {
  const formData = new FormData();
  formData.append(`file`, file);
  return api.post(path, formData, { headers: { 'Content-Type': `multipart/form-data` } });
};

