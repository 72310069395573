import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  formatDate,
} from 'helper';

import { getClientApplicationsForCreditHistory } from 'models/clients/selectors';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  Button,
  Select,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const ControlsSelect = styled(Select)`
  flex: 1;
`;

const StyledControls = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    ${ControlsSelect} {
      overflow: hidden;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  padding: 4px 8px;
  border: 0;
  background-color: ${palette.black10Color};
  color: ${palette.textSecondaryColor};

  .anticon,
  svg {
    display: block;
  }
`;

const Controls = ({
  applicationId,
  isGroupingEnabled,
  personId,
  setApplicationId,
  toggleGroupingEnabled,
}) => {
  const applications = useSelector(state => getClientApplicationsForCreditHistory(state, personId));
  return (
    <StyledControls>
      <ControlsSelect onChange={setApplicationId} value={applicationId}>
        {_.map(applications, application => (
          <Select.Option key={application.id} value={application.id}>
            {`${application.id} от ${formatDate(application.applicationDtm)}, статус: ${(application.applicationStatusName)}`} {/* eslint-disable-line max-len */}
          </Select.Option>
        ))}
      </ControlsSelect>
      <DefaultTooltip title={isGroupingEnabled ? `Выкл. свод КИ` : `Вкл. свод КИ`}>
        <StyledButton
          icon={isGroupingEnabled ? (<DownOutlined />) : (<UpOutlined />)}
          onClick={() => toggleGroupingEnabled()}
        />
      </DefaultTooltip>
    </StyledControls>
  );
};

export default Controls;
