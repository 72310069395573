import TEST_ATTRS from 'constants/TEST_ATTRIBUTES';

import { ROLE_NAMES } from 'models/roles/constants';

export const NAV = [
  {
    name         : `Клиенты`,
    path         : `/client`,
    testAttribute: TEST_ATTRS.NAV_BAR_CLIENTS_TAB,
  },
  {
    name : `Верификация`,
    path : `/verification`,
    roles: [
      ROLE_NAMES.VERIFICATION_READ,
      ROLE_NAMES.VERIFICATION_SEND,
      ROLE_NAMES.VERIFICATION_WRITE,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_VERIFICATION_TAB,
  },
  {
    name : `Администрирование`,
    path : `/admin`,
    roles: [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SMS_GATEWAY_OPERATOR,
      ROLE_NAMES.SMS_LIST_SENDER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_ADMIN_TAB,
  },
];
