import * as React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { hasIntersection } from 'helper';

import {
  resetSms as resetSmsAction,
  switchProfile as switchProfileAction,
} from 'models/clients/actions';
import {
  getIsLoadingSwitchProfile as isLoadingSwitchProfileSelector,
  getIsResetSmsLoading as isLoadingResetSmsSelector,
} from 'models/clients/selectors';
import { ILoanClient } from 'models/clients/types';
import { ROLE_NAMES } from 'models/roles/constants';
import { setModal as setModalAction } from 'models/ui/actions';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { usePath } from 'hooks/usePath';

import {
  AuditOutlined,
  ExportOutlined,
  ImportOutlined,
  LoadingOutlined,
  MailOutlined,
  RedoOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { MenuProps } from 'antd';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';
import { EntityCardTitleControlWrapper } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

const Wrapper = styled.div``;

const SettingIcon = styled(SettingOutlined)`
  font-size: 18px;
`;

const IconWrapper = styled(EntityCardTitleControlWrapper)`
  padding-right: 0;
`;

interface IComponentProps {
  client: ILoanClient;
}

export const ClientItemDropdown: React.FC<IComponentProps> = ({ client }) => {
  const dispatch = useDispatch();

  const { pushPath } = usePath();

  const isLoadingResetSms = useSelector(isLoadingResetSmsSelector);

  const isLoadingSwitchProfile = useSelector(isLoadingSwitchProfileSelector);
  const userRoles = useSelector(getRoleNamesSelector);

  const canSendSms = hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.SMS_SENDER]);
  const canSwitchProfile = hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.CLIENT_PROFILE_CLOSER]);

  const { lkAccessFlg, personId } = client || {};

  const menuItems = [
    {
      label   : `Сбросить счетчик SMS`,
      key     : `reset-sms`,
      disabled: isLoadingResetSms,
      icon    : isLoadingResetSms ? <LoadingOutlined /> : <RedoOutlined />,
    },
    {
      label : `Отправить SMS`,
      key   : `send-sms`,
      hidden: !canSendSms,
      icon  : <MailOutlined />,
    },
    {
      hidden  : !canSwitchProfile,
      key     : `switch-profile`,
      danger  : lkAccessFlg,
      disabled: isLoadingSwitchProfile,
      icon    : isLoadingSwitchProfile
        ? <LoadingOutlined />
        : lkAccessFlg
          ? <ExportOutlined />
          : <ImportOutlined />,
      label: lkAccessFlg ? `Закрыть ЛК` : `Открыть ЛК`,
    },
    {
      key : `divider-1`,
      type: `divider`,
    },
    {
      label: `Искать на верификации`,
      key  : `search-on-verification`,
      icon : <AuditOutlined />,
    },
  ];

  const resetSms = () => dispatch(resetSmsAction({ personId }));
  const openSendSmsModal = () => dispatch(setModalAction({ personId, isOpen: true, modalName: `sendSms` }));
  const switchProfile = (accessFlg: boolean) => dispatch(switchProfileAction({ accessFlg, personId }));

  const handleMenuClick: MenuProps[`onClick`] = e => {
    const { key } = e;

    switch (key) {
      case `reset-sms`: {
        return resetSms();
      }
      case `send-sms`: {
        return openSendSmsModal();
      }
      case `switch-profile`: {
        return switchProfile(!lkAccessFlg);
      }
      case `search-on-verification`: {
        return pushPath(`/verification?personId=${personId}`);
      }
      default: {
        return _.noop();
      }
    }
  };

  return (
    <Wrapper>
      <DefaultDropdown
        // @ts-ignore
        menu={{ items: menuItems, onClick: handleMenuClick }}
        trigger={[`click`]}
      >
        <IconWrapper>
          <SettingIcon />
        </IconWrapper>
      </DefaultDropdown>
    </Wrapper>
  );
};
