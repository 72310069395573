import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  formatDate,
  getColumn,
  getSort,
  prettifyAmount,
} from 'helper';

import { getClientLoanOperations as getClientLoanOperationsAction } from 'models/clients/actions';
import {
  getClientLoanOperations as getClientLoanOperationsSelector,
  getIsLoading as getIsLoadingSelector,
} from 'models/clients/selectors';
import { IOperation } from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';

import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

// @ts-ignore
const COLUMNS = ({ dictionaries }) => _.map([
  {
    defaultSortOrder: `descend`,
    key             : `createDtm`,
    title           : `Дата и время операции`,
    render          : (text: any) => formatDate(text),
    sorter          : getSort(`createDtm`),
    width           : 100,
  },
  {
    key  : `operationId`,
    title: `ID`,
    width: 140,
  },
  {
    key   : `sum`,
    title : `Сумма`,
    render: prettifyAmount,
    width : 100,
  },
  {
    key   : `operationStatusId`,
    title : `Статус`,
    render: id => _.upperFirst(_.find(dictionaries.operationStatus, { id })?.name || `-`),
    width : 140,
  },
  {
    key   : `externalOperationCode`,
    title : `Код от внешней системы`,
    width : 140,
    render: code => _.find(dictionaries.operationCode, { code })?.description || code || `-`,
  },
  {
    key   : `paymentMethodId`,
    title : `Тип`,
    width : 80,
    render: (id: any) => _.find(dictionaries.paymentMethod, { id })?.name || `-`,
  },
], getColumn);

const PREFERENCES = { path: `client.loans.operations` };

interface IProps {
  filter?: (operations: IOperation[]) => any[];
  loanId: string;
  personId: string;
}

const LoanOperations: React.FC<IProps> = ({ filter, loanId, personId }) => {
  const dispatch = useDispatch();

  const operationsUnfiltered = useSelector(state => getClientLoanOperationsSelector(state, personId, loanId));
  useEffect(() => {
      if (_.isEmpty(operationsUnfiltered) && personId && loanId) {
        dispatch(getClientLoanOperationsAction({ personId, loanId }));
      }
    },
    [loanId, personId, _.size(operationsUnfiltered)],
  );
  const isClientLoading = useSelector(getIsLoadingSelector);

  const [isDictionariesLoading, dictionaries] = useDictionaries([
    `paymentMethod`,
    `operationCode`,
    `operationStatus`,
  ]);

  const isLoading = isDictionariesLoading || isClientLoading;

  const operations = _.isFunction(filter)
    ? filter(operationsUnfiltered)
    : operationsUnfiltered;

  return (
    <AdjustableTable
      columns={COLUMNS({ dictionaries })}
      dataSource={operations}
      loading={isLoading}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(operations),
        size            : `small`,
      }}
      preferences={PREFERENCES}
      scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
    />
  );
};

export default LoanOperations;
