export const SALE_AVAILABLE_STATUS_NAMES = [
  `Itog`,
  `Sogl_viezdnogo`,
  `Sogl_majaka`,
  `Sogl_uslovij`,
];

export const SALE_STATUS_NAMES = [
  `done`,
  `perezaprosKI`,
  ...SALE_AVAILABLE_STATUS_NAMES,
];

