import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { EThemeName } from 'styles/GlobalThemeContext';

import {
  getCurrent as getOperatorAction,
  savePreferences as savePreferencesAction,
} from 'models/operators/actions';
import {
  getCurrentNotificationLists as getNotificationLists,
  getCurrentPreferences as getPreferences,
  isLoading as isLoadingSelector,
} from 'models/operators/selectors';
import { getRolesTitle as getRolesTitleSelector } from 'models/user/selectors';

import { useStatePreference } from 'hooks/useStatePreference';

import {
  BorderBottomOutlined,
  BorderTopOutlined,
  PushpinOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  List,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
} from 'antd';

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NotificationRow = styled(List.Item)`
  display: flex;
  flex-wrap: nowrap;
`;

const NotificationDesc = styled.span`
  flex-grow: 1;
  word-break: break-word;
`;

const ToggleButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 6px;
`;

const StyledSelect = styled(Select)`
  width: 250px;
`;

const StyledOption = styled(Select.Option)`
  display: flex;
`;

const OptionText = styled.span`
  margin-left: 8px;
`;

const NOTIFICATION_PLACEMENTS = [
  {
    icon : RadiusUprightOutlined,
    label: `Сверху справа`,
    value: `topRight`,
  },
  {
    icon : BorderTopOutlined,
    label: `Сверху`,
    value: `top`,
  },
  {
    icon : RadiusUpleftOutlined,
    label: `Сверху слева`,
    value: `topLeft`,
  },
  {
    icon : RadiusBottomrightOutlined,
    label: `Снизу справа`,
    value: `bottomRight`,
  },
  {
    icon : BorderBottomOutlined,
    label: `Снизу`,
    value: `bottom`,
  },
  {
    icon : RadiusBottomleftOutlined,
    label: `Снизу слева`,
    value: `bottomLeft`,
  },
];

interface IComponentProps {
  visible: boolean;
  onClose(): void;
}

export const OperatorPreferences: React.FC<IComponentProps> = ({ onClose, visible }) => {
  const dispatch = useDispatch();

  const getOperator = useCallback(() => dispatch(getOperatorAction()), [dispatch]);

  const isLoading = useSelector(isLoadingSelector);
  const initialPreferences = useSelector(getPreferences);
  const notificationLists = useSelector(getNotificationLists);
  const userRolesTitle = useSelector(getRolesTitleSelector);

  const [preferences, setPreferences] = useState(initialPreferences);

  const [themeName, setThemeName] = useStatePreference(`ui.themeName`, EThemeName.LIGHT);

  const {
    importantNotificationList,
    placement: notificationPlacement = `topRight`,
    telegramBellNotificationList,
  } = preferences.notifications;

  const toggleNotification = (list: string, id: any) => () => {
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        [list]: _.includes(preferences.notifications[list], id)
          ? _.without(preferences.notifications[list], id)
          : [...preferences.notifications[list], id],
      },
    });
  };

  const onNotificationPlacementChange = (placement: any) => {
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        placement,
      },
    });
  };

  const savePreferences = () => {
    dispatch(savePreferencesAction({
      preferences: {
        ...preferences,
        ui: {
          ...preferences.ui,
          themeName,
        }
      },
      callback: async () => {
        onClose();
      },
    }));
    notification.config({ placement: preferences?.notifications?.placement || `topRight` });
  };

  useEffect(() => {
      if (visible) {
        getOperator();
      }
    },
    [getOperator, visible],
  );

  return (
    <Modal
      okButtonProps={{
        disabled: isLoading,
      }}
      okText='Сохранить'
      onCancel={() => {
        onClose();
        setPreferences(initialPreferences);
      }}
      onOk={savePreferences}
      open={visible}
      title='Настройки пользователя'
    >
      <Divider orientation='left'>Роли</Divider>
      <p>{userRolesTitle}</p>
      {!_.isEmpty(notificationLists) && (
        <>
          <Divider orientation='left'>Уведомления</Divider>
          <List
            dataSource={notificationLists}
            // @ts-ignore
            renderItem={({ description, id, isPersonal, name }) => (
              <NotificationRow key={id}>
                <NotificationDesc>
                  {!isPersonal ? description : `${name} (персональный тэг)`}
                </NotificationDesc>
                <Tooltip title='Звук в Telegram для уведомлений с этим тэгом'>
                  <ToggleButton
                    icon={<SoundOutlined />}
                    onClick={toggleNotification(`telegramBellNotificationList`, id)}
                    type={_.includes(telegramBellNotificationList, id) ? `primary` : `default`}
                  />
                </Tooltip>
                <Tooltip title='Уведомления с этим тэгом во вкладке "Важное"'>
                  <ToggleButton
                    icon={<PushpinOutlined />}
                    onClick={toggleNotification(`importantNotificationList`, id)}
                    type={_.includes(importantNotificationList, id) ? `primary` : `default`}
                  />
                </Tooltip>
              </NotificationRow>
            )}
          />
        </>
      )}
      <Divider orientation='left'>Системные уведомления</Divider>
      <StyledSelect onChange={onNotificationPlacementChange} value={notificationPlacement}>
        {_.map(NOTIFICATION_PLACEMENTS, ({ icon: Icon, label, value }) => (
          <StyledOption key={value} value={value}>
            <Icon />
            <OptionText>{label}</OptionText>
          </StyledOption>
        ))}
      </StyledSelect>
      {isLoading && (
        <StyledSpin />
      )}
      <Divider orientation='left'>Интерфейс</Divider>
      <StyledSelect onChange={setThemeName} value={themeName}>
        <StyledOption key='light' value={EThemeName.LIGHT}>Светлая тема</StyledOption>
        <StyledOption key='dark' value={EThemeName.DARK}>Темная тема</StyledOption>
      </StyledSelect>
    </Modal>
  );
};
