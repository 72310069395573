import * as React from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import styled from 'styled-components';

import {
  decline as declineAction,
  setDecision as setDecisionAction,
} from 'models/verifications/actions';

import {
  SaveOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Popconfirm,
} from 'antd';
import { DecisionFormWrapper } from 'pages/PageClient/Single/Decision/components/DecisionFormWrapper';
import { ActionMeta } from 'redux-actions';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

interface IFormValues {
  comment: string;
  declineReason: string;
}

interface IComponentProps {
  decisionHasBeenMade: boolean;
  isAbleToSend: boolean;
  isLoading: boolean;
  verification: any;
  verificationId: number;
}

export const DeclineForm: React.FC<IComponentProps> = ({
   decisionHasBeenMade,
   isAbleToSend,
   isLoading,
   verification,
   verificationId,
 }) => {
  const [form] = Form.useForm();

  const initialValues = {
    comment: _.get(verification, `comment`, ``),
    declineReason: _.get(verification, `declineReason`, ``),
  };

  const [formValues, setFormValues] = React.useState<IFormValues>(initialValues);

  const dispatch = useDispatch();

  const setDecision = (decision: { approved: boolean; comment: string; declineReason: string; }, callback?: (() => ActionMeta<any, any>) | undefined) => dispatch(setDecisionAction({ id: verificationId, decision, callback }));

  const decline = () => dispatch(declineAction({ id: verificationId }));

  const { declineReason } = formValues;
  const isButtonsDisabled = isLoading || !declineReason;

  const isSaveButtonDisabled = decisionHasBeenMade;

  const onFormChange = (formName: string | number, info: { forms: { [x: string]: { getFieldsValue: () => React.SetStateAction<IFormValues>; }; }; }) => {
    setFormValues(info.forms[formName].getFieldsValue());
  };

  const onSave = () => {
    setDecision({ ...formValues, approved: false });
  };

  const onSaveAndSend = () => {
    setDecision({ ...formValues, approved: false }, decline);
  };

  return (
    <DecisionFormWrapper>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          form={form}
          initialValues={initialValues}
          layout='vertical'
          name='PageClientSingleDecision'
          scrollToFirstError={{ behavior: `smooth` }}
          validateTrigger={[`onChange`, `onBlur`]}
        >

          <Form.Item label='Причина отказа' name='declineReason'>
            <Input.TextArea />
          </Form.Item>

          <Form.Item label='Комментарий' name='comment'>
            <Input.TextArea />
          </Form.Item>

          <ButtonsWrapper>
            <Button
              danger
              disabled={isButtonsDisabled || isSaveButtonDisabled}
              htmlType='submit'
              icon={<SaveOutlined />}
              onClick={() => form.validateFields().then(onSave)}
              type='primary'
            >
              Сохранить
            </Button>

            {isAbleToSend && (
              <Popconfirm
                disabled={isButtonsDisabled}
                okText='Отклонить'
                onConfirm={() => form.validateFields().then(onSaveAndSend)}
                title='Отклонить займ?'
              >
                <Button
                  danger
                  disabled={isButtonsDisabled}
                  htmlType='submit'
                  icon={<SendOutlined />}
                  type='primary'
                >
                  Сохранить и отправить
                </Button>
              </Popconfirm>
            )}
          </ButtonsWrapper>
        </Form>
      </Form.Provider>
    </DecisionFormWrapper>
  );
};
