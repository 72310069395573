import React from 'react';
import _ from 'lodash';

import TEST_ATTRIBUTES from 'constants/TEST_ATTRIBUTES';

import { ROLE_NAMES } from 'models/roles/constants';

import {
  AppstoreOutlined,
  AuditOutlined,
  BorderInnerOutlined,
  CheckSquareOutlined,
  ExpandAltOutlined,
  FileSearchOutlined,
  FileWordOutlined,
  GroupOutlined,
  MailOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

export const DICTIONARY_ROLES = [
  ROLE_NAMES.DICTIONARY_EDITOR,
  ROLE_NAMES.DICTIONARY_VIEWER,
  ROLE_NAMES.TEMPLATE_EDIT,
  ROLE_NAMES.VERIFICATION_ADMIN,
];

export const ADMIN_MENU = [
  {
    title        : `Справочники`,
    icon         : fontSize => <AppstoreOutlined style={{ fontSize }} />,
    path         : `/admin/dictionaries`,
    roles        : DICTIONARY_ROLES,
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_DICTIONARY_ITEM,
  },
  {
    title        : `Роли`,
    icon         : fontSize => <UserOutlined style={{ fontSize }} />,
    path         : `/admin/roles`,
    roles        : [ROLE_NAMES.SUPER_ADMIN],
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_ROLES_ITEM,
  },
  {
    title        : `Группы ролей`,
    icon         : fontSize => <TeamOutlined style={{ fontSize }} />,
    path         : `/admin/role-groups`,
    roles        : [ROLE_NAMES.SUPER_ADMIN],
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_ROLE_GROUPS_ITEM,
  },
  {
    title        : `Сотрудники`,
    icon         : fontSize => <SolutionOutlined style={{ fontSize }} />,
    path         : `/admin/operators`,
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_EMPLOYEES_ITEM,
  },
];

export const DICTIONARIES_MENU = [
  {
    color        : `alert`,
    group        : `Внешние`,
    icon         : fontSize => <MailOutlined style={{ fontSize }} />,
    entity       : `smsTemplate`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_SMS_TEMPLATE,
    title        : `Шаблоны СМС`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <AuditOutlined style={{ fontSize }} />,
    entity: `verificationStatus`,
    title : `Статусы заданий`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <FileSearchOutlined style={{ fontSize }} />,
    entity: `vectorTitle`,
    title : `Поля вектора данных`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <ExpandAltOutlined style={{ fontSize }} />,
    entity: `verificationLimit`,
    title : `Лимиты`,
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <BorderInnerOutlined style={{ fontSize }} />,
    entity: `telemetry`,
    title : `Телематика`,
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <CheckSquareOutlined style={{ fontSize }} />,
    entity: `warranty`,
    title : `Доп. гарантии`,
  },
  {
    color : `primary`,
    group : `Документы`,
    icon  : fontSize => <GroupOutlined style={{ fontSize }} />,
    entity: `documentGroup`,
    title : `Группы документов`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `primary`,
    group : `Документы`,
    icon  : fontSize => <FileWordOutlined style={{ fontSize }} />,
    entity: `documentTemplate`,
    title : `Шаблоны документов`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
];

export const BREADCRUMB_NAME_MAP = {
  '/admin': `Администрирование`,
  ...(_.reduce(
    _.concat(ADMIN_MENU, DICTIONARIES_MENU),
    (res, item) => ({
      ...res,
      [item.path || `/admin/dictionaries/${item.entity}`]: item.title }),
    {},
  )),
};
