/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import {
  Route,
  Switch,
} from 'dva/router';

import {
  getClient as getClientAction,
  getClientApplications as getClientApplicationsAction,
  getClientLoans as getClientLoansAction,
} from 'models/clients/actions';
import { isClientLoaded as isClientLoadedSelector } from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';

import PageNotFound from 'pages/PageNotFound';

import Application from './Application';
import ClientMatchContext from './ClientMatchContext';
import Comment from './Comment';
import CreditHistory from './CreditHistory';
import Decision from './Decision';
import Docs from './Docs';
import Eventlog from './Eventlog';
import Info from './Info';
import Sms from './Sms';
import Vector from './Vector';
// import VerificationResult from './VerificationResult';

interface IProps {
  match: {
    params: {
      personId: string;
      verificationId: string;
    };
  };
}

const PageClientSingle: React.FC<IProps> = ({ match }) => {
  const personId = _.get(match, `params.personId`);
  const verificationId = _.toNumber(_.get(match, `params.verificationId`));

  // @ts-ignore
  const isLoaded = useSelector(state => isClientLoadedSelector(state, personId));

  const dispatch = useDispatch();
  const getDictionary = useCallback((entity: string, force = true) => dispatch(getDictionaryAction({ entity, force })), [dispatch]);

  const getApplications = useCallback(() => dispatch(getClientApplicationsAction({ personId })), [dispatch, personId]);
  const getClient = useCallback((callback: () => void) => dispatch(getClientAction({ personId, callback })), [dispatch, personId]);
  const getLoans = useCallback(() => dispatch(getClientLoansAction({ personId })), [dispatch, personId]);

  useEffect(() => {
    getDictionary(`gender`);
    if (!isLoaded) {
      getClient(() => {
        getApplications();
        getLoans();
      });
    }
  }, [getApplications, getClient, getDictionary, getLoans, isLoaded]);

  return (
    <ClientMatchContext.Provider value={{ personId, verificationId }}>
      <Switch>
        <Route component={Info} exact path='/client/person/:personId/info' />
        <Route component={Info} exact path='/sale/:verificationId/person/:personId/info' />
        <Route component={Info} exact path='/verification/:verificationId/person/:personId/info' />

        <Route component={Application} exact path='/client/person/:personId/application' />
        <Route component={Application} exact path='/sale/:verificationId/person/:personId/application' />
        <Route component={Application} exact path='/verification/:verificationId/person/:personId/application' />

        <Route component={CreditHistory} exact path='/client/person/:personId/credit-history' />
        <Route component={CreditHistory} exact path='/sale/:verificationId/person/:personId/credit-history' />
        <Route component={CreditHistory} exact path='/verification/:verificationId/person/:personId/credit-history' />

        <Route component={Docs} path='/client/person/:personId/doc' />
        <Route component={Docs} path='/sale/:verificationId/person/:personId/doc' />
        <Route component={Docs} path='/verification/:verificationId/person/:personId/doc' />

        <Route component={Comment} path='/client/person/:personId/comment' />
        <Route component={Comment} path='/sale/:verificationId/person/:personId/comment' />
        <Route component={Comment} path='/verification/:verificationId/person/:personId/comment' />

        <Route component={Sms} path='/client/person/:personId/sms' />
        <Route component={Sms} path='/sale/:verificationId/person/:personId/sms' />
        <Route component={Sms} path='/verification/:verificationId/person/:personId/sms' />

        <Route component={Vector} path='/sale/:verificationId/person/:personId/vector' />
        <Route component={Vector} path='/verification/:verificationId/person/:personId/vectorNew' />

        {/*<Route component={VerificationResult} exact path='/sale/:verificationId/person/:personId/check' />*/}
        {/*<Route component={VerificationResult} exact path='/verification/:verificationId/person/:personId/check' />*/}

        <Route component={Decision} path='/sale/:verificationId/person/:personId/decision' />
        <Route component={Decision} path='/verification/:verificationId/person/:personId/decision' />

        <Route component={Eventlog} path='/sale/:verificationId/person/:personId/eventlog' />
        <Route component={Eventlog} path='/verification/:verificationId/person/:personId/eventlog' />

        <Route component={PageNotFound} />
      </Switch>
    </ClientMatchContext.Provider>
  );
};

export default PageClientSingle;
