import React, {
  useContext,
  useMemo,
} from 'react';
import _ from 'lodash';

import { formatDate } from 'helper';

import { Descriptions } from 'antd';
import MobileContext from 'pages/Layout/MobileContext';

const DETAILS = [
  {
    prop : `active`,
    label: `Активный`,
  },
  {
    prop : `issued`,
    label: `Выдан`,
  },
  {
    prop : `statusPrev`,
    label: `Предыдущий статус`,
  },
  {
    prop : `status`,
    label: `Статус`,
  },
  {
    prop : `closeSource`,
    label: `Источник закрытия`,
  },
  {
    prop : `endDate`,
    label: `Дата завершения кредита`,
  },
];

const LoanDescription = ({ loan }) => {
  const mobile = useContext(MobileContext);

  const column = mobile ? 1 : _.size(DETAILS);
  const layout = mobile ? `horizontal` : `vertical`;

  const title = `Займ ${loan.contractNumber} от ${formatDate(loan.createDtm, `dd.MM.yyyy`)}`;

  const loanNormalized = useMemo(() => ({
    active     : loan.activeFlg ? `Да` : `Нет`,
    closeSource: loan.closeSource || `-`,
    endDate    : formatDate(loan.creditEndDtm, `dd.MM.yyyy`),
    issued     : loan.issuedFlg ? `Да` : `Нет`,
    status     : loan.loanStatusName || loan.loanStatusId,
    statusPrev : loan.previousLoanStatusName || loan.previousLoanStatusId || `?`,
  }), [loan?.id]);

  if (_.isEmpty(loan)) return null;
  return (
    <Descriptions
      bordered
      column={column}
      layout={layout}
      size='small'
      title={title}
    >
      {_.map(DETAILS, ({ label, prop }) => (
        <Descriptions.Item key={label} label={label}>{loanNormalized?.[prop]}</Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default LoanDescription;
