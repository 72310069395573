import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link as DvaLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { subHeaderButtonDefaultMixin } from 'components/SubHeader/SubHeaderButtonDefault';

const Wrapper = styled.div`
  font-family: PT Root UI, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  color: ${palette.whiteColor};
  display: flex;
  align-items: center;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 14px;
  }
`;

const ButtonLink = styled(DvaLink)`
  ${subHeaderButtonDefaultMixin};
  margin-left: 8px;
`;

interface IComponentProps {
  children?: React.ReactNode;
  className?: string;
  link: string;
  number: number;
}

const ClientInfoWidget: React.FC<IComponentProps> = ({ children, className, link, number }) => (
  <Wrapper className={className}>
    {children}
    <ButtonLink to={link}>
      {number}
    </ButtonLink>
  </Wrapper>
);

export default ClientInfoWidget;
