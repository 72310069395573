import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import {
  validateEmail,
  validateUuid4,
} from 'helper';

import {
  searchClients as searchClientsAction,
  set as setAction,
} from 'models/clients/actions';
import {
  getIsLoading as isLoadingSelector,
  getSearchString as searchStringSelector,
} from 'models/clients/selectors';

import useMobileContext from 'hooks/useMobileContext';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Input,
  Select,
  Tooltip,
} from 'antd';
import { SEARCH_ADDON_OPTIONS } from 'pages/PageClient/Plural/constants';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 800px;

  .ant-input-affix-wrapper {
    border-radius: 8px 0 0 8px !important;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 8px 8px 0;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    background-color: ${palette.backgroundAccentColor};

    .ant-input-search-button {
      border-radius: 0 8px 8px 0;
    }
  }

  @media(max-width: ${breakpoints.md}) {
    margin-right: 28px;
  }
`;

const StyledSuffixIcon = styled(QuestionCircleOutlined)`
  color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
`;

interface IComponentProps {
}

export const ClientSearch: React.FC<IComponentProps> = () => {
  const dispatch = useDispatch();

  const searchClients = (data: { mode: string | undefined; } | { mode?: undefined; }) => dispatch(searchClientsAction(data));
  const set = (value: { searchString: string; }) => dispatch(setAction(value));

  const initialAddonValue = _.get(_.first(SEARCH_ADDON_OPTIONS), `value`);
  const searchString = useSelector(searchStringSelector);
  const isLoading = useSelector(isLoadingSelector);

  const [addonValue, setAddonValue] = useState(initialAddonValue);

  const searchStringNumbers = _.replace(searchString, /\D/g, ``);
  const isShowAddon = !validateEmail(searchString)
    && !validateUuid4(searchString)
    && /^\d{10}$/.test(searchStringNumbers);

  const search = () => searchClients(isShowAddon ? { mode: addonValue } : {});

  const { mobile } = useMobileContext();

  useEffect(() => {
    if (isShowAddon) {
      const newAddonValue = _.head(searchStringNumbers) === `9` ? `phone` : `passport`;
      setAddonValue(newAddonValue);
    }
  }, [searchStringNumbers, isShowAddon]);

  useEffect(() => {
    search();
  }, []);

  return (
    <Wrapper>
      <Input.Search
        addonBefore={isShowAddon ? (
          <Select
            onChange={setAddonValue}
            options={SEARCH_ADDON_OPTIONS}
            value={addonValue}
          />
        ) : null}
        enterButton={mobile ? true : `Искать`}
        loading={isLoading}
        onChange={e => set({ searchString: e.target.value })}
        onSearch={search}
        placeholder='Поиск клиента'
        size='large'
        suffix={(
          <Tooltip
            placement='bottom'
            title={() => (
              <div>
                <div>В основную строку поиска можно вводить запросы вида (порядок важен):</div>
                <div>фамилия имя отчество</div>
                <div>или</div>
                <div>фамилия имя</div>
                <div>или</div>
                <div>+79999999999</div>
                <div>или personId</div>
                <div>f622a015-9f18-430e-95ef-bc26be5fb614</div>
                <div>или серию и номер паспорта</div>
                <div>2233232123</div>
                <div>или email@example.com</div>
              </div>
            )}
          >
            <StyledSuffixIcon />
          </Tooltip>
        )}
        value={searchString}
      />
    </Wrapper>
  );
};
