import {
  ROLE_NAMES,
  TRoleName,
} from 'models/roles/constants';

interface INav {
  key: string;
  label: string;
  roles?: TRoleName[];
}
export const NAV:INav[] = [
  {
    label: `Данные`,
    key  : `info`,
  },
  {
    label: `Заявки`,
    key  : `application`,
  },
  // {
  //   label: `Займы`,
  //   key  : `loan`,
  // },
  {
    label: `КИ`,
    key  : `credit-history`,
  },
  // {
  //   label: `Маркетинговые предложения`,
  //   key  : `marketingOffer`,
  // },
  {
    label: `Документы`,
    key  : `doc`,
  },
  // {
  //   label: `Авто`,
  //   key  : `car`,
  // },
  // {
  //   label: `Задолженность`,
  //   key  : `debt`,
  // },
  {
    label: `SMS`,
    key  : `sms`,
  },
  // {
  //   label: `Коммуникации`,
  //   key  : `communication`,
  // },
  // {
  //   label: `Звонки`,
  //   key  : `call`,
  //   roles: [
  //     ROLE_NAMES.SUPER_ADMIN,
  //     ROLE_NAMES.COLLECTION_ADMIN,
  //     ROLE_NAMES.SALE_ADMIN,
  //   ],
  // },
  {
    label: `Комментарии`,
    key  : `comment`,
  },
  // {
  //   label: `Акции`,
  //   key  : `action`,
  // },
  // {
  //   label: `Триггеры`,
  //   key  : `trigger`,
  // },
];

export const VERIFICATION_NAV:INav[] = [
  {
    label: `Данные`,
    key  : `info`,
  },
  {
    label: `Заявки`,
    key  : `application`,
  },
  // {
  //   label: `Займы`,
  //   key  : `loan`,
  // },
  {
    label: `КИ`,
    key  : `credit-history`,
  },
  {
    label: `Документы`,
    key  : `doc`,
  },
  // {
  //   label: `Авто`,
  //   key  : `car`,
  // },
  // {
  //   label: `Сервисы по авто`,
  //   key  : `car-service`,
  // },
  // {
  //   label: `Задолженность`,
  //   key  : `debt`,
  // },
  // {
  //   label: `Маркетинговые предложения`,
  //   key  : `marketingOffer`,
  // },
  // {
  //   label: `Вектор данных`,
  //   key  : `vector`,
  // },
  {
    label: `Вектор данных`,
    key  : `vectorNew`,
    roles: [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.VERIFICATION_READ],
  },
  {
    label: `Комментарии`,
    key  : `comment`,
  },
  // {
  //   label: `Геоданные`,
  //   key  : `geolocation`,
  // },
  // {
  //   label: `Чек-листы`,
  //   key  : `check`,
  // },
  {
    label: `Решение`,
    key  : `decision`,
  },
  ...(
    import.meta.env.REACT_APP_ENV === `dev`
      ? [{
        label: `SMS`,
        key  : `sms`,
      }]
      : []),
  // {
  //   label: `Коммуникации`,
  //   key  : `communication`,
  // },
  // {
  //   label: `Триггеры`,
  //   key  : `trigger`,
  // },
  {
    label: `Журнал`,
    key  : `eventlog`,
  },
];
