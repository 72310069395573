import React, {
  useCallback,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  isLoading as isLoadingSelector,
} from 'models/admin/selectors';
import { uploadMarketingOffers as uploadMarketingOffersAction } from 'models/clients/actions';
import {
  EMAIL_TESTER,
  OFFER_UPLOADER,
  SMS_LIST_SENDER,
  SUPER_ADMIN,
} from 'models/roles/constants';

import { useFileInput } from 'hooks/useFileInput';
import { useHasRole } from 'hooks/useHasRole';

import {
  ContainerOutlined,
  GroupOutlined,
  LoadingOutlined,
  MailOutlined,
  SendOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { IControl } from 'pages/PageAdmin/components/AdminSearchWithControls';

const ACCEPTED_FILES = [`application/vnd.ms-excel`, `.xlsx`];

export function useAdminGeneralControls() {
  const dispatch = useDispatch();

  const hasRole = useHasRole();

  const [isDocumentBatchDownloadModalOpen, setIsDocumentBatchDownloadModalOpen] = useState(false);
  const [isTestEmailModalOpen, setTestEmailModal] = useState(false);
  const [isUploadVersionedDocumentModalOpen, setUploadVersionedDocumentModal] = useState(false);
  const [isSmsBatchModalOpen, setIsSmsBatchModalOpen] = useState(false);

  const isSuperAdmin = hasRole(SUPER_ADMIN);
  const isOfferUploader = hasRole(OFFER_UPLOADER);
  const isSmsBatchSender = hasRole(SMS_LIST_SENDER);

  const isEmailTester = hasRole(EMAIL_TESTER);
  const isLoading = useSelector(isLoadingSelector);

  const uploadMarketingOffers = useCallback(
    (file:File) => dispatch(uploadMarketingOffersAction({ file })),
    [dispatch],
  );

  const onFilesSelected = ([file]: File[]) => uploadMarketingOffers(file);

  const {
    ACCEPTED_FILES_STRING,
    handleFileSelect,
    inputRef,
    openFileInput,
    StyledInputFile,
  } = useFileInput({ acceptedFiles: ACCEPTED_FILES, onFilesSelected });

  const controls: IControl[] = [
    {
      isHidden  : !isSuperAdmin && !isOfferUploader,
      title     : `Загрузить маркетинговые офферы`,
      icon      : <TrophyOutlined />,
      additional: (
        <StyledInputFile
          accept={ACCEPTED_FILES_STRING}
          multiple={false}
          onChange={handleFileSelect}
          ref={inputRef}
        />
      ),
      onClick: openFileInput,
    },
    {
      isHidden: !isSuperAdmin && !isSmsBatchSender,
      title   : `Списочная рассылка SMS`,
      icon    : <SendOutlined />,
      onClick : () => setIsSmsBatchModalOpen(v => !v),
    },
    {
      isHidden: !isSuperAdmin && !isLegalAdmin,
      title   : `Загрузить версионный документ`,
      icon    : <ContainerOutlined />,
      onClick : () => setUploadVersionedDocumentModal(v => !v),
    },
    {
      isHidden  : !isSuperAdmin && !isEmailTester,
      title     : `Отправка тестовых Email`,
      isDisabled: isLoading,
      icon      : isLoading ? <LoadingOutlined /> : <MailOutlined />,
      onClick   : () => setTestEmailModal(v => !v),
    },
    {
      isHidden: !isSuperAdmin,
      title   : `Массовая выгрузка клиентских документов`,
      icon    : <GroupOutlined />,
      onClick : () => setIsDocumentBatchDownloadModalOpen(v => !v),
    },
  ];

  return {
    controls,
    isSmsBatchModalOpen,
    isTestEmailModalOpen,
    isDocumentBatchDownloadModalOpen,
    isUploadVersionedDocumentModalOpen,
    setTestEmailModal,
    setIsSmsBatchModalOpen,
    setIsDocumentBatchDownloadModalOpen,
    setUploadVersionedDocumentModal,
  };
}

