import React, { useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { useClient } from 'models/clients/hooks';

import MttPhoneButton from 'components/MttPhoneButton';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { AddressItem } from 'pages/PageClient/Single/Info/Address/AddressItem';

const MttPhoneButtonStyled = styled(MttPhoneButton)`
  margin-left: 8px;
`;

interface IComponentProps {
  applicationDate: string;
  applicationId: string;
  personId: string;
}

const ClientInfo: React.FC<IComponentProps> = ({ applicationDate, applicationId, personId }) => {
  const [, client] = useClient(personId);

  const addressRegRaw = _.find(client?.addressesReg, { applicationId });
  const addressReg = {
    applicationDate,
    applicationId,
    reg  : addressRegRaw?.address,
    regId: addressRegRaw?.addressId,
  };
  const addressFactRaw = _.find(client?.addressesFact, { applicationId });
  const addressFact = {
    applicationDate,
    applicationId,
    fact  : addressFactRaw?.address,
    factId: addressFactRaw?.addressId,
  };

  const clientPhone = `+${client?.phone}`;

  const items = useMemo(() => _.reject([
    {
      name : `personID`,
      value: client.personId,
    },
    {
      name : `ИНН`,
      value: client.inn,
    },
    {
      name : `Телефон (осн)`,
      text : clientPhone,
      value: (
        <>
          {clientPhone}
          <MttPhoneButtonStyled phone={clientPhone} />
        </>
      ),
    },
    {
      name : `Email`,
      value: client.email,
    },
    {
      isCopyAllowed: false,
      name         : `Адрес регистрации`,
      value        : (
        <AddressItem
          item={addressReg}
          personId={personId}
          showApplicationId={false}
          type='reg'
        />
      ),
    },
    {
      isCopyAllowed: false,
      name         : `Адрес проживания`,
      value        : (
        <AddressItem
          item={addressFact}
          personId={personId}
          showApplicationId={false}
          type='fact'
        />
      ),
    },
  ], ({ value }) => _.isNil(value)),
  [personId, client, addressReg, addressFact]);

  return (
    <EntityCard>
      <EntityCardTitle>Данные клиента</EntityCardTitle>
      <EntityCardContent>
        {_.map(items, ({ isCopyAllowed = true, name, text, value }, index) => (
          <EntityCardRow key={index} title={name} {...isCopyAllowed ? { valueToCopy: text || value } : {}}>
            {value}
          </EntityCardRow>
        ))}
      </EntityCardContent>
    </EntityCard>
  );
};

export default ClientInfo;
