import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  getClientApplicationDocs as getClientApplicationDocsAction,
  getClientApplications as getApplicationsAction,
} from 'models/clients/actions';
import {
  getClientApplicationsForDocs as getClientApplicationsForDocsSelector,
  getIsLoading as getIsLoadingSelector,
} from 'models/clients/selectors';
import { IDoc } from 'models/clients/types';
import { get as getDictionaryAction } from 'models/dictionaries/actions';
import {
  getItems as getDictionarySelector,
  getUploadableDocumentTypes,
} from 'models/dictionaries/selectors';

import { usePath } from 'hooks/usePath';

import {
  Empty,
  Spin,
} from 'antd';
import ApplicationDocumentUploadModal from 'components/Modal/ApplicationDocumentUploadModal';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { TabContentWrapper } from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';
import { DocsCollapse } from 'pages/PageClient/Single/Docs/DocsCollapse';

import Controls from './Controls';
import { DocsCarousel } from './DocsCarousel';
import { getDocsList } from './helpers';

const StyledLayout = styled.div`
  margin-top: 24px;
`;

const Content = styled.div`
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr;

  @media(min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const DocsContext = createContext({
  isDownloading   : false,
  setIsDownloading: (isDownloading: boolean) => isDownloading,
  setUrl          : (url: string) => url,
  url             : undefined,
});

interface IComponentProps {
  applicationId?: string;
  isWrapped?: boolean;
  personId?: string;
}

const PageClientSingleDocs: React.FC<IComponentProps> = ({
  applicationId: _applicationId,
  isWrapped = true,
  personId: _personId,
}) => {
  const { replacePath } = usePath();

  const dispatch = useDispatch();

  const [selectedUrl, setSelectedUrl] = useState(null);

  const [isDocsLoading, setIsDocsLoading] = useState<boolean>(false);
  const [isDocDownloading, setIsDocDownloading] = useState<boolean>(false);

  const [uploadingDocumentType, setUploadingDocumentType] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const openUploadModal = (uploadingDocType = null) => {
    setIsUploadModalOpen(true);
    setUploadingDocumentType(uploadingDocType);
  };
  const closeUploadModal = () => {
    setUploadingDocumentType(null);
    setIsUploadModalOpen(false);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const applicationId = _applicationId || queryParams.get(`applicationId`);
  const matchContext = useContext(ClientMatchContext);
  const personId = _personId || matchContext?.personId;

  const applications = useSelector(state => getClientApplicationsForDocsSelector(state, personId));

  const uploadableDocumentTypes = useSelector(getUploadableDocumentTypes);
  const documentGroups = _.orderBy(
    useSelector(state => getDictionarySelector(state, `documentGroup`)),
    `order`,
    `asc`,
  );
  const isLoading = useSelector(getIsLoadingSelector);

  const getApplicationDocs = () => {
    if (!applicationId || isDocsLoading) return;

    setIsDocsLoading(true);

    dispatch(getClientApplicationDocsAction({
      applicationId,
      callback: () => setIsDocsLoading(false),
      personId,
    }));
  };

  const docs:IDoc[] = getDocsList({
    applications,
    // @ts-ignore
    applicationId,
    documentGroups,
    uploadableDocumentTypes,
  });

  const isDocsEmpty = _.isEmpty(docs);

  const setApplicationId = (appId: string) => replacePath(`${window.location.pathname}?applicationId=${appId}`);

  useEffect(() => {
    if (applicationId && _.isEmpty(docs)) getApplicationDocs();
  }, [applicationId]);

  useEffect(() => {
    if (!applicationId && applications.length > 0) {
      setApplicationId(_.get(_.first(applications), `id`));
    }
  }, [applicationId, applications.length]);

  const getApplications = useCallback(() => dispatch(getApplicationsAction({ personId })), [dispatch, personId]);
  const getDocumentGroups = useCallback(
    () => dispatch(getDictionaryAction({ force: true, entity: `documentGroup` })),
    [dispatch, personId],
  );

  useEffect(() => {
    if (_.isEmpty(applications)) getApplications();
    if (_.isEmpty(documentGroups)) getDocumentGroups();
  }, []);

  // @ts-ignore
  const hiddenDocTypeIds:number[] = _.reduce(documentGroups, (result, documentGroup) => {
    if (!documentGroup.isHidden) return result;
    return [
      ...result,
      ...documentGroup.typeIds,
    ];
  }, []);

  const filteredDocs = _.reject(docs, doc => _.includes(hiddenDocTypeIds, doc.type));

  const content = (
    <>
      <Controls
        // @ts-ignore
        applicationId={applicationId}
        docs={docs}
        openUploadModal={openUploadModal}
        // @ts-ignore
        personId={personId}
        setApplicationId={setApplicationId}
      />

      {isDocsEmpty && !isDocsLoading && (
        <Empty
          description='Документы не найдены'
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}

      {isDocsLoading && <Spin size='large' />}

      {!isDocsEmpty && (
        <Content>
          <DocsCollapse
            // @ts-ignore
            applicationId={applicationId}
            docs={docs}
            documentGroups={documentGroups}
            onOpenUploadModal={openUploadModal}
            // @ts-ignore
            personId={personId}
          />
          <DocsCarousel isLoading={isDocsLoading} items={filteredDocs} />
        </Content>
      )}

      <ApplicationDocumentUploadModal
        afterUpload={getApplicationDocs}
        // @ts-ignore
        applicationId={applicationId}
        close={closeUploadModal}
        docs={docs}
        // @ts-ignore
        documentTypeId={uploadingDocumentType}
        isOpen={isUploadModalOpen}
        // @ts-ignore
        personId={personId}
      />
    </>
  );

  if (isWrapped) {
    return (
      <DocsContext.Provider value={{
        isDownloading   : isDocDownloading,
        // @ts-ignore
        setIsDownloading: setIsDocDownloading,
        // @ts-ignore
        url             : selectedUrl,
        // @ts-ignore
        setUrl          : setSelectedUrl,
      }}
      >
        <ClientTab isReloading={isLoading || isDocsLoading} onReload={getApplicationDocs}>
          <TabContentWrapper>
            <StyledLayout>
              {content}
            </StyledLayout>
          </TabContentWrapper>
        </ClientTab>
      </DocsContext.Provider>
    );
  }

  return (
    <DocsContext.Provider value={{
      isDownloading   : isDocDownloading,
      // @ts-ignore
      setIsDownloading: setIsDocDownloading,
      // @ts-ignore
      url             : selectedUrl,
      // @ts-ignore
      setUrl          : setSelectedUrl,
    }}
    >
      {content}
    </DocsContext.Provider>
  );
};

export default PageClientSingleDocs;
