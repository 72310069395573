import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { uploadVersionedDocument } from 'models/admin/api';
import { useDictionaries } from 'models/dictionaries/hooks';

import {
  CheckCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Modal,
  notification,
  Select,
  Upload,
} from 'antd';
import _ from "lodash";

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

interface IValues {
  date: moment.Moment | null;
  documentTypeId: number | null;
  file: File | null;
  productId: number | null;
}

interface IErrors {
  date?: string;
  documentTypeId?: string;
  file?: string;
}

const defaultValues: IValues = {
  documentTypeId: null,
  date          : null,
  file          : null,
  productId     : null,
};

interface VersionedDocumentUploadModalProps {
  isVisible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

const validateValues = (values: IValues): IErrors => {
  const errors: IErrors = {};
  if (!values.documentTypeId || !Number.isInteger(values.documentTypeId)) {
    errors.documentTypeId = `Выберите тип документа`;
  }
  if (!values.date || !moment.isMoment(values.date)) {
    errors.date = `Выберите дату версии`;
  }
  if (!values.file || !(values.file instanceof File)) {
    errors.file = `Выберите файл документа`;
  }
  return errors;
};

const VersionedDocumentUploadModal: React.FC<VersionedDocumentUploadModalProps> = ({
  isVisible = false,
  onClose = _.noop,
  onSuccess = _.noop,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({});
  const [values, setValues] = useState(defaultValues);
  const [isDictionariesLoading, dictionaries] = useDictionaries(
    [`documentType`, `documentGroup`],
    true,
    true,
  );

  const documentTypeLookup = useMemo(
    () => Object.fromEntries(
      dictionaries?.documentType?.map(({ id, name }) => [id, name]) ?? [],
    ),
    [dictionaries.documentType],
  );

  const documentTypes = useMemo(
    () => dictionaries?.documentGroup
      ?.find(({ name }) => name === `versioned`)
      ?.typeIds?.map((id: string | number) => ({
        label: `${documentTypeLookup[id]} (${id})`,
        value: id,
      })) ?? [],
    [dictionaries.documentGroup, documentTypeLookup],
  );

  const setValue = useCallback(
    (field: keyof IValues, data: ChangeEvent<HTMLInputElement> | Moment | File | number) => {
      setValues(prevValues => ({
        ...prevValues,
        // @ts-ignore
        [field]: data instanceof Event ? data.target.value : data,
      }));
    },
    [],
  );

  useEffect(() => {
    setErrors(validateValues(values))
  }, [values]);

  const reset = useCallback(() => setValues(defaultValues), []);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleSuccess = useCallback(() => {
    onSuccess();
    handleClose();
  }, [handleClose, onSuccess]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const defaultError = `При загрузке документа произошла ошибка.`;
    try {
      parseResponse({
        defaultError,
        errorPath: `data.message`,
        // @ts-ignore
        response : await uploadVersionedDocument(values),
      });
      handleSuccess();
      notification.success({ message: `Документ загружен` });
    } catch (error) {
      showError({ defaultError, error });
    } finally {
      setIsLoading(false);
    }
  }, [handleSuccess, values]);

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okButtonProps={{ disabled: isLoading || isDictionariesLoading || Object.keys(errors).length > 0 }}
      okText='Загрузить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title='Загрузка версионного документа'
    >
      <Form layout='vertical'>
        <Form.Item
          help={errors.documentTypeId}
          label='Тип документа'
          required
          validateStatus={errors.documentTypeId ? `error` : `success`}
        >
          <StyledSelect
            // @ts-ignore
            onChange={(value: number) => setValue(`documentTypeId`, value)}
            optionFilterProp='label'
            options={documentTypes}
            placeholder='Тип документа'
            showSearch
            value={values.documentTypeId}
          />
        </Form.Item>

        <Form.Item
          help={errors.date}
          label='Дата версии'
          required
          validateStatus={errors.date ? `error` : `success`}
        >
          <DatePicker
            onChange={value => setValue(`date`, value)}
            // @ts-ignore
            value={values.date}
          />
        </Form.Item>

        <Form.Item
          help={errors.file}
          label='Документ'
          required
          validateStatus={errors.file ? `error` : `success`}
        >
          <StyledUpload
            // @ts-ignore
            customRequest={({ file: f }) => setValue(`file`, f)}
            multiple={false}
            showUploadList={false}
          >
            {values.file
              ? (
                <>
                  <UploadIcon><CheckCircleOutlined /></UploadIcon>
                  <UploadText>{values.file?.name}</UploadText>
                </>
              )
              : (
                <>
                  <UploadIcon><InboxOutlined /></UploadIcon>
                  <UploadText>Кликните или перетащите сюда версионный документ для загрузки</UploadText>
                </>
              )}
          </StyledUpload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VersionedDocumentUploadModal;
