/* eslint-disable max-len */
import qs from 'query-string';

import api from 'api/index';
import { getLimitParams } from 'helper';

import {
  IVerificationResults,
  TApproveDetails,
  TDecision,
} from 'models/verifications/types';

export const get = (limit: number, offset: number, params?: any) => api.get(`/verification${getLimitParams(limit, offset, params)}`);
export const getSingle = (id: number) => api.get(`/verification/${id}`);
export const getWebData = (applicationId: string, key: any) => {
  const params = qs.stringify(key);
  return api.get(`/proxy/application/web-data/${applicationId}?${params}`);
};
export const sendBlurredPhotos = (id: number, documentTypeIds: number[]) => api.post(`/verification/${id}/blurred`, { documentTypeIds });
export const setDecision = (id: number, decision: TDecision) => api.post(`/verification/${id}/decision`, decision);
export const setResults = (verificationId: number, results: IVerificationResults) => api.post(`/verification/${verificationId}/results`, results);
export const setStatus = (id: number, verificationStatusId: number) => api.post(`/verification/${id}/status`, { verificationStatusId });
export const setOperator = (id: number, {
  operatorId,
  userId,
}: { operatorId: number, userId: number }) => api.post(`/verification/${id}/operator`, { operatorId, userId });
export const approve = ({
  details,
  id,
}: { details: TApproveDetails, id: number }) => api.post(`/verification/${id}/approve`, { details });
export const decline = (id: number) => api.post(`/verification/${id}/decline`);
export const updateUserData = () => api.post(`/verification/updateUsers`);
export const verifyApplicationIncome = (data: any) => api.post(`/proxy/application/verify-income`, data);
