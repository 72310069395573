import React, {
  useState,
} from 'react';
import _ from 'lodash';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import {
  getCheckedField,
} from 'helper';

import { getWoopraLink } from 'models/clients/helpers';
import { useClient } from 'models/clients/hooks';
import {
  CLIENT_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';

import {
  EditOutlined,
} from '@ant-design/icons';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

import ConditionModal from './ConditionModal';

const allowedRoles = [SUPER_ADMIN, CLIENT_EDIT];

const Other = ({ openEditModal, personId }) => {
  const [, client] = useClient(personId);


  const [conditionModalVisible, setConditionModalVisible] = useState(false);
  const toggleConditionModal = () => setConditionModalVisible(prev => !prev);

  const items = _.reject([
    {
      name : `personId`,
      value: getCheckedField(personId),
      text : personId,
    },
    {
      name : `Woopra`,
      value: getWoopraLink(client),
      text : WOOPRA_PERSONAL_URL(personId),
    },
    {
      name : `ИНН`,
      value: client.inn,
    },
    {
      name : `СНИЛС`,
      value: client.snils,
    },
  ], ({ value }) => _.isNil(value));

  if (_.isEmpty(items)) return null;

  return (
    <EntityCard>
      <EntityCardTitle>
        Прочие данные

        <EntityCardTitleControl
          actionTitle='Изменение данных'
          allowedRoles={allowedRoles}
          onClick={() => openEditModal()}
        >
          <EditOutlined />
        </EntityCardTitleControl>
      </EntityCardTitle>

      <EntityCardContent>
        {_.map(items, ({ name, text, value }, index) => (
          <EntityCardRow key={index} title={name} valueToCopy={text || value}>
            {value}
          </EntityCardRow>
        ))}
      </EntityCardContent>

      <ConditionModal
        entity='client'
        onClose={toggleConditionModal}
        personId={personId}
        visible={conditionModalVisible}
      />
    </EntityCard>

  );
};

export default Other;
