import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { SubHeader } from 'components/SubHeader/SubHeader';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import ClientMenu from 'pages/PageClient/Single/components/ClientMenu/ClientMenu';
import ClientInfoWidget from 'pages/PageClient/Single/components/ClientSubHeader/ClientInfoWidget';
import ClientSubHeaderControls
  from 'pages/PageClient/Single/components/ClientSubHeader/ClientSubHeaderControls/ClientSubHeaderControls';
import HeaderClientName from 'pages/PageClient/Single/components/ClientSubHeader/HeaderClientName';
import { getSubheaderLinks } from 'pages/PageClient/Single/components/ClientSubHeader/helpers';

const StyledHeaderClientName = styled(HeaderClientName)`
  margin-bottom: 24px;
`;

const ControlsWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 24px;
    justify-content: flex-start;
  }
`;

const ClientInfoWidgetsWrapper = styled.div`
  display: flex;

  > * {
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`;

interface IProps {
  applicationsSize: number;
  fullName: string;
  isReloading?: boolean;
  onReload?(): void;
}

const ClientSubHeaderMobile: React.FC<IProps> = ({
  applicationsSize,
  fullName,
  isReloading,
  onReload,
}) => {
  const { personId, verificationId } = useContext(ClientMatchContext);
  const { applications } = getSubheaderLinks({ personId, verificationId });
  return (
    <SubHeader thinBottom>
      <StyledHeaderClientName fullName={fullName} />
      <ControlsWrapper>
        <ClientInfoWidgetsWrapper>
          <ClientInfoWidget link={applications} number={applicationsSize}>
            Заявок:
          </ClientInfoWidget>
        </ClientInfoWidgetsWrapper>

        <ClientSubHeaderControls isReloading={isReloading} onReload={onReload} />
      </ControlsWrapper>
      <ClientMenu />
    </SubHeader>
  );
};

export default ClientSubHeaderMobile;
