import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  SUPER_ADMIN,
  VERIFICATION_SEND,
} from 'models/roles/constants';
import { useVerification } from 'models/verifications/hooks/useVerification';

import { useHasRoles } from 'hooks/useHasRoles';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ApproveTab } from 'pages/PageClient/Single/Decision/components/ApproveTab/ApproveTab';
import { DecisionTab } from 'pages/PageClient/Single/Decision/components/DecisionTab';
import { DeclineForm } from 'pages/PageClient/Single/Decision/components/DeclineForm';
import { ETabs } from 'pages/PageClient/Single/Decision/types';

import {
  FormTabs,
  LayoutContent,
  StyledLayout,
} from './styled';

export const ContentWrapper = styled.div``;

interface VerificationFormProps {}

export const VerificationForm: React.FC<VerificationFormProps> = () => {
  const { verificationId } = useContext(ClientMatchContext) as { verificationId: number; };

  const [activeTab, setActiveTab] = useState<ETabs>(ETabs.DECLINE);

  const [isLoading, verification] = useVerification(verificationId);

  const hasRoles = useHasRoles();
  const isAbleToSend = hasRoles([SUPER_ADMIN, VERIFICATION_SEND]);

  const decisionHasBeenMade = useMemo(() => _.includes(
    [`notinverification`, `approved`, `declined`, `send`],
    verification?.verificationStatus?.name,
  ), [verification?.verificationStatus?.name]);
  useEffect(() => {
    if (verification?.approved !== undefined) {
      setActiveTab(verification.approved ? ETabs.APPROVE : ETabs.DECLINE);
    }
  }, [verification?.approved]);

  return (
    <StyledLayout>
      <LayoutContent>
        <>
          <FormTabs>
            <DecisionTab
              activeTab={activeTab}
              onChange={setActiveTab}
              tabName={ETabs.APPROVE}>
              Одобрить
            </DecisionTab>
            <DecisionTab
              activeTab={activeTab}
              isDanger
              onChange={setActiveTab}
              tabName={ETabs.DECLINE}
            >
              Отказать
            </DecisionTab>
          </FormTabs>

          <ContentWrapper>
            {activeTab === ETabs.APPROVE && (
              <ApproveTab
                decisionHasBeenMade={decisionHasBeenMade}
                isAbleToSend={isAbleToSend}
                isLoading={isLoading}
                isResultsComplete
                verification={verification}
                verificationId={verificationId}
              />
            )}

            {activeTab === ETabs.DECLINE && (
              <DeclineForm
                decisionHasBeenMade={decisionHasBeenMade}
                isAbleToSend={isAbleToSend}
                isLoading={isLoading}
                verification={verification}
                verificationId={verificationId}
              />
            )}
          </ContentWrapper>
        </>
      </LayoutContent>
    </StyledLayout>
  );
};

