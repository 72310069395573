import React, {
  useContext,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { hasIntersection } from 'helper';

import {
  isLoading as isAdminLoadingSelector,
} from 'models/admin/selectors';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as userRolesSelector } from 'models/user/selectors';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import MobileContext from 'pages/Layout/MobileContext';
import { MenuItemAdminBig } from 'pages/PageAdmin/Main/components/MenuItemAdminBig';

import { ADMIN_MENU } from '../constants';

const StyledSpace = styled(Space)<{
  $align: string,
  $direction: string,
  $wrap: boolean,
}>`
  display: flex;
  flex-wrap: wrap;
  gap: 24px !important;
  margin: 0;
  padding: 40px 60px;

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media (min-width:  ${breakpoints.md}) and (max-width: 1063px) {
    .ant-space-item {
      display: flex;
      width: calc(50% - 12px);
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
    gap: 8px !important;
    padding: 24px 16px !important;

    .ant-space-item {
      display: flex;
      width: 100%;
    }
  }
`;

const StyledDarkSpace = styled(Space)<{
  $align: string,
  $direction: string,
  $isClosed: boolean,
  $wrap: boolean,
}>`
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${palette.backgroundAccentColor};
  padding: 0 60px 16px 60px;
  align-items: flex-start;

  .ant-space-item {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 8px 16px 16px 16px;
    gap: 16px;
    ${props => (props.$isClosed && `padding-bottom: 12px;`)};
  }
`;

const SelectControl = styled.div<{ $isOpen: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.whiteColor};
  display: flex;
  padding-top: 12px;
  padding-bottom: ${({ $isOpen }) => ($isOpen && `4px`)};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    font-size: 16px;
  }
`;

const Main = () => {
  const mobile = useContext(MobileContext);

  const isLoading = useSelector(isAdminLoadingSelector);
  const userRoles = useSelector(userRolesSelector);

  const menu = _.includes(userRoles, ROLE_NAMES.SUPER_ADMIN)
    ? ADMIN_MENU
    : _.filter(ADMIN_MENU, ({ roles }) => _.isEmpty(roles) || hasIntersection(userRoles, roles));

  const [isSelectBlocksOpen, setSelectBlocksOpen] = useState(false);

  return (
    <>
      {isLoading && <DefaultSpin />}
      <StyledDarkSpace
        $align='center'
        $direction={mobile ? `vertical` : `horizontal`}
        $isClosed={!isSelectBlocksOpen}
        $wrap
      >
        {mobile && (
        <SelectControl $isOpen={isSelectBlocksOpen} onClick={() => setSelectBlocksOpen(!isSelectBlocksOpen)}>
          Шлюзы и сервисы
          {isSelectBlocksOpen
            ? <UpOutlined />
            : <DownOutlined />}
        </SelectControl>
        )}

      </StyledDarkSpace>
      <StyledSpace
        $align='center'
        $direction={mobile ? `vertical` : `horizontal`}
        $wrap
      >
        {_.map(menu, ({ icon, path, testAttribute, title }) => (
          <MenuItemAdminBig
            icon={icon}
            key={path}
            mobile={mobile}
            path={path}
            testAttribute={testAttribute}
            title={title}
          />
        ))}
      </StyledSpace>
    </>
  );
};

export default Main;
