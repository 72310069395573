import React from 'react';
import _ from 'lodash';

import BOOL_COLUMN from 'constants/BOOL_COLUMN';
import BOOL_FIELD from 'constants/BOOL_FIELD';
import {
  REQUIRED,
} from 'constants/FORM_VALIDATION_RULES';
import TAG_COLORS from 'constants/TAG_COLORS';
import TEST_ATTRIBUTES from 'constants/TEST_ATTRIBUTES';
import {
  getSort,
} from 'helper';

import {
  downloadTemplates as downloadTemplatesAction,
  downloadTemplateSingle as downloadTemplateAction,
  get as getDictionary,
} from 'models/dictionaries/actions';
import {
  ENTITIES_WITH_NOT_UNIQUE_NAMES,
  FIELD_TYPES,
  REQUISITE_TYPES,
} from 'models/dictionaries/constants';
import { getDocumentTypes as getDocumentTypesSelector } from 'models/dictionaries/selectors';
import { ROLE_NAMES } from 'models/roles/constants';

import {
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Input,
  InputNumber,
  Select,
} from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

const COLOR_COLUMN = {
  key   : `color`,
  render: v => (v ? <DefaultTag color={v}>{v}</DefaultTag> : null),
  title : `Цвет`,
  width : 80,
};

const COLOR_FIELD = {
  key      : `color`,
  title    : `Цвет отображения`,
  Component: Select,
  options  : _.map(TAG_COLORS, value => ({ label: <DefaultTag color={value}>{value}</DefaultTag>, value })),
};

const ID_COLUMN = {
  key  : `id`,
  align: `center`,
  width: 60,
};

const CREDIT_HISTORY_TYPES = [
  {
    label: `Числовой, отделять пробелом разряд, округлять до целого числа`,
    value: `amount`,
  },
  {
    label: `Числовой, выводить как есть`,
    value: `number`,
  },
  {
    label: `Бинарный`,
    value: `boolean`,
  },
  {
    label: `Строка, выводить как есть`,
    value: `string`,
  },
];

const FORMAT_COLUMN = {
  align : `left`,
  key   : `type`,
  render: value => _.get(_.find(CREDIT_HISTORY_TYPES, { value }), `label`, value),
  title : `Формат данных`,
};

const FORMAT_FIELD = {
  key      : `type`,
  title    : `Формат данных`,
  Component: Select,
  options  : CREDIT_HISTORY_TYPES,
};

export const SOURCES_BY_ENTITY = {
  documentAlias: {
    documentType: {
      key      : `typeId`,
      title    : `Тип документа`,
      Component: props => (
        <Select
          {...props}
          allowClear
          optionFilterProp='label'
          showSearch
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { documentType } }) => _.map(
        _.sortBy(documentType, `id`),
        ({ id, name }) => ({ value: id, label: `${name} (${id})` }),
      ),
    },
  },
  documentGroup: {
    documentType: {
      key      : `typeIds`,
      title    : `Типы документов`,
      Component: props => (
        <Select
          {...props}
          allowClear
          mode='multiple'
          optionFilterProp='label'
          showSearch
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { documentType } }) => _.map(
        _.sortBy(documentType, `id`),
        ({ id, name }) => ({ value: id, label: `${name} (${id})` }),
      ),
    },
  },
  verificationResult: {
    verificationResultType: {
      key             : `verificationResultTypeId`,
      title           : `Список`,
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => v.description || v.name || `-`,
    },
  },
  verificationResultType: {
    verificationStatus: {
      key      : `saleVerificationStatusIds`,
      title    : `Показывать в продажах при переходе в статусы`,
      Component: props => (
        <Select
          {...props}
          allowClear
          mode='multiple'
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { verificationStatus } }) => _.map(
        verificationStatus,
        ({ description, id, name }) => ({ value: id, label: description || name }),
      ),
      optionsRender: v => (v.name),
    },
  },
};

export const CUSTOM_DATA_BY_ENTITY = {
  documentAlias: {
    checkIsLoaded: state => !_.isEmpty(getDocumentTypesSelector(state)),
    getFormFields: () => ([]),
    load         : dispatch => dispatch(getDictionary({ entity: `documentType` })),
  },
  documentGroup: {
    checkIsLoaded: state => !_.isEmpty(getDocumentTypesSelector(state)),
    getFormFields: () => ([]),
    load         : dispatch => dispatch(getDictionary({ entity: `documentType` })),
  },
};

export const COLUMNS_BY_ENTITY = {
  appealSubject      : [COLOR_COLUMN],
  applicationFallback: [BOOL_COLUMN(`isActive`, `Активна`)],
  creditHistoryField: [FORMAT_COLUMN],
  documentAlias: [
    {
      dataIndex: `documentType`,
      key      : `typeId`,
      title    : `Тип документа`,
      render   : docType => <DefaultTag key={docType?.id}>{docType?.name} ({docType?.id})</DefaultTag>,
      width    : 300,
    },
    BOOL_COLUMN(`isVersioned`, `Версионный`),
  ],
  documentGroup: [
    {
      dataIndex: `documentType`,
      key      : `typeIds`,
      title    : `Типы документов`,
      render   : values => _.map(values, (v, index) => (
        <DefaultTag key={`${v?.id}_${index}`}>{v?.name} ({v?.id})</DefaultTag>
      )),
      width: 300,
    },
    {
      dataIndex: `order`,
      key      : `order`,
      title    : `Порядок`,
      width    : 100,
    },
    BOOL_COLUMN(`isRecaptured`, `Переснятие`),
    BOOL_COLUMN(`isHidden`, `Скрытый`),
  ],
  documentTemplate: [
    {
      ...ID_COLUMN,
      sorter: getSort(`id`),
    },
    { key: `name`, title: `Название шаблона` },
    { key: `description`, title: `Описание шаблона` },
    {
      dataIndex: `mediaType`,
      key      : `mediaType`,
      title    : `Тип файла`,
    },
  ],
  notificationList: [BOOL_COLUMN(`isPersonal`, `Индивидуальный`)],
  phoneType   : [],
  proposalReaction       : [COLOR_COLUMN],
  smsTemplate: [
    ID_COLUMN,
    { key: `description`, title: `Описание`, width: 300 },
    { key: `text`, title: `Текст` },
    BOOL_COLUMN(`activeFlg`, `Активен`),
  ],
  telemetry: [COLOR_COLUMN],
  vectorTitle : [
    { key: `note`, title: `Примечание` },
    BOOL_COLUMN(`hidden`, `Скрывать поле`),
    BOOL_COLUMN(`isTitle`, `Заголовок блока`),
    {
      key  : `blockName`,
      title: `Блок данных`,
      align: `center`,
      width: 100,
    },
    {
      key  : `dataSource`,
      title: `Источник данных`,
      align: `center`,
      width: 120,
    },
    {
      key  : `fieldPath`,
      title: `Путь к полю`,
      align: `center`,
      width: 150,
    },
    {
      key  : `format`,
      title: `Формат значения поля`,
      align: `center`,
      width: 130,
    },
    {
      key  : `blockOrder`,
      title: `Порядок поля в блоке`,
      align: `center`,
      width: 100,
    },
  ],
  verificationLimit: [
    {
      key  : `min`,
      title: `Минимальное значение`,
      align: `center`,
      width: 260,
    },
    {
      key  : `max`,
      title: `Максимальное значение`,
      align: `center`,
      width: 260,
    },
  ],
  verificationResult: [
    {
      key  : `weight`,
      title: `Вес`,
      align: `center`,
      width: 60,
    },
    {
      dataIndex: `verificationResultType`,
      key      : `verificationResultTypeId`,
      title    : `Список`,
      render   : verificationResultType => {
        if (!verificationResultType || _.isEmpty(verificationResultType)) return `-`;
        return (verificationResultType.description || verificationResultType.name);
      },
    },
  ],
  verificationResultType: [
    {
      key   : `scriptIds`,
      title : `Идентификаторы скриптов`,
      align : `center`,
      render: v => (_.isNil(v) ? `Все` : _.map(v, (i, key) => <DefaultTag key={key}>{i}</DefaultTag>)),
      width : 200,
    },
    {
      key   : `fieldType`,
      render: v => _.get(_.find(FIELD_TYPES, { value: v }), `label`, `?`),
      title : `Тип полей`,
      width : 150,
    },
    {
      key  : `order`,
      title: `Порядок в общем списке`,
      width: 200,
    },
    {
      key  : `documentTypeName`,
      title: `Название типа документа`,
      width: 200,
    },
    BOOL_COLUMN(`fileFlg`, `Загрузка файла`),
    BOOL_COLUMN(`isRequiredSale`, `Обязательный в продажах при смене статуса`, { width: 180 }),
    {
      dataIndex: `verificationStatus`,
      key      : `saleVerificationStatusIds`,
      title    : `Показывать в продажах при переходе в статусы`,
      render   : values => _.map(values, ({ color, description, id, name }, index) => (
        <DefaultTag color={color} key={`${id}_${index}`}>{description || name}</DefaultTag>
      )),
      width: 200,
    },
  ],
  verificationStatus: [COLOR_COLUMN],
  warranty: [COLOR_COLUMN],
};

export const FIELDS_COMMON = (itemNames = [], itemEditing, entity) => [
  {
    key          : `name`,
    title        : `Название`,
    Component    : Input,
    initialValue : ``,
    required     : true,
    testAttribute: TEST_ATTRIBUTES.DICTIONARY_COMMON_FIELD_NAME,
    rules        : [
      REQUIRED,
      {
        validator: (pass, value) => {
          if (
            _.includes(ENTITIES_WITH_NOT_UNIQUE_NAMES, entity)
            || _.get(itemEditing, `name`) === value
            || !_.includes(itemNames, value)
          ) return Promise.resolve();
          return Promise.reject(new Error(`Такое название уже есть, придумайте другое`));
        },
      },
    ],
  },
  {
    key          : `description`,
    title        : `Описание`,
    Component    : Input.TextArea,
    initialValue : ``,
    testAttribute: TEST_ATTRIBUTES.DICTIONARY_COMMON_FIELD_DESCRIPTION,
  },
];

export const FIELDS_BY_ENTITY = {
  appealSubject      : [COLOR_FIELD],
  applicationFallback: [BOOL_FIELD(`isActive`, `Активна`)],
  clientCharacteristic: [COLOR_FIELD],
  clientCondition     : [
    {
      key  : `name`,
      title: `Наименование`,
    },
    {
      key  : `description`,
      title: `Описание`,
    },
  ],
  contractCondition: [
    {
      key  : `name`,
      title: `Наименование`,
    },
    {
      key  : `description`,
      title: `Описание`,
    },
  ],
  creditHistoryField: [FORMAT_FIELD],
  documentAlias: [
    BOOL_FIELD(`isVersioned`, `Версионный`),
  ],
  documentGroup: [
    {
      key      : `order`,
      title    : `Порядок сортировки`,
      Component: InputNumber,
    },
    BOOL_FIELD(`isRecaptured`, `Переснятие`),
    BOOL_FIELD(`isHidden`, `Скрытый`),
  ],
  notificationList: [BOOL_FIELD(
    `isPersonal`,
    `Индивидуальный`,
    { testAttribute: TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_INDIVIDUAL_FIELD },
  )],
  phoneType   : [],
  proposalReaction       : [COLOR_FIELD],
  smsTemplate: [
    {
      key  : `description`,
      title: `Описание`,
    },
    {
      key      : `text`,
      title    : `Текст шаблона`,
      Component: Input.TextArea,
      rows     : 9,
    },
    BOOL_FIELD(`activeFlg`, `Активен`),
  ],
  telemetry: [
    {
      ...COLOR_FIELD,
      required: true,
      rules   : [REQUIRED],
    },
  ],
  vectorTitle : [
    { key: `note`, title: `Примечание` },
    BOOL_FIELD(`hidden`, `Скрывать`),
    BOOL_FIELD(`isTitle`, `Заголовок блока`),
    {
      key      : `blockName`,
      title    : `Блок данных`,
      Component: Select,
      options  : [
        { label: `Фрод индикаторы`, value: `fraud` },
        { label: `БКИ`, value: `bki` },
        { label: `Авто`, value: `auto` },
        { label: `Внутренние данные`, value: `internal` },
        { label: `Фрод индикаторы: устройство пользователя`, value: `fraudDevice` },
      ],
    },
    {
      key      : `dataSource`,
      title    : `Источник данных`,
      Component: Select,
      options  : [
        { label: `Вектор`, value: `vector` },
        { label: `Авто`, value: `auto` },
      ],
    },
    { key: `fieldPath`, title: `Путь к полю (например, applicationCalc.fmsGoodFlag)` },
    {
      key  : `blockOrder`,
      title: `Порядок поля в блоке`,
      rules: [
        {
          validator: (pass, value) => {
            if (!value || (_.isInteger(_.toNumber(value)) && _.toNumber(value) >= 1)) return Promise.resolve();
            return Promise.reject(new Error(`Порядок должен быть натуральным числом`));
          },
        },
      ],
    },
    {
      key      : `format`,
      title    : `Формат значения`,
      Component: Select,
      options  : [
        { label: `Строка`, value: `string` },
        { label: `Сумма`, value: `amount` },
        { label: `Логический`, value: `boolean` },
      ],
    },
  ],
  verificationLimit: [
    { key: `min`, title: `Минимальное значение`, Component: InputNumber },
    { key: `max`, title: `Максимальное значение`, Component: InputNumber },
  ],
  verificationResult    : [{ key: `weight`, title: `Вес`, Component: InputNumber }],
  verificationResultType: [
    { key: `productId`, title: `Продукты (productId через запятую, без пробелов)` },
    { key: `scriptIds`, title: `Скрипты (scriptId через запятую, без пробелов)` },
    {
      key          : `fieldType`,
      title        : `Тип полей в списке`,
      Component    : Select,
      options      : FIELD_TYPES,
      optionsRender: value => _.get(_.find(REQUISITE_TYPES, { value }), `label`, `?`),
      required     : true,
      rules        : [REQUIRED],
    },
    { key: `order`, title: `Порядок сортировки в общем списке`, Component: InputNumber },
    { key: `documentTypeName`, title: `Название типа документа` },
    BOOL_FIELD(`fileFlg`, `Загрузка файла`),
    BOOL_FIELD(`isRequiredSale`, `Обязательный в продажах при смене статуса`),
  ],
  verificationStatus: [
    {
      ...COLOR_FIELD,
      required: true,
      rules   : [REQUIRED],
    },
  ],
  warranty: [
    {
      ...COLOR_FIELD,
      required: true,
      rules   : [REQUIRED],
    },
  ],
};

export const CELL_CONTROLS_BY_ENTITY = ({ canEdit, onUploadSingleClick }) => ({
  documentTemplate: [
    {
      icon   : <DownloadOutlined />,
      onClick: (dispatch, record) => dispatch(downloadTemplateAction({ name: record.name, templateId: record.id })),
      title  : `Скачать`,
      type   : `primary`,
    },
    {
      disabled: !canEdit,
      icon    : <UploadOutlined />,
      onClick : onUploadSingleClick,
      title   : canEdit ? `Обновить` : `Доступно для роли ${ROLE_NAMES.TEMPLATE_EDIT}`,
      type    : `default`,
    },
  ],
});

export const CUSTOM_CONTROLS_BY_ENTITY = ({
  canEdit,
  dispatch,
  selectedRowKeys,
  setUploadDocumentTemplateModalOpen,
}) => ({
  documentTemplate: [
    {
      isDisabled: _.isEmpty(selectedRowKeys),
      icon      : <DownloadOutlined />,
      onClick   : () => dispatch(downloadTemplatesAction({ templateIds: selectedRowKeys })),
      title     : `Скачать архив`,
    },
    {
      icon    : <UploadOutlined />,
      isHidden: !canEdit,
      onClick : () => setUploadDocumentTemplateModalOpen(true),
      title   : `Обновить шаблоны`,
    },
  ],
});

export const SELECTABLE_UNREMOVABLE_ENTITIES = [`documentTemplate`];
export const UNADDABLE_ENTITIES = [`documentTemplate`];
export const UNEDITABLE_ENTITIES = [`documentTemplate`];
export const UNSEARCHABLE_ENTITIES = [];
export const UNREMOVABLE_ENTITIES = [
  `documentTemplate`,
  `smsTemplate`,
];
export const LINKABLE_ENTITIES = {
};
export const OMIT_COLUMNS_ENTITIES = {
};

export const INACTIVE_VISIBLE_ENTITITES = [
  `smsTemplate`,
];

export const TEST_ATTRIBUTES_SEARCH_BY_ENTITY = {
  notificationList: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_ADD_BUTTON,
  },
};

export const EDITOR_ROLES_BY_ENTITY = {
  documentTemplate      : ROLE_NAMES.TEMPLATE_EDIT,
  verificationResult    : ROLE_NAMES.VERIFICATION_ADMIN,
  verificationResultType: ROLE_NAMES.VERIFICATION_ADMIN,
};
