import _ from 'lodash';

import {
  CLIENT_EDIT,
  DOCUMENT_UPLOADER,
  SIGN_DOCUMENT_CREATOR,
  SUPER_ADMIN,
} from 'models/roles/constants';

export const CREATE_SIGN_DOCUMENT_MODES = [{
  mode              : `paymentTransfer`,
  label             : `Перенос даты платежа`,
  documentTemplateId: 40,
}, {
  mode              : `settlementAgreement`,
  label             : `Соглашение об отступном (ПДП)`,
  documentTemplateId: 48,
}, {
  mode              : `settlementAgreementPartial`,
  label             : `Соглашение об отступном (ЧДП)`,
  documentTemplateId: 50,
}, {
  mode              : `dsChangePSK`,
  label             : `ДС "Изменение ПСК"`,
  documentTemplateId: 148,
},
];

export const CREATE_SIGN_DOCUMENT_ROLES = [
  SUPER_ADMIN,
  SIGN_DOCUMENT_CREATOR,
];

export const ALL_SIGN_DOCUMENTS_ROLES = [
  ...CREATE_SIGN_DOCUMENT_ROLES,
  ..._.flatMap(CREATE_SIGN_DOCUMENT_MODES, `roles`),
];

export const UPLOAD_DOCUMENT_ROLES = [
  SUPER_ADMIN,
  CLIENT_EDIT,
  DOCUMENT_UPLOADER,
];
