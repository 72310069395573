import * as React from 'react';
import {
  useContext,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import {
  copyToClipboard,
  hasIntersection,
} from 'helper';

import {
  createClientActiveEmployment as createClientActiveEmploymentAction,
  resetSms as resetSmsAction,
  switchProfile as switchProfileAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getIsLoadingInfo as isLoadingInfoSelector,
  isClientLoaded as isClientLoadedSelector,
} from 'models/clients/selectors';
import {
  CLIENT_EDIT,
  CLIENT_PROFILE_CLOSER,
  SMS_SENDER,
  SUPER_ADMIN,
} from 'models/roles/constants';
import { setModal as setModalAction } from 'models/ui/actions';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { usePath } from 'hooks/usePath';

import {
  AuditOutlined,
  CopyOutlined,
  EditOutlined,
  ExportOutlined,
  ImportOutlined,
  LinkOutlined,
  LoadingOutlined,
  MailOutlined,
  MoreOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';
import CreateModal from 'components/Modal/Create';
import {
  SubHeaderButtonDefault,
  subHeaderButtonDefaultMixin,
} from 'components/SubHeader/SubHeaderButtonDefault';
import { SubHeaderButtonPrimary } from 'components/SubHeader/SubHeaderButtonPrimary';
import { getHotkeyTitle } from 'pages/Layout/GlobalHotkeys/helpers';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { EditClientModal } from 'pages/PageClient/Single/components/EditClientModal/EditClientModal';
import {
  EDIT_WORK_FORM_FIELDS,
  getAllowedForTitle,
} from 'pages/PageClient/Single/Info/constants';

const Wrapper = styled.div``;

const ControlsWrapper = styled.div`
  display: flex;

  ${SubHeaderButtonDefault} {
    display: none;
    :not(:first-child) {
      margin-left: 12px;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    ${SubHeaderButtonPrimary} {
      display: block;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    ${SubHeaderButtonDefault} {
      display: block;

      :not(:first-child) {
        margin-left: 16px;
      }
    }
  }
`;

const ShowMoreControlsButton = styled.button`
  ${subHeaderButtonDefaultMixin};
  margin-left: 12px;

  .anticon:first-child {
    margin-right: 0;
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-left: 16px;
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hotkey = styled.div`
  display: none;

  @media(min-width: ${breakpoints.md}) {
    display: block;
    margin-left: 16px;
    color: ${palette.black80Color};
  }
`;

interface IProps {
  isReloading?: boolean;
  onReload?(): void;
}

const ClientSubHeaderControls: React.FC<IProps> = ({ isReloading, onReload }) => {
  const { personId } = useContext(ClientMatchContext) as { personId: string };
  const dispatch = useDispatch();
  const { pushPath } = usePath();

  const loanClient = useSelector(state => getClientSelector(state, personId));
  const isLoadingInfo = useSelector(state => isLoadingInfoSelector(state));
  // @ts-ignore
  const userRoles = useSelector(state => getRoleNamesSelector(state));

  const lkAccessFlg = loanClient?.lkAccessFlg;

  const createEmployment = (data: any) => dispatch(createClientActiveEmploymentAction({ data, personId }));
  const openSendSmsModal = () => dispatch(setModalAction({ personId, isOpen: true, modalName: `sendSms` }));
  const resetSms = () => dispatch(resetSmsAction({ personId }));
  const switchProfile = () => dispatch(switchProfileAction({ accessFlg: !lkAccessFlg, personId }));

  const [editClientModalVisible, setEditClientModalVisible] = useState(false);
  const toggleEditClientModalVisible = () => setEditClientModalVisible(!editClientModalVisible);

  const [editClientWorkModalVisible, setEditClientWorkModalVisible] = useState(false);
  const toggleEditClientWorkModalVisible = () => setEditClientWorkModalVisible(!editClientWorkModalVisible);

  const allowedToEditClientRoles = [SUPER_ADMIN, CLIENT_EDIT];
  const canEditClient = hasIntersection(userRoles, allowedToEditClientRoles);
  const editClientTitle = getAllowedForTitle(allowedToEditClientRoles);

  const allowedToSendSmsRoles = [SUPER_ADMIN, SMS_SENDER];
  const canSendSms = hasIntersection(userRoles, allowedToSendSmsRoles);
  const sendSmsTitle = getAllowedForTitle(allowedToSendSmsRoles);

  const allowedToSwitchProfileRoles = [SUPER_ADMIN, CLIENT_PROFILE_CLOSER];
  const canSwitchProfile = hasIntersection(userRoles, allowedToSwitchProfileRoles);
  const switchProfileTitle = getAllowedForTitle(allowedToSwitchProfileRoles);

  // @ts-ignore
  const personalWoopraUrl = WOOPRA_PERSONAL_URL(personId);

  // @ts-ignore
  const isClientLoaded = useSelector(state => isClientLoadedSelector(state, personId));

  const goToVerification = () => {
    pushPath(`/verification?personId=${personId}`);
  };


  const menuItems = [
    {
      hidden : !onReload,
      key    : `reload-client`,
      primary: true,
      onClick: onReload,
      icon   : isReloading ? <LoadingOutlined spin /> : <ReloadOutlined />,
      label  : (<Label>Перезагрузить <Hotkey>{getHotkeyTitle(`shift+R`)}</Hotkey></Label>),
    },
    {
      key     : `edit-client`,
      onClick : toggleEditClientModalVisible,
      icon    : <EditOutlined />,
      label   : `Изменить данные`,
      tooltip : !canEditClient ? editClientTitle : undefined,
      disabled: !canEditClient,
    },
    {
      key : `divider-1`,
      type: `divider`,
    },
    {
      key     : `go-to-verification`,
      onClick : goToVerification,
      icon    : <AuditOutlined />,
      disabled: !isClientLoaded,
      label   : (<Label>Искать на верификации <Hotkey>{getHotkeyTitle(`shift+V`)}</Hotkey></Label>),
    },
    {
      key : `divider-2`,
      type: `divider`,
    },
    {
      key    : `reset-sms`,
      onClick: resetSms,
      icon   : <ReloadOutlined />,
      label  : `Сбросить счетчик СМС`,
    },
    {
      key    : `copy-to-clipboard`,
      onClick: () => copyToClipboard(personId),
      icon   : <CopyOutlined />,
      label  : `Скопировать ID`,
    },
    {
      key     : `open-send-sms-modal`,
      disabled: !canSendSms,
      onClick : openSendSmsModal,
      tooltip : !canSendSms ? sendSmsTitle : undefined,
      icon    : <MailOutlined />,
      label   : `Отправить SMS`,
    },
    {
      key  : `woopra-link`,
      label: (
        <a
          href={personalWoopraUrl}
          rel='noreferrer'
          style={{ textDecoration: `underline` }}
          target='_blank'
        >
          <LinkOutlined />
          Woopra
        </a>
      ),
    },
    {
      key : `divider-3`,
      type: `divider`,
    },
    {
      key     : `switch-profile`,
      disabled: !canSwitchProfile,
      onClick : switchProfile,
      tooltip : !canSwitchProfile ? switchProfileTitle : undefined,
      icon    : lkAccessFlg ? <ImportOutlined /> : <ExportOutlined />,
      label   : lkAccessFlg ? `Закрыть ЛК` : `Открыть ЛК`,
    },
  ];

  useHotkeys(`shift+R`, () => onReload && onReload(), {}, [onReload]);
  useHotkeys(`shift+V`, goToVerification, {}, []);

  return (
    <Wrapper>
      <ControlsWrapper>
        <SubHeaderButtonDefault
          disabled={!canEditClient}
          onClick={() => toggleEditClientModalVisible()}
          title={editClientTitle}
        >
          <EditOutlined />Изменить данные
        </SubHeaderButtonDefault>

        {onReload && (
          <SubHeaderButtonPrimary disabled={isReloading} onClick={() => onReload()}>
            {isReloading ? (
              <LoadingOutlined spin />
            ) : (
              <ReloadOutlined />
            )}
            Перезагрузить
          </SubHeaderButtonPrimary>
        )}

        <DefaultDropdown
          // @ts-ignore
          menu={{ items: menuItems }}
          trigger={[`click`]}
        >
          <ShowMoreControlsButton>
            <MoreOutlined />
          </ShowMoreControlsButton>
        </DefaultDropdown>
      </ControlsWrapper>

      <EditClientModal
        isOpen={editClientModalVisible}
        personId={personId}
        toggleOpen={toggleEditClientModalVisible}
      />

      {canEditClient && editClientWorkModalVisible && (
        <CreateModal
          close={toggleEditClientWorkModalVisible}
          create={createEmployment}
          formFields={EDIT_WORK_FORM_FIELDS}
          isLoading={isLoadingInfo}
          mode='create'
          title='Добавление активного трудоустройства клиента'
          visible={editClientWorkModalVisible}
        />
      )}

    </Wrapper>
  );
};

export default ClientSubHeaderControls;
