import { createAction } from 'redux-actions';

export const model = `dictionaries`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const CREATE = `CREATE`;
export const CREATE_EXTERNAL = `CREATE_EXTERNAL`;
export const DOWNLOAD_TEMPLATE_SINGLE = `DOWNLOAD_TEMPLATE_SINGLE`;
export const DOWNLOAD_TEMPLATES = `DOWNLOAD_TEMPLATES`;
export const GET = `GET`;
export const GET_MULTIPLE = `GET_MULTIPLE`;
export const GET_SINGLE = `GET_SINGLE`;
export const LINK_EXTERNAL = `LINK_EXTERNAL`;
export const REMOVE = `REMOVE`;
export const REMOVE_EXTERNAL = `REMOVE_EXTERNAL`;
export const REMOVE_SINGLE = `REMOVE_SINGLE`;
export const REMOVE_SINGLE_EXTERNAL = `REMOVE_SINGLE_EXTERNAL`;
export const RESET = `RESET`;
export const RESET_FINISHED = `RESET_FINISHED`;
export const SET = `SET`;
export const SET_FINISHED = `SET_FINISHED`;
export const SET_ITEMS = `SET_ITEMS`;
export const SET_LOADING = `SET_LOADING`;
export const SET_SEARCH_STRING = `SET_SEARCH_STRING`;
export const UNLINK_EXTERNAL = `UNLINK_EXTERNAL`;
export const UPDATE = `UPDATE`;
export const UPDATE_EXTERNAL = `UPDATE_EXTERNAL`;
export const UPLOAD_EXTERNAL = `UPLOAD_EXTERNAL`;

export const create = ca(CREATE);
export const downloadTemplateSingle = ca(DOWNLOAD_TEMPLATE_SINGLE);
export const downloadTemplates = ca(DOWNLOAD_TEMPLATES);
export const get = ca(GET);
export const getMultiple = ca(GET_MULTIPLE);
export const getSingle = ca(GET_SINGLE);
export const linkExternal = ca(LINK_EXTERNAL);
export const remove = ca(REMOVE);
export const removeSingle = ca(REMOVE_SINGLE);
export const reset = ca(RESET);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const setSearchString = ca(SET_SEARCH_STRING);
export const update = ca(UPDATE);
export const unlinkExternal = ca(UNLINK_EXTERNAL);
