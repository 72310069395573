import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import { Link } from 'dva/router';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { get as uiGet } from 'models/ui/selectors';

import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

import { BREADCRUMB_NAME_MAP } from '../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: ${palette.backgroundAccentColor};

  @media(min-width: 433px) {
    flex-direction: row;
    align-items: center;
  }

  @media(min-width: ${breakpoints.md}) {
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: none;
  }

  @media(min-width: ${breakpoints.lg}) {
    padding: 20px 60px;
  }
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 8px;

  li:last-child {
    .ant-breadcrumb-link {
      a {
        color: ${palette.whiteColor};
        padding: 0 8px;
        background: ${getLightStyleOr(
    p => transparentize(0.9, palette.backgroundColor(p)),
    palette.backgroundColor,
  )};
        border-radius: 16px;
      }
    }
  }

  .ant-breadcrumb-link {
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      background-color: transparent;
      color: ${palette.black60Color};
    }
  }

  @media screen and (max-width: 432px) {
    margin-bottom: 8px;
  }
`;

interface IComponentProps {
  location?: any;
}

const Toolbar: React.FC<IComponentProps> = ({ location }) => {
  const breadcrumbAppendItems = useSelector(state => uiGet(state, `breadcrumbs`)) || [];
  const { pathname } = location;
  const pathSnippets = _.compact(_.split(pathname, `/`));
  const breadcrumbItems = [
    ..._.map(pathSnippets, (path, index) => {
      const url = `/${pathSnippets.slice(0, _.toNumber(index) + 1).join(`/`)}`;
      // @ts-ignore
      return BREADCRUMB_NAME_MAP[url] ? (
        <Breadcrumb.Item key={url}>
          {/*@ts-ignore*/}
          <Link to={url}>{BREADCRUMB_NAME_MAP[url]}</Link>
        </Breadcrumb.Item>
      ) : null;
    }),
    ..._.map(breadcrumbAppendItems, ({ name, url }) => (
      <Breadcrumb.Item key={name}>
        <Link to={url}>{name}</Link>
      </Breadcrumb.Item>
    )),
  ];

  return (
    <Wrapper>
      <StyledBreadcrumb separator={<RightOutlined style={{ fontSize: `9px`, color: `#C5CFDD` }} />}>
        {breadcrumbItems}
      </StyledBreadcrumb>

      {/*{(!mobile || pathname === `/admin` || pathname === `/admin/dictionaries`) && (*/}
      {/*  <ToolbarControls />*/}
      {/*)}*/}
    </Wrapper>
  );
};

export default Toolbar;
