import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import {
  Route,
  Switch,
} from 'dva/router';
import styled from 'styled-components';

import { hasIntersection } from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { Layout } from 'antd';

import DictionariesList from './Dictionaries/List';
import DictionariesPlural from './Dictionaries/Plural';
import Main from './Main/Main';
import OperatorsPlural from './Operators/Plural';
import RoleGroupsPlural from './RoleGroups/Plural';
import RolesPlural from './Roles/Plural';
import Toolbar from './Toolbar/Toolbar';

import {
  DICTIONARY_ROLES,
} from './constants';

const StyledLayout = styled(Layout)`
  height: 100%;
`;

const StyledLayoutContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`;

const StyledPageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

const PageAdmin = ({ location }) => {
  const userRoles = useSelector(state => getRoleNamesSelector(state));

  return (
    <StyledLayout>
      <StyledLayoutContent>
        <Toolbar location={location} />
        <StyledPageContent>
          <Switch>
            <Route component={Main} exact path='/admin' />
            {hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ...DICTIONARY_ROLES]) && (
              <Route component={DictionariesList} exact path='/admin/dictionaries' />
            )}
            {hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ...DICTIONARY_ROLES]) && (
              <Route component={DictionariesPlural} exact path='/admin/dictionaries/:entity' />
            )}
            <Route component={OperatorsPlural} exact path='/admin/operators' />
            {_.includes(userRoles, ROLE_NAMES.SUPER_ADMIN) && (
              <Route component={RolesPlural} exact path='/admin/roles' />
            )}
            {_.includes(userRoles, ROLE_NAMES.SUPER_ADMIN) && (
              <Route component={RoleGroupsPlural} exact path='/admin/role-groups' />
            )}
          </Switch>
        </StyledPageContent>
      </StyledLayoutContent>
    </StyledLayout>
  );
};

export default PageAdmin;
