import React from 'react';

import { TEventlogItem } from 'models/eventlogs/types';

import { VerificationDecision } from './Verification/Decision';
import { VerificationSend } from './Verification/Send';
import { VerificationSendToVerification } from './Verification/SendToVerification';
import { Operator } from './Operator';
import { Status } from './Status';

interface IComponentProps extends TEventlogItem {
  date:string;
}

export const Item: React.FC<IComponentProps> = props => {
  if (props.entity === `task`) {
    if (props.type === `operator`) return <Operator {...props} />;
    if (props.type === `status`) return <Status {...props} />;
  }

  if (props.entity === `verification`) {
    if (props.type === `decision`) return <VerificationDecision {...props} />;
    if (props.type === `operator`) return <Operator {...props} />;
    if (props.type === `sendToVerification`) return <VerificationSendToVerification {...props} />;
    if (props.type === `send`) return <VerificationSend {...props} />;
    if (props.type === `status`) return <Status {...props} />;
  }

  return null;
};
