/* eslint-disable max-len */
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { Link } from 'dva/router';

import { hasIntersection } from 'helper';

import {
  closeApplication as closeApplicationAction,
  getClientApplications as getClientApplicationsAction,
  reopenApplication as reopenApplicationAction,
  repairApplication as repairApplicationAction,
  sendApplicationToCall as sendApplicationToCallAction,
} from 'models/clients/actions';
import {
  getClientApplicationsWithLoans as getClientApplicationsWithLoansSelector,
  getIsLoading as isLoadingSelector,
} from 'models/clients/selectors';
import {IApplication, ILoan, IOffer, IOperation} from 'models/clients/types';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { useDynamicFilterValues } from 'hooks/useDynamicFilterValues';

import {
  CloseCircleOutlined,
  FileSearchOutlined,
  IssuesCloseOutlined,
  SettingOutlined,
  SwapOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import {
  Descriptions,
  Dropdown,
} from 'antd';
import ApplicationOperation from 'components/ApplicationOperation';
import LoanDescription from 'components/LoanDescription';
import LoanOperations from 'components/LoanOperations';
import OfferDescription from 'components/OfferDescription';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import { COLUMNS } from './constants';

export const PageClientSingleApplication = ({ isWrapped = true, personId: _personId }: { isWrapped?: boolean; personId?: string;}) => {
  const dispatch = useDispatch();
  const clientMatch = useContext(ClientMatchContext);
  const personId = _personId || clientMatch?.personId;
  const applications = useSelector(state => getClientApplicationsWithLoansSelector(state, personId));
  const userRoles = useSelector(getRoleNamesSelector);
  const isLoading = useSelector(isLoadingSelector);

  const getClientApplications = useCallback(
    () => dispatch(getClientApplicationsAction({ personId })),
    [dispatch],
  );

  const closeApplication = useCallback(
      (applicationId: string) => dispatch(closeApplicationAction({ applicationId, callback: getClientApplications })),
    [dispatch],
  );

  const reopenApplication = useCallback(
      (applicationId: string) => dispatch(reopenApplicationAction({ applicationId, callback: getClientApplications })),
    [dispatch],
  );

  const repairApplication = useCallback(
      (application: string) => dispatch(repairApplicationAction({ application, callback: getClientApplications })),
    [dispatch],
  );

  const sendApplicationToCall = useCallback(
    ({ applicationId, call, callback, hideMessage }: { applicationId: string; call: number; callback: () => void; hideMessage?: boolean; }) => {
      dispatch(sendApplicationToCallAction({ applicationId, hideMessage, call, callback }));
    },
    [dispatch],
  );

  // const sendApplicationToCall1 = useCallback(
  //   (applicationId: string) => sendApplicationToCall({
  //     applicationId,
  //     call    : 2,
  //     callback: getClientApplications,
  //   }),
  //   [sendApplicationToCall],
  // );

  const sendApplicationToCreditHistory = useCallback(
    (applicationId: string) => sendApplicationToCall({
      applicationId,
      call    : 4,
      callback: getClientApplications,
    }),
    [sendApplicationToCall],
  );

  useEffect(() => {
    if (_.isEmpty(applications)) getClientApplications();
  }, []);

  const [productNames] = useDynamicFilterValues(applications, `productName`);

  const getMenu = (record: { id: string; personId: string; }) => ({
    items: [
      {
        key  : 0,
        // @ts-ignore
        icon : <FileSearchOutlined />,
        label: (
          <Link to={`/client/person/${personId}/doc?applicationId=${record.id}`}>
            Перейти к документам
          </Link>
        ),
      },
      ...(hasIntersection(userRoles, [ROLE_NAMES.APPEAL_OPERATOR, ROLE_NAMES.SUPER_ADMIN])
        ? [
          { type: `divider` },
          {
            key    : 3,
            label  : `Переоткрыть заявку`,
            // @ts-ignore
            icon   : <SwapOutlined />,
            onClick: () => reopenApplication(record.id),
          },
          // {
          //   key    : 4,
          //   label  : `Отправить на Call1`,
          //   // @ts-ignore
          //   icon   : <IssuesCloseOutlined />,
          //   onClick: () => sendApplicationToCall1(record.id),
          // },
          {
            key    : 5,
            label  : `Перезапрос КИ и Вектора`,
            // @ts-ignore
            icon   : <IssuesCloseOutlined />,
            onClick: () => sendApplicationToCreditHistory(record.id),
          },
          {
            key    : 7,
            label  : `Починить заявку`,
            // @ts-ignore
            icon   : <ToolOutlined />,
            // @ts-ignore
            onClick: () => repairApplication(record),
          },
          { type: `divider` },
          {
            danger : true,
            key    : 8,
            label  : `Закрыть заявку`,
            // @ts-ignore
            icon   : <CloseCircleOutlined />,
            onClick: () => closeApplication(record.id),
          },
        ]
        : []
      ),
    ],
  });

  const filterOperations = (operations: IOperation[]) => _.filter(operations, operation => (
    operation.operationTypeId === 1 && _.includes([9, 12], operation.operationStatusId)
  ));

  const content = (
    <AdjustableTable
      columns={COLUMNS(productNames)}
      dataSource={applications}
      expandable={{
        expandedRowRender: ({ applicationStatusId, loan, offers, operation }: { applicationStatusId: number; loan: ILoan; offers: IOffer[]; operation: IOperation[]; }) => (
          <div>
            {!_.isEmpty(operation) && <ApplicationOperation operation={operation} />}
            {applicationStatusId === 12 && loan && (
              <>
                <Descriptions bordered size='small' title='Операции' />
                {/*@ts-ignore*/}
                <LoanOperations filter={filterOperations} loanId={loan.id} personId={personId} />
              </>
            )}
            {loan && <LoanDescription loan={loan} />}
            {!_.isEmpty(offers) && (
              <>
                <Descriptions
                  bordered
                  layout='vertical'
                  size='small'
                  title='Офферы'
                />
                {_.map(offers, offer => <OfferDescription key={offer.id} offer={offer} />)}
              </>
            )}
          </div>
        ),

        rowExpandable: (application: IApplication) => !_.isEmpty(application.offers) || !_.isEmpty(application.operation) || application.loan,
      }}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(applications),
        size            : `small`,
      }}
      preferences={{
        path      : `clientInfo.application`,
        cellRender: (text, record) => (
          <Dropdown
            // @ts-ignore
            menu={getMenu(record)}
            trigger={[`click`]}
          >
            {/* @ts-ignore */}
            <SettingOutlined style={{ padding: `8px` }} />
          </Dropdown>
        ),
      }}
      scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
    />

  );

  return isWrapped ? (
    <ClientTab isReloading={isLoading} onReload={getClientApplications}>
      {content}
    </ClientTab>
  ) : content;
};

export default PageClientSingleApplication;
