import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  formatDate,
  hasIntersection,
} from 'helper';

import { getClientApplicationsForDocs as getClientApplicationsForDocsSelector } from 'models/clients/selectors';
import { IDoc } from 'models/clients/types';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import {
  DiffOutlined,
} from '@ant-design/icons';
import {
  Button,
  Select,
} from 'antd';
import {
  UPLOAD_DOCUMENT_ROLES,
} from 'pages/PageClient/Single/Docs/constants';
import DownloadGroupControls from 'pages/PageClient/Single/Docs/DownloadGroupControls';

const SelectWrapper = styled.div`
  overflow: hidden;
`;

const ControlsSelect = styled(Select)`
  width: 100%;
`;

const ControlsButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
  min-width: 32px;

  @media(min-width: ${breakpoints.md}) {
    width: inherit;
    margin-bottom: 0;

    :not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: ${breakpoints.md}) {
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const StyledControls = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 16px;

  @media(min-width: ${breakpoints.md}) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
`;
interface IProps {
  applicationId: string;
  docs: IDoc[];
  isSelectDisabled?: boolean;
  personId: string;
  openUploadModal():void;
  setApplicationId(applicationId:string):void;
}

const Controls: React.FC<IProps> = ({
  applicationId,
  docs,
  isSelectDisabled = false,
  openUploadModal,
  personId,
  setApplicationId,
}) => {
  const applications = useSelector(state => getClientApplicationsForDocsSelector(state, personId));
  const userRoles = useSelector(getRoleNamesSelector);

  const canUpload = applicationId && hasIntersection(userRoles, UPLOAD_DOCUMENT_ROLES);

  return (
    <StyledControls>
      <SelectWrapper>
        <ControlsSelect
          disabled={isSelectDisabled}
          // @ts-ignore
          onChange={setApplicationId}
          value={applicationId}
        >
          {_.map(applications, application => (
            <Select.Option key={application.id} value={application.id}>
              {`${application.id} от ${formatDate(application.applicationDtm)}, статус: ${(application.applicationStatusName)}`} {/* eslint-disable-line max-len */}
            </Select.Option>
          ))}
        </ControlsSelect>
      </SelectWrapper>
      <ButtonsWrapper>
        <DownloadGroupControls
          applicationId={applicationId}
          buttonType='default'
          docs={docs}
          personId={personId}
        />
        <ControlsButton
          disabled={!canUpload}
          icon={<DiffOutlined />}
          onClick={() => openUploadModal()}
        >
          Загрузить документ
        </ControlsButton>
      </ButtonsWrapper>
    </StyledControls>
  );
};

export default Controls;
