import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { hasIntersection } from 'helper';

import { TRoleName } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { getAllowedForTitle } from 'pages/PageClient/Single/Info/constants';
import {Button} from "antd";

export const EntityCardTitleControlWrapper = styled(Button)<{ isDisabled?: boolean }>`
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  color: ${getLightStyleOr(palette.black60Color, palette.black40Color)};
  border: none;
  background: transparent;
  transition: 200ms ease;

  :hover {
    color: ${palette.black80Color};
  }

  :active {
    color: ${palette.black100Color};
  }

  :disabled {
    color: ${palette.textDisabledColor} !important;
    cursor: not-allowed;
  }

  ${p => p.isDisabled && `
    color: ${palette.textDisabledColor(p)} !important;
    cursor: not-allowed;
  `}
`;

interface IProps {
  children?: React.ReactNode;
  actionTitle: string;
  allowedRoles?: TRoleName[];
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const EntityCardTitleControlComponent: React.FC<IProps> = ({
  actionTitle,
  allowedRoles,
  children,
  className,
  disabled,
  onClick,
}) => {
  const userRoles = useSelector(getRoleNamesSelector);

  const isAllowed = _.isEmpty(allowedRoles) ? true : hasIntersection(userRoles, allowedRoles);
  // @ts-ignore
  const allowedForTitle = _.isEmpty(allowedRoles) ? `` : getAllowedForTitle(allowedRoles);
  const isDisabled = disabled || !isAllowed;
  const title = isAllowed ? actionTitle : <>{actionTitle}<br />{allowedForTitle}</>;
  const titleString = isAllowed ? actionTitle : `${actionTitle}\n${allowedForTitle}`;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const onClickHandler = isAllowed ? onClick : _.noop;

  return (
    <DefaultTooltip title={title}>
      <EntityCardTitleControlWrapper
        className={className}
        icon={children}
        isDisabled={isDisabled}
        onClick={onClickHandler}
        title={titleString}
      />
    </DefaultTooltip>
  );
};

export const EntityCardTitleControl = EntityCardTitleControlComponent;
