import React from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';

import {
  getColumn as getColumnDefault,
  getSort,
  sortAlphabetically,
} from 'helper';

import { getFullName } from 'models/clients/helpers';

import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
} from 'antd';
import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

const getColumn = props => getColumnDefault({ align: `center`, width: 150, ...props });

export const COLUMNS = ({ dictionaries, operators }) => _.map([
  {
    key   : `verificationStatus`,
    title : `Статус`,
    render: (v = {}) => {
      if (!v) return null;
      return <DefaultTag color={v.color}>{v.description}</DefaultTag>
    },
    sorter: getSort(`verificationStatus.description`, sortAlphabetically),
  },
  {
    key   : `client`,
    title : `Клиент`,
    render: (client, { personId }) => (
      <Link to={`/client/person/${personId}/info`}>
        {getFullName(client)}
      </Link>
    ),
  },
  {
    key   : `id`,
    title : ``,
    render: (id, { applicationId, personId }) => (
      <Link to={`/verification/${id}/person/${personId}/info?applicationId=${applicationId}`}>
        <Button icon={<CaretRightOutlined />} shape='circle' type='primary' />
      </Link>
    ),
    width: 45,
  },
  {
    key   : `approved`,
    title : `Одобрено`,
    render: v => <Checkbox checked={v} />,
    width : 90,
  },
  {
    dataIndex: [`application`, `requestedTerm`],
    key      : `requestedTerm`,
    title    : `Срок (запрошен)`,
    width    : 120,
  },
  {
    dataIndex: `term`,
    key      : `term`,
    title    : `Срок (одобрен)`,
    width    : 120,
  },
  {
    dataIndex: `telemetryId`,
    key      : `telemetryId`,
    title    : `Телематика`,
    width    : 150,
    render: (id) => {
      const v = _.find(dictionaries.telemetry, { id });
      if (!v) return null;
      return <DefaultTag color={v.color}>{v.description}</DefaultTag>
    },
  },
  {
    dataIndex: `warrantyId`,
    key      : `warrantyId`,
    title    : `Доп. гарантия`,
    width    : 150,
    render: (id) => {
      const v = _.find(dictionaries.warranty, { id });
      if (!v) return null;
      return <DefaultTag color={v.color}>{v.description}</DefaultTag>
    },
  },
  {
    key             : `createdAt`,
    title           : `Поступила в СРМ`,
    defaultSortOrder: `descend`,
    align           : `left`,
    render          : v => <CellDateTime value={v} />,
    sorter          : getSort(`createdAt`, sortAlphabetically),
    width    : 190,
  },
  {
    key  : `operatorId`,
    title: `В работе у сотрудника`,
    width: 180,
    render: id => {
      console.log({ id, operators });
      return _.find(operators, {id})?.user?.fullName || `-`
    },
  },
], getColumn);
