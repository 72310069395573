import { createAction } from 'redux-actions';

export const model = `clients`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const CLOSE_APPLICATION = `CLOSE_APPLICATION`;
export const CLOSE_LOAN = `CLOSE_LOAN`;
export const CLOSE_CLIENT_MARKETING_OFFER = `CLOSE_CLIENT_MARKETING_OFFER`;
export const CLOSE_SIGN_DOCUMENT = `CLOSE_SIGN_DOCUMENT`;
export const CREATE_CLIENT_ACTIVE_EMPLOYMENT = `UPDATE_CLIENT_ACTIVE_WORK`;
export const CREATE_CLIENT_DOCUMENT = `CREATE_CLIENT_DOCUMENT`;
export const CREATE_CLIENT_PHONE = `CREATE_CLIENT_PHONE`;
export const CREATE_MARKETING_OFFER = `CREATE_MARKETING_OFFER`;
export const DOWNLOAD_SMS = `DOWNLOAD_SMS`;
export const FINALIZE_SIGN_DOCUMENT = `FINALIZE_SIGN_DOCUMENT`;
export const GENERATE_REPAYMENT_LINK = `GENERATE_REPAYMENT_LINK`;
export const GET_CLIENT = `GET_CLIENT`;
export const GET_CLIENTS_RECENT = `GET_CLIENTS_RECENT`;
export const GET_CLIENT_APPLICATIONS = `GET_CLIENT_APPLICATIONS`;
export const GET_CLIENT_APPLICATION_VECTOR = `GET_CLIENT_APPLICATION_VECTOR`;
export const GET_CLIENT_APPLICATION_DOCS = `GET_CLIENT_APPLICATION_DOCS`;
export const GET_CLIENT_APPLICATION_OFFERS = `GET_CLIENT_APPLICATION_OFFERS`;
export const GET_CLIENT_APPLICATION_OPERATION = `GET_CLIENT_APPLICATION_OPERATION`;
export const GET_CLIENT_AUTH_CALLS = `GET_CLIENT_AUTH_CALLS`;
export const GET_CLIENT_CALIBRI_CHATS = `GET_CLIENT_CALIBRI_CHATS`;
export const GET_CLIENT_CALIBRI_CHAT_HISTORY = `GET_CLIENT_CALIBRI_CHAT_HISTORY`;
export const GET_CLIENT_CREDIT_HISTORY = `GET_CLIENT_CREDIT_HISTORY`;
export const GET_CLIENT_INFO = `GET_CLIENT_INFO`;
export const GET_CLIENT_LOANS = `GET_CLIENT_LOANS`;
export const GET_CLIENT_LOAN_AGREEMENT = `GET_CLIENT_LOAN_AGREEMENT`;
export const GET_CLIENT_LOAN_DETAILS = `GET_CLIENT_LOAN_DETAILS`;
export const GET_CLIENT_LOAN_DOCUMENT = `GET_CLIENT_LOAN_DOCUMENT`;
export const GET_CLIENT_LOAN_OPERATIONS = `GET_CLIENT_LOAN_OPERATIONS`;
export const GET_CLIENT_LOAN_REPAYMENTS = `GET_CLIENT_LOAN_REPAYMENTS`;
export const GET_CLIENT_LOAN_REPAYMENT_LINKS = `GET_CLIENT_LOAN_REPAYMENT_LINKS`;
export const GET_CLIENT_LOAN_SCHEDULE = `GET_CLIENT_LOAN_SCHEDULE`;
export const GET_CLIENT_MARKETING_OFFERS = `GET_CLIENT_MARKETING_OFFERS`;
export const GET_CLIENT_PHONES = `GET_CLIENT_PHONES`;
export const GET_CLIENT_SIGN_DOCUMENTS = `GET_CLIENT_SIGN_DOCUMENTS`;
export const GET_CLIENT_SMS = `GET_CLIENT_SMS`;
export const GET_CLIENT_VERIFICATIONS = `GET_CLIENT_VERIFICATIONS`;
export const POST_MARKETING_BLACKLIST = `POST_MARKETING_BLACKLIST`;
export const REOPEN_APPLICATION = `REOPEN_APPLICATION`;
export const REPAIR_APPLICATION = `REPAIR_APPLICATION`;
export const RESET = `RESET`;
export const RESET_SMS = `RESET_SMS`;
export const SEARCH_CLIENTS = `SEARCH_CLIENTS`;
export const SEND_APPLICATION_TO_CALL = `SEND_APPLICATION_TO_CALL`;
export const SEND_CLIENT_MARKETING_OFFER_DECISION = `SEND_CLIENT_MARKETING_OFFER_DECISION`;
export const SEND_SMS = `SEND_SMS`;
export const SET = `SET`;
export const SET_CALIBRI_CHAT_HISTORY_LOADING = `SET_CALIBRI_CHAT_HISTORY_LOADING`;
export const SET_CLIENT_ATTRIBUTE = `SET_CLIENT_ATTRIBUTE`;
export const SET_CLIENT_CREDIT_HISTORY = `SET_CLIENT_CREDIT_HISTORY`;
export const SET_CLIENT_LOADED = `SET_CLIENT_LOADED`;
export const SET_CLIENT_SUBATTRIBUTE = `SET_CLIENT_SUBATTRIBUTE`;
export const SET_LOADING = `SET_LOADING`;
export const SET_RECENT_LOADING = `SET_RECENT_LOADING`;
export const SWITCH_PROFILE = `SWITCH_PROFILE`;
export const UPDATE_CLIENT_ADDRESS = `UPDATE_CLIENT_ADDRESS`;
export const UPDATE_CLIENT_COMMUNICATION_TYPE = `UPDATE_CLIENT_COMMUNICATION_TYPE`;
export const UPDATE_CLIENT_CONDITION = `UPDATE_CLIENT_CONDITION`;
export const UPDATE_CLIENT_PHONE = `UPDATE_CLIENT_PHONE`;
export const UPDATE_CONTRACT_CONDITION = `UPDATE_CONTRACT_CONDITION`;
export const UPLOAD_APPLICATION_DOCUMENT = `UPLOAD_APPLICATION_DOCUMENT`;
export const UPLOAD_MARKETING_OFFERS = `UPLOAD_MARKETING_OFFERS`;

export const closeApplication = ca(CLOSE_APPLICATION);
export const closeClientMarketingOffer = ca(CLOSE_CLIENT_MARKETING_OFFER);
export const closeSignDocument = ca(CLOSE_SIGN_DOCUMENT);
export const createClientActiveEmployment = ca(CREATE_CLIENT_ACTIVE_EMPLOYMENT);
export const createClientDocument = ca(CREATE_CLIENT_DOCUMENT);
export const createClientPhone = ca(CREATE_CLIENT_PHONE);
export const createMarketingOffer = ca(CREATE_MARKETING_OFFER);
export const downloadSms = ca(DOWNLOAD_SMS);
export const finalizeSignDocument = ca(FINALIZE_SIGN_DOCUMENT);
export const getClient = ca(GET_CLIENT);
export const getClientApplicationDocs = ca(GET_CLIENT_APPLICATION_DOCS);
export const getClientApplicationOffers = ca(GET_CLIENT_APPLICATION_OFFERS);
export const getClientApplicationOperation = ca(GET_CLIENT_APPLICATION_OPERATION);
export const getClientApplicationVector = ca(GET_CLIENT_APPLICATION_VECTOR);
export const getClientApplications = ca(GET_CLIENT_APPLICATIONS);
export const getClientCreditHistory = ca(GET_CLIENT_CREDIT_HISTORY);
export const getClientInfo = ca(GET_CLIENT_INFO);
export const getClientLoanDetails = ca(GET_CLIENT_LOAN_DETAILS);
export const getClientLoanOperations = ca(GET_CLIENT_LOAN_OPERATIONS);
export const getClientLoans = ca(GET_CLIENT_LOANS);
export const getClientMarketingOffers = ca(GET_CLIENT_MARKETING_OFFERS);
export const getClientPhones = ca(GET_CLIENT_PHONES);
export const getClientSignDocuments = ca(GET_CLIENT_SIGN_DOCUMENTS);
export const getClientSms = ca(GET_CLIENT_SMS);
export const getClientVerifications = ca(GET_CLIENT_VERIFICATIONS);
export const getClientsRecent = ca(GET_CLIENTS_RECENT);
export const postMarketingBlacklist = ca(POST_MARKETING_BLACKLIST);
export const reopenApplication = ca(REOPEN_APPLICATION);
export const repairApplication = ca(REPAIR_APPLICATION);
export const reset = ca(RESET);
export const resetSms = ca(RESET_SMS);
export const searchClients = ca(SEARCH_CLIENTS);
export const sendApplicationToCall = ca(SEND_APPLICATION_TO_CALL);
export const sendClientMarketingOfferDecision = ca(SEND_CLIENT_MARKETING_OFFER_DECISION);
export const sendSms = ca(SEND_SMS);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const switchProfile = ca(SWITCH_PROFILE);
export const updateClientAddress = ca(UPDATE_CLIENT_ADDRESS);
export const updateClientCommunicationType = ca(UPDATE_CLIENT_COMMUNICATION_TYPE);
export const updateClientCondition = ca(UPDATE_CLIENT_CONDITION);
export const updateClientPhone = ca(UPDATE_CLIENT_PHONE);
export const updateContractCondition = ca(UPDATE_CONTRACT_CONDITION);
export const uploadApplicationDocument = ca(UPLOAD_APPLICATION_DOCUMENT);
export const uploadMarketingOffers = ca(UPLOAD_MARKETING_OFFERS);
