import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Input } from 'antd';
import { DADATA_DEBOUNCE } from 'constants/DADATA';
import { useEffectDebounced } from 'hooks/useEffectDebounced';
import { getDadataSuggestions, preventEnter } from './helpers';
import {getLightStyleOr, palette} from "styles/theme";

const { TextArea } = Input;

const InputSuggestionsWrapper = styled.div`
  position: relative;
`;

const SuggestionsList = styled.ul`
  background-color: ${getLightStyleOr(palette.whiteColor, palette.blackColor)};
  border-radius: 8px;
  border: 1px solid ${palette.black30Color};
  max-height: 220px;
  overflow: auto;
  overscroll-behavior: none;
  padding: 8px 0;
  position: absolute;
  top: calc(100% + 2px);
  width: 100%;
  z-index: 20;
`;

const SuggestionItem = styled.li`
  align-items: center;
  color: ${palette.black100Color};
  cursor: pointer;
  display: flex;
  height: auto;
  flex-shrink: 0;
  padding: 8px 16px;
  width: 100%;
  z-index: 21;

  &:hover {
    background-color: ${getLightStyleOr(palette.white75Color, palette.black50Color)};
  }
`;

const StyledTextArea = styled(TextArea)`
  auto-size: minRows 2;
  width: 100%;
`;

const InputSuggestions = ({
                            controlClassName,
                            controlWrapClassName,
                            dadata,
                            focus,
                            name,
                            onChange: _onChange,
                            value: _value,
                          }) => {
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const value = dadata === 'address' && _value?.value
    ? _value?.value
    : _value;

  const loadSuggestions = useEffectDebounced(async (q) => {
    try {
      let query = q || value;

      if (dadata === 'address') {
        query = q || _.get(value, 'value', value);
      }

      const { data, status } = await getDadataSuggestions({ query, type: dadata });
      if (status !== 200) throw new Error();

      setSuggestions(data.suggestions);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }, DADATA_DEBOUNCE);

  const onChange = async (event) => {
    const v = _.isString(event) ? event : event.target.value;

    _onChange(v);
    await loadSuggestions(v);
  };

  const onSelect = (v) => {
    _onChange(v);
    setIsSuggestionsOpen(false);
  };

  const onFocus = async () => {
    setIsSuggestionsOpen(true);
    if (_.isEmpty(suggestions)) await loadSuggestions();
  };

  const onBlur = () => {
    setTimeout(() => setIsSuggestionsOpen(false), 200);
  };

  return (
    <InputSuggestionsWrapper
      className={controlWrapClassName}
      onClick={focus}
    >
      <StyledTextArea
        autoSize={{ minRows: 2 }}
        className={controlClassName}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={preventEnter}
        value={value}
      />
      {isSuggestionsOpen && !_.isEmpty(suggestions) && (
        <SuggestionsList>
          {_.map(suggestions, (option, index) => (
            <SuggestionItem
              key={`${option.value}-${index}`}
              onClick={() => onSelect(dadata === 'address' ? option : option.value)}
            >
              {option.value}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </InputSuggestionsWrapper>
  );
};

export default InputSuggestions;
