import React from 'react';
import _ from 'lodash';
import { routerRedux } from 'dva/router';
import ExcelJS from 'exceljs';
import moment from 'moment';

import { parseResponse } from 'api/helpers';
import { getToken } from 'api/index';
import {
  downloadBlob,
  getFullName,
  showError,
  validateContractNumber,
  validateEmail,
  validatePassportNumber,
  validatePassportSerial,
  validatePhone,
  validatePhoneSearch,
  validateUuid4,
} from 'helper';

import { getShortLink } from 'models/admin/api';
import {
  CLOSE_APPLICATION,
  CLOSE_CLIENT_MARKETING_OFFER,
  CLOSE_LOAN,
  CLOSE_SIGN_DOCUMENT,
  CREATE_CLIENT_ACTIVE_EMPLOYMENT,
  CREATE_CLIENT_DOCUMENT,
  CREATE_CLIENT_PHONE,
  CREATE_MARKETING_OFFER,
  DOWNLOAD_SMS,
  FINALIZE_SIGN_DOCUMENT,
  GENERATE_REPAYMENT_LINK,
  GET_CLIENT,
  GET_CLIENT_APPLICATION_DOCS,
  GET_CLIENT_APPLICATION_OFFERS,
  GET_CLIENT_APPLICATION_OPERATION,
  GET_CLIENT_APPLICATION_VECTOR,
  GET_CLIENT_APPLICATIONS,
  GET_CLIENT_AUTH_CALLS,
  GET_CLIENT_CALIBRI_CHAT_HISTORY,
  GET_CLIENT_CALIBRI_CHATS,
  GET_CLIENT_CREDIT_HISTORY,
  GET_CLIENT_INFO,
  GET_CLIENT_LOAN_AGREEMENT,
  GET_CLIENT_LOAN_DETAILS,
  GET_CLIENT_LOAN_DOCUMENT,
  GET_CLIENT_LOAN_OPERATIONS,
  GET_CLIENT_LOAN_REPAYMENT_LINKS,
  GET_CLIENT_LOAN_REPAYMENTS,
  GET_CLIENT_LOAN_SCHEDULE,
  GET_CLIENT_LOANS,
  GET_CLIENT_MARKETING_OFFERS,
  GET_CLIENT_PHONES,
  GET_CLIENT_SIGN_DOCUMENTS,
  GET_CLIENT_SMS,
  GET_CLIENT_VERIFICATIONS,
  GET_CLIENTS_RECENT,
  model as namespace,
  REOPEN_APPLICATION,
  REPAIR_APPLICATION,
  RESET,
  RESET_SMS,
  SEARCH_CLIENTS,
  SEND_APPLICATION_TO_CALL,
  SEND_CLIENT_MARKETING_OFFER_DECISION,
  SEND_SMS,
  SET,
  SET_CALIBRI_CHAT_HISTORY_LOADING,
  SET_CLIENT_ATTRIBUTE,
  SET_CLIENT_CREDIT_HISTORY,
  SET_CLIENT_LOADED,
  SET_CLIENT_SUBATTRIBUTE,
  SET_LOADING,
  SET_RECENT_LOADING,
  SWITCH_PROFILE,
  UPDATE_CLIENT_ADDRESS,
  UPDATE_CLIENT_COMMUNICATION_TYPE,
  UPDATE_CLIENT_CONDITION,
  UPDATE_CLIENT_PHONE,
  UPDATE_CONTRACT_CONDITION,
  UPLOAD_APPLICATION_DOCUMENT,
  UPLOAD_MARKETING_OFFERS,
} from 'models/clients/actions';
import * as api from 'models/clients/api';
import {
  getClient,
  getClientApplications as getClientApplicationsSelector,
  getClientAuthCalls as getClientAuthCallsSelector,
  getClientCalibriChatHistory as getClientCalibriChatHistorySelector,
  getClientCalibriChats as getClientCalibriChatsSelector,
  getClientLoanByApplicationId as getClientLoanByApplicationIdSelector,
  getClientLoanById as getClientLoanByIdSelector,
  getClientLoanDetails as getClientLoanDetailsSelector,
  getClientLoans as getClientLoansSelector,
  getClientPhone,
  getClients as getClientsSelector,
  getClientSms as getClientSmsSelector,
  getClientSmsByExternalIds,
  getClientSurname as getClientSurnameSelector,
  getSearchString,
} from 'models/clients/selectors';
import { checkDictionary } from 'models/dictionaries/helpers';
import { setModal } from 'models/ui/actions';
import {
  getFullName as getFullNameSelector,
} from 'models/user/selectors';

import {
  Button,
  notification,
  Typography,
} from 'antd';
import { COLUMNS as SMS_COLUMNS } from 'pages/PageClient/Single/Sms/constants';

import {
  getDocDownloadName,
  validateMarketingOffersFile,
} from './helpers';

import {
  LOAN_STATUSES,
  SIGN_DOCUMENT_STATUSES,
} from './constants';
import {intercom} from "@cashdrive/api/socket";

const { Text } = Typography;

const initialState = {
  calibriChatHistoryLoading: [],
  clients                  : {},
  error                    : ``,
  isLoading                : false,
  isLoadingCarInfo         : false,
  isLoadingCarService      : false,
  isLoadingDebts           : false,
  isLoadingInfo            : false,
  isLoadingLoanDetails     : false,
  isLoadingPhones          : false,
  isLoadingSignDocuments   : false,
  isLoadingRepayments      : false,
  isLoadingSwitchProfile   : false,
  isLoadingTasksLastTouch  : false,
  loadedPersonIds          : [],
  recent                   : [],
  searchClientPersonIds    : {},
  searchString             : ``,
  vinEditedCars            : [],
};

const castSearchStringParts = (parts = []) => {
  if (_.isEmpty(parts)) return {};
  return _.reduce(parts, (result, value, index) => {
    let partName = ``;
    if (validateUuid4(value)) {
      partName = `personId`;
    } else if (validateEmail(value)) {
      partName = `email`;
    } else if (validateContractNumber(value)) {
      partName = `contractNumber`;
    } else if (validatePhone(value)) {
      partName = `phone`;
    } else if (validatePassportNumber(value)) {
      partName = `passportNumber`;
    } else if (validatePassportSerial(value)) {
      partName = `passportSerial`;
    } else if (index === 0) {
      partName = `surname`;
    } else if (index === 1) {
      partName = `name`;
    } else if (index === 2) {
      partName = `patronymic`;
    }

    return {
      ...result,
      [partName]: value,
    };
  }, {});
};

let CLIENTS_LOADING = [];

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      intercom.subscribe(`SOCKET_MTT_CALL`, ({ payload: [type, { fullName, personId, phone }] }) => {
        if (type === `mttCall`) {
          const key = `newMttCall-${personId}`;
          const onClose = () => {
            notification.close(key);
          };

          const goToClient = () => {
            onClose();
            dispatch(routerRedux.push(personId ? `/client/person/${personId}/info` : `/client`));
          };

          notification.info({
            btn: (
              <Button onClick={goToClient}>{`Перейти к ${personId ? `клиенту` : `созданию коммуникации`}`}</Button>
            ),
            duration: 0,
            key,
            message : `Входящий звонок в МТТ (${fullName || phone})`,
            onClose,
          });
        }
      });
    },
  },
  effects: {
    *[CLOSE_APPLICATION](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, callback, hideMessage } = action.payload;
      const defaultError = `При закрытии заявки c id '${applicationId}' произошла ошибка`;

      try {
        if (!applicationId) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.closeApplication, applicationId),
        });

        if (_.isFunction(callback)) callback();
        if (!hideMessage) notification.success({ message: `Заявка с id ${applicationId} успешно закрыта` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CLOSE_LOAN](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, factCloseDtm, loanId, personId } = action.payload;
      const defaultError = `При закрытии займа c id '${loanId}' произошла ошибка`;

      try {
        if (!personId || !loanId || !factCloseDtm) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.closeLoan, loanId, { factCloseDtm, loanStatusId: LOAN_STATUSES.CLOSED }),
        });

        if (_.isFunction(callback)) callback();
        yield put({ type: GET_CLIENT_LOANS, payload: { personId } });
        notification.success({ message: `Займ с id ${loanId} успешно закрыт` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CLOSE_SIGN_DOCUMENT](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id:signDocumentId, personId } = action.payload;
      const defaultError = `При закрытии задачи с id ${signDocumentId} произошла ошибка`;

      try {
        if (!signDocumentId) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.closeSignDocument, signDocumentId),
        });

        yield put({ type: GET_CLIENT_SIGN_DOCUMENTS, payload: { personId } });
        notification.success({ message: `Задача с id ${signDocumentId} успешно закрыта` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[FINALIZE_SIGN_DOCUMENT](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { documentId, personId, sms } = action.payload;
      const defaultError = `При одобрении документа с id ${documentId} произошла ошибка`;

      try {
        if (!documentId) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.finalizeSignDocument, { documentId, personId, sms }),
        });

        yield put({ type: GET_CLIENT_SIGN_DOCUMENTS, payload: { personId } });
        notification.success({ message: `Документ успешно одобрен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE_CLIENT_DOCUMENT](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });

      const { callback, data } = action.payload;
      const defaultError = `При создании документа произошла ошибка`;

      try {
        if (_.isEmpty(data)) {
          throw new Error(defaultError);
        }

        parseResponse({
          defaultError,
          errorPath: `data.message`,
          response : yield call(api.createClientDocument, data),
        });

        notification.success({ message: `Документ успешно создан` });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE_CLIENT_PHONE](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingPhones: true } });
      const { data, personId } = action.payload;
      const defaultError = `При создании телефона клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId || _.isEmpty(data)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.createClientPhone, personId, data),
        });

        yield put({ type: GET_CLIENT_PHONES, payload: { personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingPhones: false } });
      }
    },

    *[GENERATE_REPAYMENT_LINK](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        callback,
        loanId,
        paymentGatewayId,
        personId,
        setLink,
        sum,
      } = action.payload;

      const defaultError = `При формировании ссылки на оплату для клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId || !loanId) throw new Error(defaultError);

        const { formUrl } = parseResponse({
          defaultError,
          response: yield call(
            api.generateRepaymentLink,
            loanId,
            {
              paymentGatewayId,
              paymentMethodId: 2,
              personId,
              sum,
            },
          ),
        });

        const shortLink = parseResponse({
          dataPath: `data.url`,
          defaultError,
          response: yield call(getShortLink, formUrl),
        });
        if (_.isFunction(setLink)) setLink(shortLink || formUrl);
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        if (_.isFunction(callback)) callback();
      }
    },

    *[GET_CLIENT](action, { call, put, select, take }) {
      const { callback, personId } = action.payload;
      if (!personId) return;
      yield put({ type: SET_LOADING, payload: true });
      if (_.includes(CLIENTS_LOADING, personId)) {
        if (!_.isFunction(callback)) return;
        yield take(({ type }) => type === `${namespace}/${SET}`);
        callback();
        return;
      }
      CLIENTS_LOADING.push(personId);
      const defaultError = `При загрузке клиента c personId '${personId}' произошла ошибка`;

      try {
        const client = parseResponse({
          defaultError,
          response: yield call(api.getClient, personId),
        });

        if (_.isEmpty(client)) return;

        const currentClients = yield select(getClientsSelector);
        const isClientLoaded = currentClients[personId];
        let clients = { ...currentClients };
        if (isClientLoaded) {
          clients = {
            ...clients,
            [personId]: {
              ...clients[personId],
              ...client,
            },
          };
        } else {
          clients = {
            ...clients,
            [personId]: client,
          };
        }
        yield put({ type: SET, payload: { clients } });
        yield put({ type: SET_CLIENT_LOADED, payload: personId });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        if (_.isFunction(callback)) callback();
        CLIENTS_LOADING = _.without(CLIENTS_LOADING, personId);
      }
    },

    *[GET_CLIENT_APPLICATIONS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      yield put({ type: SET, payload: { isLoadingApplications: true } });
      const { callback, personId } = action.payload;
      const defaultError = `При загрузке заявок клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const applications = parseResponse({
          defaultError,
          response: yield call(api.getClientApplications, personId),
        });

        const verificationResults = parseResponse({
          defaultError,
          response: yield call(api.getClientVerificationResults, personId),
        });

        const applicationsWithResults = _.map(applications, application => ({
          ...application,
          verificationResults: verificationResults[application.id] || [],
        }));

        const currentApplications = yield select(state => getClientApplicationsSelector(state, personId));

        yield put({
          type   : SET_CLIENT_ATTRIBUTE,
          payload: {
            personId,
            applications: _.reduce(applicationsWithResults, (result, application) => {
              const currentApplication = _.find(currentApplications, { id: application.id });
              result.push(currentApplication ? { ...currentApplication, ...application } : application);
              return result;
            }, []),
          },
        });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        yield put({ type: SET, payload: { isLoadingApplications: false } });
      }
    },

    *[GET_CLIENT_APPLICATION_DOCS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, personId } = action.payload;
      // eslint-disable-next-line max-len
      const defaultError = `При загрузке документов по заявке '${applicationId}' клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!applicationId || !personId) throw new Error(defaultError);

        const docsRaw = parseResponse({
          defaultError,
          response: yield call(api.getDocsByApplication, personId, applicationId),
        });

        const { clientSurname, loanByApplication } = yield select(state => ({
          clientSurname    : getClientSurnameSelector(state, personId),
          loanByApplication: getClientLoanByApplicationIdSelector(state, personId, applicationId),
        }));
        const baseUrl = import.meta.env.VITE_REACT_APP_API_URL || `http://localhost:3001`;
        const token = getToken();
        const docs = _.map(docsRaw, doc => {
          const fileBaseUrl = `${baseUrl}/document/person/${personId}/application/${applicationId}/file/${doc.id}`;
          return {
            ...doc,
            downloadName: getDocDownloadName({ doc, loan: loanByApplication, surname: clientSurname }),
            url         : `${fileBaseUrl}?token=${token}`,
            urlOriginal : doc.url,
            urlPdf      : `${fileBaseUrl}/pdf?token=${token}`,
          };
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            docs,
            entity  : `applications`,
            entityId: applicationId,
            personId,
          },
        });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_APPLICATION_OFFERS](action, { call, put, select, take }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        applicationId,
        hideMessage,
        limit,
        offset,
        personId,
      } = action.payload;
      // eslint-disable-next-line max-len
      const defaultError = `При загрузке офферов по заявке '${applicationId}' клиента c personId '${personId}' произошла ошибка`;
      const offerStatuses = yield checkDictionary(`offerStatus`, { put, select, take });

      try {
        if (!applicationId || !personId) throw new Error(defaultError);
        const offers = parseResponse({
          defaultError,
          response : yield call(api.getOffersByApplication, applicationId, limit, offset),
          normalize: items => _.map(items, offer => ({
            ...offer,
            applicationId,
            statusName : _.find(offerStatuses, { id: offer.status })?.name,
          })),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            offers,
            entity  : `applications`,
            entityId: applicationId,
            personId,
          },
        });

        if (!hideMessage) {
          notification.success({ message: `Офферы по заявке ${applicationId} загружены (${_.size(offers)} шт)` });
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_APPLICATION_OPERATION](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, callback, personId } = action.payload;
      const defaultError = `При загрузке операции клиента '${personId}' по заявке '${applicationId}' произошла ошибка`;

      try {
        if (!personId || !applicationId) throw new Error(defaultError);

        const operation = parseResponse({
          defaultError,
          normalize: o => (_.isEmpty(o) ? o : {
            ...o,
          }),
          response: yield call(api.getApplicationOperation, applicationId),
        });

        if (_.isEmpty(operation)) {
          notification.info({ message: `По этой заявке не найдено операции выплаты` });
        } else {
          notification.success({ message: `Операция загружена` });
          yield put({
            type   : SET_CLIENT_SUBATTRIBUTE,
            payload: {
              operation,
              entity  : `applications`,
              entityId: applicationId,
              personId,
            },
          });
        }

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_APPLICATION_VECTOR](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, callback, personId } = action.payload;
      // eslint-disable-next-line max-len
      const defaultError = `При загрузке вектора данных клиента '${personId}' по заявке '${applicationId}' произошла ошибка`;

      try {
        if (!personId || !applicationId) throw new Error(defaultError);

        const vector = parseResponse({
          defaultError,
          response: yield call(api.getApplicationVector, applicationId),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            vector,
            entity  : `applications`,
            entityId: applicationId,
            personId,
          },
        });

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_AUTH_CALLS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { force = false, personId } = action.payload;
      const defaultError = `При загрузке звонков клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const currentAuthCalls = yield select(state => getClientAuthCallsSelector(state, personId));
        if (!force && !_.isEmpty(currentAuthCalls)) return;

        const authCalls = parseResponse({
          defaultError,
          response: yield call(api.getClientAuthCalls, personId),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { authCalls, personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_CALIBRI_CHAT_HISTORY](action, { call, put, select }) {
      const { chatId, communicationId, force = false, personId } = action.payload;
      yield put({ type: SET_CALIBRI_CHAT_HISTORY_LOADING, payload: { chatId, isLoading: true } });
      const defaultError = `При загрузке истории чата c id '${chatId}' произошла ошибка`;
      try {
        if (!chatId || !personId || !communicationId) throw new Error(defaultError);
        const currentHistory = yield select(state => getClientCalibriChatHistorySelector(state, personId, chatId));
        if (!force && !_.isEmpty(currentHistory)) return;

        const history = parseResponse({
          defaultError,
          response: yield call(api.getClientCalibriChatHistory, chatId),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            entity     : `calibriChats`,
            entityId   : chatId,
            history,
            personId,
            idFieldName: `chatId`,
          },
        });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_CALIBRI_CHAT_HISTORY_LOADING, payload: { chatId, isLoading: false } });
      }
    },

    *[GET_CLIENT_CALIBRI_CHATS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { force = false, personId } = action.payload;
      const defaultError = `При загрузке чатов клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const currentCalibriChats = yield select(state => getClientCalibriChatsSelector(state, personId));
        if (!force && !_.isEmpty(currentCalibriChats)) return;

        const phone = yield select(state => getClientPhone(state, personId));
        const calibriChats = parseResponse({
          defaultError,
          response: yield call(api.getClientCalibriChats, { comment: personId, phone }),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { calibriChats, personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_CREDIT_HISTORY](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, personId } = action.payload;
      const defaultError = `При загрузке кредитной истории клиента произошла ошибка`;

      try {
        if (!personId || !applicationId) throw new Error(defaultError);
        const creditHistory = parseResponse({
          defaultError,
          response: yield call(api.getClientCreditHistory, applicationId),
        });

        yield put({
          type   : SET_CLIENT_CREDIT_HISTORY,
          payload: { applicationId, creditHistory, personId },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_INFO](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingInfo: true } });
      const { personId } = action.payload;
      const defaultError = `При загрузке информации о клиенте c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        const info = parseResponse({
          defaultError,
          response: yield call(api.getClientInfo, personId),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, ...info } });

        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingInfo: false } });
      }
    },

    *[GET_CLIENT_LOAN_DETAILS](action, { call, put, select }) {
      yield put({ type: SET, payload: { isLoadingLoanDetails: true } });
      const { loanId, pdp = false, personId, requestDtm = new Date() } = action.payload;
      const defaultError = `При загрузке подробной информации по займу клиента c loanId '${loanId}' произошла ошибка`;

      try {
        if (!personId || !loanId || (pdp && !requestDtm)) throw new Error(defaultError);
        const loanDetails = parseResponse({
          defaultError,
          errorPath: `data.message`,
          response : yield call(
            api.getClientLoanDetails,
            loanId,
            pdp,
            moment.utc(requestDtm).format(`YYYY-MM-DDTHH:mm:ss`),
          ),
        });

        const details = yield select(state => getClientLoanDetailsSelector(state, personId, loanId));

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            entity  : `loans`,
            entityId: loanId,
            personId,
            details : pdp
              ? {
                ...details,
                pdpDate: requestDtm,
                pdpSum : loanDetails.loanClosingAmount,
              }
              : {
                ...details,
                ...loanDetails,
              },
          },
        });

        yield put({ type: GET_CLIENT_LOAN_OPERATIONS, payload: { loanId, personId } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingLoanDetails: false } });
      }
    },

    *[GET_CLIENT_LOANS](action, { all, call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });

      const { callback, limit, offset, personId } = action.payload;
      const defaultError = `При загрузке займов клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        const loans = parseResponse({
          defaultError,
          response: yield call(api.getClientLoans, personId, limit, offset),
        });

        const currentLoans = yield select(state => getClientLoansSelector(state, personId));

        yield put({
          type   : SET_CLIENT_ATTRIBUTE,
          payload: {
            personId,
            loans: _.map(loans, loan => ({
              ...(_.find(currentLoans, { id: loan.id }) || {}),
              ...loan,
            })),
          },
        });

        const activeLoanIds = _.map(_.filter(loans, { loanStatusId: LOAN_STATUSES.ACTIVE }), `id`);
        // https://ccredit.atlassian.net/browse/CRM-1499
        yield all(_.map(activeLoanIds, loanId => put({
          type   : GET_CLIENT_LOAN_DETAILS,
          payload: { loanId, personId },
        })));
        //
        if (_.isFunction(callback)) {
          callback(activeLoanIds);
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_LOAN_AGREEMENT](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { loanId, personId } = action.payload;
      const defaultError = `Согласие на реализацию по займу c loanId '${loanId}' не удалось сформировать`;

      try {
        if (!loanId) throw new Error(defaultError);

        const response = yield call(api.getClientLoanAgreement, action.payload);
        const data = parseResponse({
          defaultError,
          response,
        });

        const loan = yield select(getClientLoanByIdSelector, personId, loanId);
        const contractNumber = _.replace(_.get(loan, `contractNumber`, loanId), /\//g, `_`);

        downloadBlob(data, `Согласие_на_реализацию_${contractNumber}.pdf`);
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_LOAN_DOCUMENT](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { loanId, personId, type } = action.payload;
      const defaultError = `Документ по займу клиента c loanId '${loanId}' не удалось сформировать`;

      try {
        if (!loanId) throw new Error(defaultError);

        const loan = yield select(getClientLoanByIdSelector, personId, loanId);
        const contractNumber = _.replace(_.get(loan, `contractNumber`, loanId), /\//g, `_`);
        const fileName = `Справка_${contractNumber}.pdf`;
        const blob = parseResponse({
          defaultError,
          response: yield call(api.getClientLoanDocument, type, loanId, fileName),
        });
        downloadBlob(blob, fileName);
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_LOAN_OPERATIONS](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingRepayments: true } });
      const { loanId, personId } = action.payload;
      const defaultError = `При загрузке попыток оплат по займу клиента c loanId '${loanId}' произошла ошибка`;

      try {
        if (!personId || !loanId) throw new Error(defaultError);
        const operations = parseResponse({
          dataPath: `data.data`,
          defaultError,
          response: yield call(api.getClientLoanOperations, loanId),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            personId,
            entity  : `loans`,
            entityId: loanId,
            operations,
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingRepayments: false } });
      }
    },

    *[GET_CLIENT_LOAN_REPAYMENT_LINKS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { loanId, personId } = action.payload;
      const defaultError = `При загрузке ссылок на оплату по займу клиента c loanId '${loanId}' произошла ошибка`;

      try {
        if (!personId || !loanId) throw new Error(defaultError);
        const repaymentLinks = parseResponse({
          defaultError,
          response: yield call(api.getClientLoanRepaymentLinks, loanId),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            personId,
            entity  : `loans`,
            entityId: loanId,
            repaymentLinks,
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_LOAN_REPAYMENTS](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingRepayments: true } });
      const { loanId, personId } = action.payload;
      const defaultError = `При загрузке погашений по займу клиента c loanId '${loanId}' произошла ошибка`;

      try {
        if (!personId || !loanId) throw new Error(defaultError);
        const repayments = parseResponse({
          defaultError,
          response: yield call(api.getClientLoanRepayments, loanId),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            personId,
            entity  : `loans`,
            entityId: loanId,
            repayments,
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingRepayments: false } });
      }
    },

    *[GET_CLIENT_LOAN_SCHEDULE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { loanId, personId } = action.payload;
      const defaultError = `При загрузке графика платежей по займу клиента c loanId '${loanId}' произошла ошибка`;

      try {
        if (!personId || !loanId) throw new Error(defaultError);
        const schedule = parseResponse({
          defaultError,
          response: yield call(api.getClientLoanSchedule, loanId),
        });

        yield put({
          type   : SET_CLIENT_SUBATTRIBUTE,
          payload: {
            personId,
            entity  : `loans`,
            entityId: loanId,
            schedule,
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_MARKETING_OFFERS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { personId } = action.payload;
      const defaultError = `При загрузке маркетинговых предложений клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const marketingOffers = parseResponse({
          defaultError,
          response: yield call(api.getClientMarketingOffers, { personIds: [personId] }),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, marketingOffers } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENTS_RECENT](action, { call, put }) {
      const defaultError = `При загрузке клиентов произошла ошибка`;
      yield put({ type: SET_RECENT_LOADING, payload: true });
      try {
        const recent = parseResponse({
          defaultError,
          response: yield call(api.getRecent),
        });

        yield put({ type: SET, payload: { recent } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_RECENT_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_PHONES](action, { call, put }) {
      const defaultError = `При загрузке дополнительных телефонов произошла ошибка`;
      yield put({ type: SET, payload: { isLoadingPhones: true } });
      try {
        const { personId } = action.payload;

        if (!personId) throw new Error(`Не указан personId клиента`);
        const phones = parseResponse({
          defaultError,
          response: yield call(api.getClientPhones, personId),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, phones } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingPhones: false } });
      }
    },

    *[GET_CLIENT_SIGN_DOCUMENTS](action, { call, put }) {
      const defaultError = `При загрузке документов на подпись произошла ошибка`;
      yield put({ type: SET, payload: { isLoadingSignDocuments: true } });
      try {
        const { personId } = action.payload;

        if (!personId) throw new Error(defaultError);

        const data = parseResponse({
          defaultError,
          response: yield call(api.getClientSignDocuments, personId),
        });

        const signDocuments = _.reduce(SIGN_DOCUMENT_STATUSES, (res, item, key) => ({
          ...res,
          [_.toLower(key)]: _.filter(data, ({ fileStatusId }) => _.includes(item, fileStatusId)),
        }), {});

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, signDocuments } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingSignDocuments: false } });
      }
    },

    *[GET_CLIENT_SMS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { force = false, limit, offset, personId } = action.payload;
      const defaultError = `При загрузке SMS клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const currentSms = yield select(state => getClientSmsSelector(state, personId));
        if (!force && !_.isEmpty(currentSms)) return;
        const sms = parseResponse({
          defaultError,
          response: yield call(api.getClientSms, personId, limit, offset),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, sms } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[DOWNLOAD_SMS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При экспорте SMS в Excel произошла ошибка`;
      try {
        const { callback, personId, smsExternalIds } = action.payload || {};

        // Получаем элементы из состояния
        const items = yield select(state => getClientSmsByExternalIds(state, personId, smsExternalIds));
        const client = yield select(state => getClient(state, personId));
        const fullName = getFullName(client);
        // Создаем новую книгу Excel
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`SMS`);

        const columns = SMS_COLUMNS({});

        // Устанавливаем столбцы
        worksheet.columns = _.map(columns, ({ key, title, width }) => ({
          header: title,
          key,
          width : width / 6,
        }));

        // Заполняем книгу данными
        _.each(items, item => {
          worksheet.addRow(_.reduce(item, (result, value, key) => {
            const render = _.find(columns, { key })?.renderExcel || _.identity;
            return {
              ...result,
              [key]: render(value),
            };
          }, {}));
        });

        // Преобразуем книгу в blob
        const blob = yield call([workbook.xlsx, `writeBuffer`]);
        const blobObject = new Blob(
          [blob],
          { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` },
        );

        // Скачиваем файл
        downloadBlob(blobObject, `SMS ${fullName}.xlsx`);

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CLIENT_VERIFICATIONS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      yield put({ type: SET, payload: { isLoadingVerifications: true } });
      const { callback, personId } = action.payload;
      const defaultError = `При загрузке задач на верификацию клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const verifications = parseResponse({
          defaultError,
          response: yield call(api.getClientVerifications, personId),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, verifications } });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        yield put({ type: SET, payload: { isLoadingApplications: false } });
      }
    },

    *[SEARCH_CLIENTS](action, { call, put, select }) {
      const defaultError = `При поиске клиентов произошла ошибка`;
      yield put({ type: SET_LOADING, payload: true });

      try {
        //   birthDate,
        //   limit,
        //   name,
        //   offset,
        //   passportNumber,
        //   passportSerial,
        //   patronymic,
        //   personId,
        //   phone,
        //   searchString,
        //   surname,

        const { mode } = action.payload;

        const searchString = _.toLower(_.trim(yield select(getSearchString)));
        if (!searchString) return;
        let searchObj;

        const searchStringNumbers = _.replace(searchString, /\D/gi, ``);
        if (mode) {
          if (mode === `passport`) {
            searchObj = {
              passportNumber: searchStringNumbers.slice(4, 10),
              passportSerial: searchStringNumbers.slice(0, 4),
            };
          } else { // mode === `phone`
            searchObj = {
              phone: `7${searchStringNumbers}`,
            };
          }
        } else if (validatePhoneSearch(searchString)
          && !validateEmail(searchString)
          && !validateUuid4(searchString)
          && !validateContractNumber(searchString)) {
          searchObj = {
            phone: _.head(searchStringNumbers) === `8`
              ? _.replace(searchStringNumbers, /8/, `7`)
              : searchStringNumbers,
          };
        } else {
          searchObj = castSearchStringParts(_.split(searchString, ` `));
        }

        if (_.has(searchObj, `surname`) && !_.has(searchObj, `name`)) {
          notification.warn({
            message  : `Укажите имя, поиск только по фамилии сильно нагружает сервисы`,
            placement: `bottomRight`,
          });
          return;
        }

        const validateName = _.filter(
          [
            { key: `name`, label: `имени` },
            { key: `surname`, label: `фамилии` },
          ], ({ key, label }) => {
            if (_.has(searchObj, key) && _.size(searchObj[key]) < 2) {
              notification.warn({
                message  : `В ${label} менее двух символов, добавьте, чтобы выполнить поиск`,
                placement: `bottomRight`,
              });
              return true;
            }
          },
        );
        if (!_.isEmpty(validateName)) return;

        const searchClients = parseResponse({
          defaultError,
          response: yield call(api.searchClients, _.omitBy(searchObj, _.isNil)),
        });

        const currentClients = yield select(getClientsSelector);
        let clients = { ...currentClients };
        _.forEach(searchClients, searchClient => {
          const isClientLoaded = currentClients[searchClient.personId];
          if (isClientLoaded) {
            clients = {
              ...clients,
              [searchClient.personId]: {
                ...clients[searchClient.personId],
                ...searchClient,
              },
            };
          } else {
            clients = {
              ...clients,
              [searchClient.personId]: searchClient,
            };
          }
        });

        yield put({ type: SET, payload: { clients, searchClientPersonIds: _.map(searchClients, `personId`) } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CLOSE_CLIENT_MARKETING_OFFER](action, { call, put }) { // FIXME move to separate model
      yield put({ type: SET_LOADING, payload: true });
      const { offerId, personId } = action.payload;
      const defaultError = `Произошла ошибка. Оффер не закрыт`;

      try {
        if (!offerId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.closeClientMarketingOffer, { offerId }),
        });
        yield put({ type: GET_CLIENT_MARKETING_OFFERS, payload: { personId } });
        notification.success({ message: `Оффер закрыт` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEND_CLIENT_MARKETING_OFFER_DECISION](action, { call, put }) { // FIXME move to separate model
      yield put({ type: SET_LOADING, payload: true });
      const { chosenFlg, offerId } = action.payload;
      const defaultError = chosenFlg
        ? `При принятии маркетингового предложения id '${offerId}' произошла ошибка`
        : `При отказе от маркетингового предложения id '${offerId}' произошла ошибка`;

      try {
        if (!offerId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.sendClientMarketingOfferDecision, { chosenFlg, offerId }),
        });

        notification.success({
          message: chosenFlg
            ? `Согласие на маркетинговое предложение успешно отправлено`
            : `Отказ от маркетингового предложения успешно отправлен`,
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEND_SMS](action, { call, put }) {
      yield put(setModal({ modalName: `sendSms`, isLoading: true }));
      const { personId, text } = action.payload;
      const defaultError = `При отправке SMS клиенту с personId ${personId} произошла ошибка`;

      try {
        if (!personId || !text) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.sendSms, { personId, text }),
        });

        notification.success({ message: `SMS успешно отправлено!` });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put(setModal({ modalName: `sendSms`, isLoading: false }));
      }
    },

    *[SWITCH_PROFILE](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingSwitchProfile: true } });
      const { accessFlg, personId } = action.payload;
      // eslint-disable-next-line max-len
      const defaultError = `При ${accessFlg ? `открытии` : `закрытии`} ЛК клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.switchProfile, personId, accessFlg),
        });

        yield put({ type: GET_CLIENT, payload: { personId } });
        notification.success({ message: `ЛК клиента с id ${personId} успешно ${accessFlg ? `открыт` : `закрыт`}` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingSwitchProfile: false } });
      }
    },

    *[REOPEN_APPLICATION](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, callback, hideMessage } = action.payload;
      const defaultError = `При переоткрытии заявки c id '${applicationId}' произошла ошибка`;

      try {
        if (!applicationId) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.reopenApplication, applicationId),
        });

        if (_.isFunction(callback)) callback();
        if (!hideMessage) notification.success({ message: `Заявка с id ${applicationId} успешно переоткрыта` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REPAIR_APPLICATION](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { application: { id: applicationId, personId }, callback } = action.payload;
      const defaultError = `При проталкивании заявки произошла ошибка`;

      try {
        if (!applicationId) throw new Error(defaultError);
        const response = yield call(api.repairApplication, applicationId);

        if (response?.status === 400) {
          const client = yield select(state => getClient(state, personId));
          const { error = ``, message = `` } = response?.data || {};
          // eslint-disable-next-line max-len
          const errorText = `${message}\n${getFullName(client)}\npersonId: ${personId}\napplicationId: ${applicationId}\nerror:${error}`;
          showError({
            duration   : 0,
            error      : `Пишите в чат поддержки`,
            description: (
              <Text copyable={{ text: errorText }}>
                {message}
                <br />
                {getFullName(client)}
                <br />
                {`personId: ${personId}`}
                <br />
                {`applicationId: ${applicationId}`}
                <br />
                {`error: ${error}`}
              </Text>
            ) });
        } else {
          parseResponse({ defaultError, response });
          if (_.isFunction(callback)) callback();
          notification.success({ message: `Заявка с applicationId ${applicationId} отправлена на верификацию` });
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[RESET_SMS](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingResetSms: true } });
      const { personId } = action.payload;
      const defaultError = `При сбросе счётчика СМС клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.resetSms, personId),
        });

        notification.success({ message: `Счётчик СМС клиента с personId ${personId} успешно сброшен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingResetSms: false } });
      }
    },

    *[SEND_APPLICATION_TO_CALL](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, call: _call, callback, hideMessage } = action.payload;
      let callTitle = `call${_call}`;
      if (_call === 2) callTitle = `все проверки`;
      if (_call === 4) callTitle = `перезапрос КИ и Вектора`;
      if (_call === 5) callTitle = `верификацию`;
      const defaultError = `При отправке заявки c id '${applicationId}' на ${callTitle} произошла ошибка`;

      try {
        if (!applicationId) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.sendApplicationToCall, applicationId, _call),
        });

        if (_.isFunction(callback)) callback();
        if (!hideMessage) {
          notification.success({ message: `Заявка с id ${applicationId} успешно отправлена на ${callTitle}` });
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_CLIENT_ADDRESS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data, personId } = action.payload;
      const defaultError = `При обновлении адреса клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!personId || _.isEmpty(data)) throw new Error(defaultError);

        const { address, addressId } = data;

        parseResponse({
          defaultError,
          response: yield call(api.updateClientAddress, { addressFull: address, addressId }),
        });

        yield put({
          type   : GET_CLIENT,
          payload: {
            callback: () => notification.success({ message: `Адрес успешно изменён` }),
            personId,
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_CLIENT_COMMUNICATION_TYPE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { communicationTypeId, personId } = action.payload;
      // eslint-disable-next-line max-len
      const defaultError = `При обновлении предпочтительного канала связи для клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!communicationTypeId || !personId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.updateClientCommunicationType, personId, communicationTypeId),
        });

        yield put({ type: SET_CLIENT_ATTRIBUTE, payload: { personId, communicationTypeId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE_CLIENT_ACTIVE_EMPLOYMENT](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data, personId } = action.payload;
      const defaultError = `При обновлении активной работы клиента с personId '${personId}' произошла ошибка`;

      try {
        if (!personId || _.isEmpty(data)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.createClientActiveEmployment, personId, data),
        });

        yield put({ type: GET_CLIENT, payload: { personId } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingInfo: false } });
      }
    },

    *[CREATE_MARKETING_OFFER](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, values } = action.payload;
      const defaultError = `Оффер не загружен`;

      try {
        parseResponse({
          defaultError,
          response: yield call(api.createMarketingOffer, values),
        });
        if (_.isFunction(callback)) callback();
        notification.success({ message: `Оффер успешно загружен ` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_CLIENT_CONDITION](action, { call, put, select }) {
      yield put({ type: SET, payload: { isLoadingInfo: true } });
      const { clientConditionId, personId } = action.payload;
      const defaultError = `При обновлении состояния клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!clientConditionId || !personId) throw new Error(defaultError);

        const operatorName = yield select(state => _.get(state, `user.current.fullName`));

        parseResponse({
          defaultError,
          response: yield call(
            api.updateClientCondition,
            {
              personId,
              clientConditionId,
              changeDtm: new Date().toISOString(),
              operatorName,
            },
          ),
        });

        yield put({ type: GET_CLIENT, payload: { personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingInfo: false } });
      }
    },

    *[UPDATE_CONTRACT_CONDITION](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { contractConditionId, loanId, personId } = action.payload;
      const defaultError = `При обновлении состояния договора c id '${loanId}' произошла ошибка`;

      try {
        if (!contractConditionId || !loanId || !personId) throw new Error(defaultError);

        const operatorName = yield select(getFullNameSelector);

        parseResponse({
          defaultError,
          response: yield call(
            api.updateContractCondition,
            {
              loanId,
              personId,
              contractConditionId,
              changeDtm: new Date().toISOString(),
              operatorName,
            },
          ),
        });

        yield put({ type: GET_CLIENT_LOANS, payload: { personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_CLIENT_PHONE](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingPhones: true } });
      const { personId, phone, phoneId } = action.payload;
      const defaultError = `При обновлении телефона клиента c personId '${personId}' произошла ошибка`;

      try {
        if (!phoneId || !phone || !personId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.updateClientPhone, phoneId, phone),
        });

        yield put({ type: GET_CLIENT_PHONES, payload: { personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingPhones: false } });
      }
    },

    *[UPLOAD_APPLICATION_DOCUMENT](action, { call, put }) {
      yield put({ type: SET, payload: { isLoadingInfo: true } });
      const {
        addToCurrent,
        applicationId,
        documentType,
        file,
        personId,
      } = action.payload;
      const defaultError = `При загрузке документа произошла ошибка`;

      try {
        if (!applicationId || !documentType || !personId || _.isEmpty(file)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(
            api.uploadApplicationDocument,
            personId,
            applicationId,
            documentType,
            file,
            !addToCurrent,
          ),
        });

        yield put({ type: GET_CLIENT_APPLICATION_DOCS, payload: { applicationId, personId } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
        notification.success({ message: `Документ успешно загружен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isLoadingInfo: false } });
      }
    },

    *[UPLOAD_MARKETING_OFFERS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, file } = action.payload;
      const defaultError = `При загрузке офферов произошла ошибка`;
      try {
        yield call(validateMarketingOffersFile, file);

        parseResponse({
          defaultError,
          response: yield call(api.uploadMarketingOffers, file),
        });

        if (_.isFunction(callback)) callback();
        notification.success({ message: `Офферы загружены` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_CALIBRI_CHAT_HISTORY_LOADING](state, { payload: { chatId, isLoading } }) {
      return {
        ...state,
        calibriChatHistoryLoading: isLoading
          ? [...(state.calibriChatHistoryLoading || []), chatId]
          : _.without(state.calibriChatHistoryLoading, chatId),
      };
    },

    [SET_CLIENT_ATTRIBUTE](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      const { personId, ...attribute } = payload;

      return {
        ...state,
        clients: {
          ...state.clients,
          [personId]: {
            ...state.clients[personId],
            ...attribute,
          },
        },
      };
    },

    [SET_CLIENT_CREDIT_HISTORY](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      const { applicationId, creditHistory, personId } = payload;

      return {
        ...state,
        clients: {
          ...state.clients,
          [personId]: {
            ...(state.clients[personId] || {}),
            creditHistory: {
              ...state.clients[personId]?.creditHistory,
              [applicationId]: creditHistory,
            },
          },
        },
      };
    },

    [SET_CLIENT_LOADED](state, { payload: personId }) {
      if (!personId) return state;
      return {
        ...state,
        loadedPersonIds: _.uniq([...state.loadedPersonIds, personId]),
      };
    },

    [SET_CLIENT_SUBATTRIBUTE](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      const {
        entity,
        entityId,
        personId,
        idFieldName = `id`,
        ...attribute
      } = payload;

      return {
        ...state,
        clients: {
          ...state.clients,
          [personId]: {
            ...(state.clients[personId] || {}),
            [entity]: _.map(state.clients?.[personId]?.[entity] || [], e => (
              e[idFieldName] === entityId
                ? { ...e, ...attribute }
                : e
            )),
          },
        },
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    [SET_RECENT_LOADING](state, { payload = false }) {
      return {
        ...state,
        isRecentLoading: payload,
      };
    },
  },
};
