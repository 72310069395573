import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { Redirect } from 'dva/router';
import qs from 'query-string';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';
import {
  Language,
  messages,
  validatePassword,
} from 'validators/password';

import { validateEmail } from 'helper';

import { isLoading as isLoadingSelector } from 'models/ui/selectors';
import {
  login as loginAction,
  recover as recoverAction,
  requestRecover as requestRecoverAction,
} from 'models/user/actions';
import { isAuth } from 'models/user/helpers';

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
} from 'antd';
import LinearProgress from 'components/LinearProgress';

import {
  MODE_AUTH,
  MODE_FORGOT,
  MODE_RECOVER,
} from './constants';

// @ts-ignore
import logoDark from 'assets/img/logo-dark.svg';
// @ts-ignore
import logoLight from 'assets/img/logo-light.svg';

const initialValues = {
  email   : ``,
  password: ``,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100vh;

  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
`;

const LogoWrapper = styled.aside`
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  * {
    user-select: none;
  }

  @media(min-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 0;
  }
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 12px;
  max-width: 150px;
  width: 150px;

  @media(min-width: ${breakpoints.md}) {
    max-width: 200px;
    width: 200px;
    height: 150px;
    margin-right: 0;
  }
`;

const LogoTitle = styled.h1`
  text-align: center;
  color: ${palette.darkBlueColor};
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;

  @media(min-width: ${breakpoints.md}) {
    margin-bottom: 70px;
  }
`;

const FormWrapper = styled.main`
  height: 100%;
  min-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media(min-width: ${breakpoints.md}) {
    min-width: 400px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  border-left: none;
  border-top: 2px solid ${palette.primary500Color};

  @media(min-width: ${breakpoints.md}) {
    border-left: 3px solid ${palette.primary500Color};
    border-top: none;
  }

  .ant-form-item-explain {
    max-width: 300px;
    margin: 8px 0;
    white-space: pre-wrap;
  }
`;

const PageAuth = () => {
  const [form] = Form.useForm();
  const themeName = localStorage.getItem(`themeName`) || `light`;

  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [isEmailValid, setEmailValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [passwordMessage, setPasswordMessage] = useState<string>(messages[Language.RU].requirements);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [mode, setMode] = useState(MODE_AUTH);

  const login = ({ email, password }: { email: string, password: string }) => dispatch(loginAction({ email, password }));
  const recover = ({ id, password, recoverToken }: { id: string, password: string, recoverToken: string }, cb: ()=>void) => dispatch(recoverAction({
    cb,
    id,
    password,
    recoverToken,
  }));
  const requestRecover = ({ email }: { email: string }) => dispatch(requestRecoverAction({ email }));

  const validate = ({ email, password }: { email: string, password: string }) => {
    const emailValid = mode === MODE_RECOVER || (mode !== MODE_RECOVER && validateEmail(email));
    setEmailValid(emailValid);
    const passwordValidationResult = validatePassword(password);

    let passwordValid = mode === MODE_FORGOT;
    if (mode !== MODE_FORGOT) {
      if (mode === MODE_RECOVER) {
        passwordValid = passwordValidationResult.isValid;
        setPasswordMessage(passwordValidationResult.message);
      } else {
        passwordValid = password?.length > 5;
      }
    }
    setPasswordValid(passwordValid);

    setSubmitDisabled(!emailValid || !passwordValid);
  };

  const onValuesChange = (changedValues: any, allValues: { email: any; password: any; }) => {
    const { email, password } = allValues;

    validate({ email, password });
  };

  const handleSubmit = ({ email, password }: { email: string, password: string }) => {
    setSubmitDisabled(true);

    if (mode === MODE_FORGOT) {
      requestRecover({ email });
    } else if (mode === MODE_RECOVER) {
      const query = qs.parse(window.location.search) || {};

      // @ts-ignore
      recover({
        // @ts-ignore
        id          : query.id,
        password,
        // @ts-ignore
        recoverToken: query.recoverToken,
      }, () => setMode(MODE_AUTH));
    } else {
      login({ email, password });
    }
    setSubmitDisabled(false);
  };

  let submitButton;

  if (mode === MODE_RECOVER) {
    submitButton = `Сбросить пароль`;
  } else {
    submitButton = mode === MODE_FORGOT ? `Отправить` : `Войти`;
  }

  useEffect(() => {
    const query = qs.parse(window.location.search) || {};

    // new password
    if (query.recoverToken && query.id) {
      setMode(MODE_RECOVER);
    }
  }, []);

  return isAuth() ? <Redirect to='/client' /> : (
    <Wrapper>
      {/* @ts-ignore */}
      {isLoading && <StyledLinearProgress />}

      <LogoWrapper>
        <Logo alt='ЛОГО' src={themeName === `light` ? logoDark : logoLight} />
        <LogoTitle>FlexiRent CRM</LogoTitle>
      </LogoWrapper>

      <FormWrapper>
        {/*@ts-ignore*/}
        <StyledForm
          form={form}
          initialValues={initialValues}
          name='PageAuth'
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          wrapperCol={{ span: 24 }}
        >
          {mode === MODE_RECOVER ? (
            <Form.Item
              help={passwordMessage}
              name='password'
              validateStatus={isPasswordValid ? `success` : `error`}
            >
              <Input.Password
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                placeholder='Новый пароль'
                prefix={<LockOutlined />}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item name='email' validateStatus={isEmailValid ? `success` : `error`}>
                <Input
                  allowClear
                  placeholder='Email'
                  prefix={<UserOutlined />}
                  type='email'
                />
              </Form.Item>
              {mode !== MODE_FORGOT && (
                <>
                  <Form.Item name='password' validateStatus={isPasswordValid ? `success` : `error`}>
                    <Input.Password
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      placeholder='Пароль'
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={isLoading}
                      htmlType='button'
                      onClick={() => {
                        form.validateFields().then(values => validate(values));
                        setMode(MODE_FORGOT);
                      }}
                      type='link'
                    >
                      Я забыл пароль
                    </Button>
                  </Form.Item>
                </>
              )}
            </>
          )}
          <Form.Item wrapperCol={{ span: 24 }}>
            {mode === MODE_FORGOT && (
              <Button
                disabled={isLoading}
                htmlType='button'
                onClick={() => setMode(MODE_AUTH)}
                style={{ marginRight: 8 }}
                type='default'
              >
                Назад
              </Button>
            )}
            <Button
              disabled={isSubmitDisabled || isLoading}
              htmlType='submit'
              type='primary'
            >
              {submitButton}
            </Button>
          </Form.Item>
        </StyledForm>
      </FormWrapper>
    </Wrapper>
  );
};

export default PageAuth;
