import * as React from 'react';
import styled from 'styled-components';
import {
  palette,
  shadows,
} from 'styles/theme';

import { IDoc } from 'models/clients/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  Empty,
  Spin,
} from 'antd';
import { CarouselDesktop } from 'pages/PageClient/Single/Docs/DocsCarousel/CarouselDesktop';
import { CarouselMobile } from 'pages/PageClient/Single/Docs/DocsCarousel/CarouselMobile';

import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';
import '@egjs/flicking-plugins/dist/pagination.css';

const Wrapper = styled.div`
  background: ${palette.black5Color};
  border: 1px solid ${palette.black10Color};
  box-shadow: ${shadows.image};
  border-radius: 16px;
  height: 100%;
  overflow: hidden;
  max-height: 576px;
  min-height: 200px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

interface IComponentProps {
  isLoading: boolean;
  items: IDoc[];
}

export const DocsCarousel: React.FC<IComponentProps> = ({ isLoading, items }) => {
  const { mobile } = useMobileContext();
  const hasImages = items.length > 0;

  return (
    <Wrapper key='docs-images'>
      {hasImages ? (
        mobile
          ? <CarouselMobile items={items} />
          : <CarouselDesktop items={items} />
      ) : (
        <EmptyWrapper>
          {isLoading ? <Spin size='large' />
            : (
              <Empty
                description='Нет данных'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};
