import _ from 'lodash';
import axios, { AxiosHeaders } from 'axios';

import {DADATA_TOKEN} from 'constants/DADATA';
import {
  lsGet,
  lsSet,
} from 'helper';

import {
  logoutUserWithMessage,
  needRefreshToken,
  refreshAccessToken,
} from 'models/user/helpers';

const HANDLE_STATUSES = [200, 201, 400, 401];

export const getToken = () => lsGet(`accessToken`) || ``;

const api = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL || `http://localhost:3001`,
  headers: {
    Accept: `application/json`,
  },
  validateStatus: status => _.includes(HANDLE_STATUSES, status),
});

api.interceptors.request.use(req => {
  req.headers.Authorization = getToken();

  if (_.includes(req.url, `suggestions.dadata.ru`)) {
    req.headers.Authorization = `Token ${DADATA_TOKEN}`;
  }

  return req;
});

api.interceptors.response.use(response => {
  if (response.status === 401 && response.config?.url !== `/auth/login`) {
    localStorage.clear();
    logoutUserWithMessage();
  }

  if (needRefreshToken()) refreshAccessToken();

  return response;
}, error => {
  if (_.get(error, `response.data.error`)) return Promise.reject(error.response.data.error);
  return Promise.reject(error);
});

export const setToken = (token: string | number | boolean | string[] | AxiosHeaders | null | undefined) => {
  lsSet(`accessToken`, token);
  api.defaults.headers.common.Authorization = token;
};

export const clearAuthHeaders = () => {
  delete api.defaults.headers.common.Authorization;
};

export default api;

