import * as React from 'react';
import {
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  getNumber,
  hasIntersection,
  prettifyAmount,
  prettifyNumber,
} from 'helper';

import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';
import {
  approve as approveAction,
  setDecision as setDecisionAction,
} from 'models/verifications/actions';

import {
  SaveOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
} from 'antd';
import { ILimitProps } from 'pages/PageClient/Single/Decision/components/ApproveTab/types';
import { useSendButtonDisabled } from 'pages/PageClient/Single/Decision/components/ApproveTab/useSendButtonDisabled';
import { DecisionFormWrapper } from 'pages/PageClient/Single/Decision/components/DecisionFormWrapper';
import {useDictionaries} from "models/dictionaries/hooks";
import {SUPER_ADMIN, VERIFICATION_SEND, VERIFICATION_WRITE} from "models/roles/constants";

interface IFormValues {
  approved: boolean;
  amount: number;
  comment: string;
  warrantyId: number;
  telemetryId: number;
  term: number;
}

interface IComponentProps {
  decisionHasBeenMade: boolean;
  isAbleToSend: boolean;
  isLoading: boolean;
  limitProps: ILimitProps;
  verification: any;
  verificationId: number;
}

export const ApproveForm: React.FC<IComponentProps> = ({
  decisionHasBeenMade,
  isAbleToSend,
  isLoading,
  limitProps,
  verification,
  verificationId,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    amountMaxRaw,
    amountMinRaw,
    termMaxRaw,
    termMinRaw,
  } = limitProps;

  const initialValues = {
    amount   : _.get(verification, `amount`, 0),
    approved   : _.get(verification, `approved`, false),
    comment  : _.get(verification, `comment`, ``),
    telemetryId: _.get(verification, `telemetryId`, null),
    term     : _.get(verification, `term`, 0),
    warrantyId: _.get(verification, `warrantyId`, null),
  };

  const [formValues, setFormValues] = React.useState<IFormValues>(initialValues);
  // @ts-ignore
  const [isDecisionLoading, setIsDecisionLoading] = useState<boolean>(false);
  const [isDictionariesLoading, dictionaries] = useDictionaries([`telemetry`, `warranty`]);

  const approve = () => {
    setIsDecisionLoading(true);
    dispatch(approveAction({
      callback: () => setIsDecisionLoading(false),
      id      : verificationId,
      details : {
        limit       : formValues.amount,
        telemetryId: formValues.telemetryId,
        term        : formValues.term,
        warrantyId   : formValues.warrantyId,
      },
    }));
  };
  const setDecision = async (decision: IFormValues, callback?: (() => void) | undefined) => {
    setIsDecisionLoading(true);
    // @ts-ignore
    dispatch(setDecisionAction({ id: verificationId, decision, callback }));
    // @ts-ignore
    if (!callback) setIsDecisionLoading(false);
  };

  const userRoles = useSelector(getRoleNamesSelector);

  const onSave = () => {
    setDecision({ ...formValues, approved: true });
  };

  const onSaveAndSend = () => {
    setDecision({ ...formValues, approved: true }, approve);
  };

  const approved = true;
  const amount = _.get(formValues, `amount`);
  const term = _.get(formValues, `term`);

  const formIncomplete = (!amount || !term);

  const isButtonsDisabled = isLoading || isDecisionLoading || isDictionariesLoading || formIncomplete;

  const isSaveButtonDisabled = decisionHasBeenMade;

  const isSendButtonDisabled = useSendButtonDisabled({
    approved,
    decisionHasBeenMade,
  });

  const onFormChange = (formName:string, info: { forms: { [x: string]: { getFieldsValue: () => React.SetStateAction<IFormValues>; }; }; }) => setFormValues(info.forms[formName].getFieldsValue());

  return (
    <DecisionFormWrapper>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          className='form'
          form={form}
          initialValues={initialValues}
          layout='vertical'
          name='PageClientSingleDecision'
          scrollToFirstError={{ behavior: `smooth` }}
          validateTrigger={[`onChange`, `onBlur`]}
        >
          <Form.Item
            label='Сумма одобренной кредитной линии, ₽'
            name='amount'
            rules={[
              {
                validator: (pass, v) => {
                  if (!v || v < amountMinRaw) {
                    return Promise.reject(new Error(`Не может быть меньше ${prettifyAmount(amountMinRaw)}`));
                  }
                  if (v > amountMaxRaw) {
                    return Promise.reject(new Error(`Не может быть больше ${prettifyAmount(amountMaxRaw)}`));
                  }
                  if (!_.isInteger(v)) {
                    return Promise.reject(new Error(`Только целочисленные значения`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              formatter={prettifyNumber}
              parser={getNumber}
            />
          </Form.Item>

          <Form.Item label='Срок одобрения кредитной линии, месяцев' name='term'>
            <Select options={
              _.map(
                _.filter(
                  [3, 12, 24, 36, 48, 60, 84],
                  v => v >= termMinRaw && v <= termMaxRaw,
                ),
                v => ({
                  label: `${v} мес.`,
                  value: v,
                }),
              )
            }
            />
          </Form.Item>

          <Form.Item label='Телематика' name='telemetryId'>
            <Select options={_.map(dictionaries.telemetry, v => ({ label: v.name, value: v.id }))} />
          </Form.Item>

          <Form.Item label='Доп. гарантия' name='warrantyId'>
            <Select options={_.map(dictionaries.warranty, v => ({ label: v.name, value: v.id }))} />
          </Form.Item>

          <Form.Item label='Комментарий' name='comment'>
            <Input.TextArea />
          </Form.Item>

          {hasIntersection(userRoles, [SUPER_ADMIN, VERIFICATION_SEND, VERIFICATION_WRITE]) && (
            <Form.Item
              className='form-controls form-controls_center'
            >
              <Space>
                <Button
                  className='form-controls-item'
                  disabled={isButtonsDisabled || isSaveButtonDisabled}
                  htmlType='submit'
                  icon={<SaveOutlined />}
                  onClick={() => form.validateFields().then(onSave)}
                  type='primary'
                >
                  Сохранить
                </Button>

                {isAbleToSend && (
                  <Popconfirm
                    disabled={isButtonsDisabled || isSendButtonDisabled}
                    okText='Одобрить'
                    onConfirm={() => form.validateFields().then(onSaveAndSend)}
                    title={(
                      <>
                        <p>Одобрить займ?</p>
                        <p>{`Одобренная кредитная линия, ₽ - ${prettifyNumber(formValues.amount)}`}</p>
                        <p>{`Срок, месяцев - ${formValues.term}`}</p>
                      </>
                    )}
                  >
                    <Button
                      className='form-controls-item'
                      disabled={isButtonsDisabled || isSendButtonDisabled}
                      htmlType='submit'
                      icon={<SendOutlined />}
                      type='primary'
                    >
                      Сохранить и отправить
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            </Form.Item>
          )}
        </Form>
      </Form.Provider>
    </DecisionFormWrapper>
  );
};
