import _ from 'lodash';
import { createSelector } from 'reselect';

import { getClients as getClientsSelector } from 'models/clients/selectors';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { SALE_STATUS_NAMES } from 'models/verifications/constants';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(
  [getState, state => state],
  ({ items }, state) => {
    const clients = getClientsSelector(state);

    return _.map(items, item => {
      if (item.application) return item;
      return {
        ...item,
        application: _.find(
          _.get(
            _.get(clients, _.get(item, `personId`)) || {},
            `applications`,
          ), { id: item.applicationId },
        ),
      };
    });
  },
);

export const getItem = createSelector(
  [getItems, (state, id) => id],
  (items, id) => _.find(items, { id }) || {},
);

export const getItemsByIds = createSelector(
  [getItems, (state, ids) => ids],
  (items, ids) => _.filter(items, ({ id }) => _.includes(ids, id)),
);

export const getSearchString = createSelector(getState, ({ searchString }) => searchString);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const isItemsLoading = createSelector(getState, ({ isItemsLoading: _isItemsLoading }) => _isItemsLoading);

export const isLoaded = createSelector(getState, ({ isLoaded: _isLoaded }) => _isLoaded);

export const getTotal = createSelector(getState, ({ total }) => total);

export const getRequestParams = createSelector(
  [
    getState,
    (state, isSale) => isSale,
    state => getDictionarySelector(state, `verificationStatus`),
  ],
  (state, isSale, allVerificationStatuses) => {
    const params = _.pick(state, [
      `dateFrom`,
      `dateTo`,
      `fullName`,
      `page`,
      `pageSize`,
      `personId`,
      `userId`,
      `verificationStatusId`,
    ]);
    if (params.fullName) {
      params.fullName = _.replace(params.fullName, /[^А-ЯЁа-яё\d\s'.-]/g, ``);
    }
    if (params.page && params.pageSize) {
      const limit = params.pageSize;
      const offset = limit * (params.page - 1);
      delete params.page;
      delete params.pageSize;
      params.limit = limit;
      params.offset = offset;
    }
    if (isSale) {
      const saleVerificationStatusIds = _.reduce(allVerificationStatuses, (result, item) => {
        if (_.includes(SALE_STATUS_NAMES, item.name)) result.push(item.id);
        return result;
      }, []);
      if (_.isEmpty(params.verificationStatusId)) {
        params.verificationStatusId = saleVerificationStatusIds;
      } else {
        params.verificationStatusId = _.filter(
          params.verificationStatusId,
          id => _.includes(saleVerificationStatusIds, _.toNumber(id)),
        );
      }
    }
    return params;
  },
);

export const isResultsComplete = createSelector(
  [
    getItem,
    state => getDictionarySelector(state, `verificationResult`),
    state => getDictionarySelector(state, `verificationResultType`),
  ],
  (verification, _verificationResults, _verificationResultTypes) => {
    if (_.isEmpty(verification) || _.isEmpty(_verificationResults) || _.isEmpty(_verificationResultTypes)) return false;
    const scriptId = verification.scriptId || null;
    const { results } = verification;
    if (_.isEmpty(results)) return false;

    const verificationResultTypes = scriptId
      ? _.filter(_verificationResultTypes, ({ scriptIds }) => _.includes(scriptIds, scriptId))
      : _.reject(_verificationResultTypes, `scriptIds`);
    const verificationResults = _.filter(
      _verificationResults,
      ({ verificationResultTypeId }) => (
        _.includes(
          _.map(verificationResultTypes, `id`),
          verificationResultTypeId,
        )
      ),
    );

    let isComplete = true;
    _.each(verificationResultTypes, ({ fieldType, id }) => {
      // fieldType enum:
      // 1: Одиночный выбор
      // 2: Множественный выбор
      // 3: Текстовые поля
      if (!isComplete) return false;
      if (!_.has(results, id) || (fieldType === 1 && !results[id]) || (fieldType === 2 && _.isEmpty(results[id]))) {
        isComplete = false;
        return;
      }
      if (fieldType === 3) {
        const verificationResultsToCheck = _.filter(verificationResults, { verificationResultTypeId: id });
        _.each(verificationResultsToCheck, verificationResult => {
          if (!isComplete) return false;
          if (!_.has(results[id], verificationResult.id) || !results[id][verificationResult.id]) {
            isComplete = false;
          }
        });
      }
    });
    return isComplete;
  },
);

export const getResults = createSelector(getItem, i => _.get(i, `results`, {}));
