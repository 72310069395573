import _ from 'lodash';
import { createSelector } from 'reselect';

import { formatDate } from 'helper';

import { model } from './actions';
import {
  getFullName,
  getHasSupplement,
} from './helpers';

import {
  APPLICATION_STATUS_WEIGHTS,
} from './constants';

export const getState = state => _.get(state, model, {});

export const getClients = createSelector(getState, ({ clients }) => clients);

export const getSearchClients = createSelector(
  getState,
  ({ clients, searchClientPersonIds }) => {
    const selectedClients = _.pick(clients, searchClientPersonIds);
    return _.sortBy(_.values(selectedClients), `personId`);
  },
);

export const getClient = createSelector(
  [getClients, (state, personId) => personId],
  (clients, personId) => clients[personId] || {},
);

export const getLoadedClientsPersonIds = createSelector(getState, ({ loadedPersonIds }) => loadedPersonIds);
export const isClientLoaded = createSelector(
  [getLoadedClientsPersonIds, (state, personId) => personId],
  _.includes,
);

export const getClientActiveEmployments = createSelector(
  getClient,
  client => _.filter(client.organization, `organizationActive`),
);

export const getClientActiveEmployment = createSelector(getClientActiveEmployments, _.first);

export const getClientFullName = createSelector(getClient, getFullName);

export const getClientSurname = createSelector(
  getClient,
  client => client.surname,
);

export const getClientApplications = createSelector(
  getClient,
  client => client.applications || [],
);

export const getClientApplicationsForDocs = createSelector(
  getClientApplications,
  applications => _.orderBy(applications,
    ({
       applicationCloseDtm,
       applicationDtm,
       applicationStatusId,
     }) => ((APPLICATION_STATUS_WEIGHTS[(applicationStatusId === 7 && applicationCloseDtm)
      ? 777
      : applicationStatusId] * 10000000000000) + applicationDtm),
    `desc`),
);

export const getClientApplicationsForCreditHistory = createSelector(
  getClientApplicationsForDocs,
  applications => _.filter(applications, ({ applicationStatusId }) => _.includes([7, 11, 12], applicationStatusId)),
);

export const getClientApplicationsSize = createSelector(getClientApplications, _.size);

export const getClientLastApplication = createSelector(
  getClientApplications,
  applications => _.maxBy(applications, `applicationDtm`),
);

export const getIsLoadingApplications = createSelector(getState, ({ isLoadingApplications }) => isLoadingApplications);

export const getClientLoans = createSelector(
  getClient,
  client => _.orderBy(client?.loans || [], `createDtm`, `desc`),
);

export const getClientAddresses = createSelector(
  [getClient, getClientApplications, getClientLoans, getIsLoadingApplications],
  (client, applications, loans, isLoadingApplications) => {
    if (isLoadingApplications) return [];
    const addresses = _.uniqWith(
      _.reduce(_.orderBy(applications, `applicationDtm`, `desc`), (result, {
        id: applicationId,
      }) => {
        const addressFact = _.find(client.addressesFact, { applicationId });
        const addressReg = _.find(client.addressesReg, { applicationId });

        const fact = _.trim(_.get(addressFact, `address`));
        const reg = _.trim(_.get(addressReg, `address`));
        const application = _.find(applications, { id: applicationId });
        const loan = _.find(loans, { applicationId });
        if (!fact && !reg) return result;
        return [
          ...result,
          {
            applicationDate: application?.applicationDtm ? formatDate(application.applicationDtm, `dd.MM.yyyy`) : `?`,
            applicationId,
            contractNumber : loan?.contractNumber || `?`,
            fact,
            factId         : addressFact?.addressId,
            reg,
            regId          : addressReg?.addressId,
          }];
      }, []),
      _.isEqual,
    );

    if (_.isEmpty(addresses) && client.addressFact && client.addressReg) {
      return [{
        applicationId  : `?`,
        applicationDate: `?`,
        contractNumber : `?`,
        fact           : client.addressFact,
        reg            : client.addressReg,
      }];
    }

    return addresses;
  },
);

export const getClientApplication = createSelector(
  [
    getClientApplications,
    (state, personId, applicationId) => applicationId,
  ],
  (applications, id) => _.find(applications, { id }) || {},
);

export const getClientApplicationDocs = createSelector(
  getClientApplication,
  application => _.get(application, `docs`, []),
);

export const getClientApplicationAuto = createSelector(
  getClientApplication,
  application => _.get(application, `auto`, {}),
);

export const getClientApplicationVector = createSelector(
  getClientApplication,
  application => _.get(application, `vector`, {}),
);

export const getClientCalibriChats = createSelector(
  getClient,
  client => _.get(client, `calibriChats`, []),
);

export const getClientCalibriChatHistory = createSelector(
  [getClientCalibriChats, (state, personId, chatId) => chatId],
  (calibriChats, chatId) => _.find(calibriChats, { chatId })?.history || [],
);

export const getClientSms = createSelector(
  getClient,
  client => _.get(client, `sms`, []),
);

export const getClientSmsByExternalIds = createSelector(
  [getClientSms, (state, personId, smsExternalIds) => smsExternalIds],
  (items, smsExternalIds) => _.filter(items, item => _.includes(smsExternalIds, item.smsExternalId)),
);

export const getCreditHistory = createSelector(
  [getClient, (state, id, applicationId) => applicationId],
  (client, applicationId) => _.get(client, `creditHistory.${applicationId}`, []),
);

export const isCreditHistoryLoaded = createSelector(
  getCreditHistory,
  _.negate(_.isEmpty),
);

export const getClientLoansSize = createSelector(getClientLoans, _.size);

export const getClientMarketingOffersRaw = createSelector(
  getClient,
  client => _.get(client, `marketingOffers`, []),
);

export const getClientMarketingOffers = createSelector(
  getClientMarketingOffersRaw,
  offers => _.map(
    _.orderBy(offers, [`closeDtm`, `startDtm`], [`desc`, `desc`]), offer => {
      let color;

      if (!offer.closeDtm && !offer.startDtm) {
        color = `white`;
      } else if (offer.closeDtm < Date.now()) {
        color = `gray`;
      } else if ((!offer.closeDtm || offer.closeDtm > Date.now()) && offer.startDtm < Date.now()) {
        color = `green`;
      } else if ((!offer.closeDtm || offer.closeDtm > Date.now()) && offer.startDtm > Date.now()) {
        color = `blue`;
      }

      return {
        ...offer,
        color,
      };
    },
  ),
);

export const getClientPhones = createSelector(
  getClient,
  client => _.get(client, `phones`, []),
);

export const getClientLoanById = createSelector(
  [
    getClientLoans,
    (state, personId, loanId) => loanId,
  ],
  (loans, id) => _.find(loans, { id }),
);

export const getClientLoanDetails = createSelector(
  getClientLoanById,
  loan => _.get(loan, `details`, {}),
);

export const getClientLoanByApplicationId = createSelector(
  [
    getClientLoans,
    (state, personId, applicationId) => applicationId,
  ],
  (loans, applicationId) => _.find(loans, { applicationId }),
);

export const getClientLoansWithApplications = createSelector(
  [getClientApplications, getClientLoans],
  (applications, loans) => _.map(loans, loan => {
    const application = _.find(applications, { id: loan.applicationId });
    if (application) {
      return {
        ...loan,
        application,
        key          : loan.id,
        hasSupplement: getHasSupplement(application),
      };
    }
    return { ...loan, key: loan.id };
  }),
);

export const getClientApplicationsWithLoans = createSelector(
  [getClientApplications, getClientLoans],
  (applications, loans) => _.map(applications, application => {
    const loan = _.find(loans, { applicationId: application.id });
    const hasSupplement = getHasSupplement(application);

    if (loan) {
      return {
        ...application,
        hasSupplement,
        key: application.id,
        loan,
      };
    }
    return {
      ...application,
      hasSupplement,
      key: application.id,
    };
  }),
);

export const getClientPhone = createSelector(
  getClient,
  client => _.get(client, `phone`),
);

export const getClientsRecent = createSelector(getState, ({ recent }) => recent);

export const getIsLoading = createSelector(getState, ({ isLoading }) => isLoading);

export const getIsLoadingSwitchProfile = createSelector(getState, ({ isLoadingSwitchProfile }) => (
  isLoadingSwitchProfile
));

export const getIsLoadingInfo = createSelector(getState, ({ isLoadingInfo }) => isLoadingInfo);

export const getIsLoadingPhones = createSelector(getState, ({ isLoadingPhones }) => isLoadingPhones);

export const getIsLoadingSignDocuments = createSelector(
  getState,
  ({ isLoadingSignDocuments }) => isLoadingSignDocuments,
);

export const getIsResetSmsLoading = createSelector(getState, ({ isLoadingResetSms }) => isLoadingResetSms);

export const getSearchString = createSelector(getState, ({ searchString }) => searchString);

export const getClientVerifications = createSelector(
  getClient,
  client => _.orderBy(_.get(client, `verifications`), verification => {
    const { applicationCloseDtm, applicationDtm, applicationStatusId } = _.get(verification, `application`, {});
    return ((APPLICATION_STATUS_WEIGHTS[(applicationStatusId === 7 && applicationCloseDtm)
      ? 777
      : applicationStatusId] * 10000000000000) + applicationDtm);
  }, `asc`),
);

export const getLegalRegistries = createSelector(
  getClient,
  client => _.orderBy(client?.legalRegistries || [], `createDtm`, `desc`),
);

export const getLegalDeads = createSelector(
  getClient,
  client => _.orderBy(client?.dead || [], `createDtm`, `desc`),
);

export const getLegalCourtResolutions = createSelector(
  getClient,
  client => _.orderBy(client?.courtResolutions || [], `createDtm`, `desc`),
);

export const getClientAuthCalls = createSelector(
  getClient,
  client => client?.authCalls || [],
);

export const getClientLoanOperations = createSelector(
  getClientLoanById,
  ({ operations }) => operations || [],
);
