import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  getClient as getClientAction,
  getClientApplications as getClientApplicationsAction,
  getClientLoans as getClientLoansAction,
  getClientPhones as getClientPhonesAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getIsLoading as isLoadingSelector,
} from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { EditClientModal } from 'pages/PageClient/Single/components/EditClientModal/EditClientModal';
import { EntityCard } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { TabContentWrapper } from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';
import { AdditionalPhones } from 'pages/PageClient/Single/Info/AdditionalPhones';
import Address from 'pages/PageClient/Single/Info/Address/Address';
import { WorkInfo } from 'pages/PageClient/Single/Info/WorkInfo';

import { Contact } from './Contact';
import Other from './Other';
import Passport from './Passport';

const Column = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media (max-width: ${breakpoints.lg}) {
    min-width: 100%;
    flex: 1;
  }

  @media (max-width: ${breakpoints.xl}) {
    margin-top: 8px;
  }

`;
const Row = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  margin-top: -16px;

  &+& {
    margin-top: 16px;
  }

  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    margin-top: -8px;

    &+& {
      margin-top: 8px;
    }
  }
`;

const ColumnPassport = styled(Column)`
  max-width: 600px;

  margin-top: -16px;
  ${EntityCard} {
    margin-top: 16px;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 700px;
  }

  @media (max-width: ${breakpoints.mm}) {
    width: unset;
    margin-top: -8px;
    ${EntityCard} {
      margin-top: 8px;
    }
  }

`;

const Wrapper = styled.div`
  display: flex;
  margin-right: -8px;
  margin-left: -8px;

  ${EntityCard} {
    padding-right: 8px;
    padding-left: 8px;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`;

const PageClientSingleInfo = () => {
  const dispatch = useDispatch();
  const { personId } = useContext(ClientMatchContext);

  const client = useSelector(state => getClientSelector(state, personId));
  const phoneTypeExternal = useSelector(state => getDictionarySelector(state, `phoneTypeExternal`));
  const isLoading = useSelector(state => isLoadingSelector(state));

  const getClientPhones = () => dispatch(getClientPhonesAction({ personId }));
  const getClient = callback => dispatch(getClientAction({ personId, callback }));
  const getDictionary = (entity, force = true) => dispatch(getDictionaryAction({ entity, force }));
  const getApplications = () => dispatch(getClientApplicationsAction({ personId }));
  const getLoans = () => dispatch(getClientLoansAction({ personId }));

  useEffect(() => {
    if (_.isEmpty(phoneTypeExternal)) getDictionary(`phoneTypeExternal`);
    if (client?.personId) getClientPhones();
  }, []);

  const onReload = () => {
    getClient(() => {
      getApplications();
      getLoans();
    });
  };

  const [editClientModalVisible, setEditClientModalVisible] = useState(false);
  const toggleEditClientModalVisible = () => setEditClientModalVisible(!editClientModalVisible);

  return (
    <ClientTab isReloading={isLoading} onReload={onReload}>
      <TabContentWrapper>
        <Wrapper>
          <ColumnPassport>
            <Passport
              openEditModal={toggleEditClientModalVisible}
              personId={personId}
            />
            <Other
              openEditModal={toggleEditClientModalVisible}
              personId={personId}
            />
          </ColumnPassport>

          <Column>
            <Row>
              <Column>
                <Contact
                  openEditModal={toggleEditClientModalVisible}
                  personId={personId}
                />
                <AdditionalPhones personId={personId} />
                <WorkInfo personId={personId} />
              </Column>

              <Column>
                <Address personId={personId} type='reg' />
                <Address personId={personId} type='fact' />
              </Column>
            </Row>
          </Column>
        </Wrapper>

        <EditClientModal
          isOpen={editClientModalVisible}
          personId={personId}
          toggleOpen={toggleEditClientModalVisible}
        />
      </TabContentWrapper>
    </ClientTab>
  );
};

export default PageClientSingleInfo;
