import React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';


import {
  PhoneOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

const PhoneButton = styled(Button)`
  border: none;
  color: ${palette.primary500Color};
  margin-top: -6px;
  margin-bottom: -6px;

  :disabled {
    color: ${palette.textDisabledColor};
  }
`;

interface IProps {
  className?: string,
  phone: string,
}

const MttPhoneButton: React.FC<IProps> = ({ className = ``, phone }) => {


  if (!phone) return null;

  return (
      <PhoneButton
        className={className}
        icon={<PhoneOutlined />}
        type='text'
      />
  );
};

export default MttPhoneButton;
