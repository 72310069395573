import { createAction } from 'redux-actions';

export const model = `admin`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const GET_PAYMENT_GATEWAYS = `GET_PAYMENT_GATEWAYS`;
export const GET_SHORT_LINK_SERVICES = `GET_SHORT_LINK_SERVICES`;
export const GET_SMS_GATEWAYS = `GET_SMS_GATEWAYS`;
export const PROCESS_TASKS = `PROCESS_TASKS`;
export const RESET = `RESET`;
export const RUN_TRIGGERS = `RUN_TRIGGERS`;
export const SEND_TEST_EMAILS = `SEND_TEST_EMAIL`;
export const SET = `SET`;
export const SET_LOADING = `SET_LOADING`;
export const SET_PAYMENT_GATEWAY_ACTIVE = `GET_PAYMENT_GATEWAY_ACTIVE`;
export const SET_SHORT_LINK_SERVICE_ACTIVE = `SET_SHORT_LINK_SERVICE_ACTIVE`;
export const SET_SMS_GATEWAY_ACTIVE = `SET_SMS_GATEWAY_ACTIVE`;
export const SWITCH_PAYMENT_GATEWAY = `SWITCH_PAYMENT_GATEWAY`;
export const SWITCH_SHORT_LINK_SERVICE = `SWITCH_SHORT_LINK_SERVICE`;
export const SWITCH_SMS_GATEWAY = `SWITCH_SMS_GATEWAY`;

export const getShortLinkServices = ca(GET_SHORT_LINK_SERVICES);
export const getSmsGateways = ca(GET_SMS_GATEWAYS);
export const processTasks = ca(PROCESS_TASKS);
export const runTriggers = ca(RUN_TRIGGERS);
export const reset = ca(RESET);
export const sendTestEmail = ca(SEND_TEST_EMAILS);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const setShortLinkServiceActive = ca(SET_SHORT_LINK_SERVICE_ACTIVE);
export const setSmsGatewayActive = ca(SET_SMS_GATEWAY_ACTIVE);
export const switchShortLinkService = ca(SWITCH_SHORT_LINK_SERVICE);
export const switchSmsGateway = ca(SWITCH_SMS_GATEWAY);
