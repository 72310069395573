import * as React from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';
import styled from 'styled-components';
import {
  palette,
  zIndex,
} from 'styles/theme';

import { MoreOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { horizontalPaddingMixin } from 'components/mixins/horizontal-padding-mixin';
import { HeaderLogo } from 'pages/Layout/Header/HeaderLogo';
import { HeaderNotificationBadge } from 'pages/Layout/Header/HeaderNotificationBadge';
import { MainNav } from 'pages/Layout/Header/helpers';
import { MenuDesktop } from 'pages/Layout/Header/MenuDesktop';
import SessionControl from 'pages/Layout/Header/SessionControl';

const Wrapper = styled.div`
  ${horizontalPaddingMixin};
  position: sticky;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  top: 0;
  background: ${palette.backgroundColor};
  z-index: ${zIndex.header};
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  margin-left: auto;
`;

const StyledHeaderLogo = styled(HeaderLogo)`
  padding-right: 24px;
`;

interface IComponentProps {
  activeTabPath: string;
  nav: MainNav;
  onPreferencesOpen():void;
}

export const HeaderDesktop: React.FC<IComponentProps> = ({ activeTabPath, nav, onPreferencesOpen }) => {
  const menuItems = _.map(nav, ({ name, path, testAttribute }) => (
    <Menu.Item data-test={testAttribute} key={path}>
      <Link to={path}>{name}</Link>
    </Menu.Item>
  ));

  return (
    <Wrapper>
      <StyledHeaderLogo to='/client' />
      <MenuDesktop
        mode='horizontal'
        overflowedIndicator={<MoreOutlined style={{ fontSize: `22px` }} />}
        selectedKeys={[activeTabPath]}
        style={{ flex: `auto`, minWidth: 0 }}
      >
        {menuItems}
      </MenuDesktop>
      <Controls>
        <HeaderNotificationBadge />
        <SessionControl onUserNameClick={onPreferencesOpen} />
      </Controls>
    </Wrapper>
  );
};
