import React, {
  useCallback,
  useMemo,
} from 'react';
import _ from 'lodash';

import { prettifyNumber } from 'helper';

import {
  IVector,
  IVectorTitle,
} from 'models/clients/types';

import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import {
  EntityCardRow,
  IconWithTooltip,
} from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';

import { getItemColor } from './helpers';
import {useDictionaries} from "models/dictionaries/hooks";
import { Spin } from 'antd';

interface IComponentProps {
  blockName: string;
  title: string;
  titleNote?: JSX.Element | string;
  vector: IVector;
}

const VectorCard: React.FC<IComponentProps> = ({
  blockName,
  title,
  titleNote,
  vector,
}) => {
  const [isDictionariesLoading, dictionaries] = useDictionaries([`vectorTitle`], true, true);
  const getValue = useCallback((fieldPath: string) => {
    return _.get(vector, fieldPath);
  }, [vector]);

  const fields: IVectorTitle[] = useMemo(
    () => _.filter<IVectorTitle>(dictionaries?.vectorTitle, { blockName }),
    [dictionaries?.vectorTitle, blockName],
  );

  const items = useMemo(() => _.sortBy(_.reject(_.map(fields, ({
    blockOrder,
    description,
    fieldPath,
    format,
    hidden,
    name,
    note,
  }) => {
    // @ts-ignore
    let value = getValue(fieldPath);
    if (_.isBoolean(value)) {
      value = _.toString(value);
    } else if (format === `amount`) {
      value = prettifyNumber(value);
    }

    return {
      blockOrder,
      color: getItemColor({ key: name, value }),
      hidden,
      name : description || name,
      note,
      value,
    };
  }), ({ hidden, value }) => _.isNil(value) || hidden), `blockOrder`), [fields, getValue]);

  return (
      <EntityCard>
        <Spin spinning={isDictionariesLoading} />
        <EntityCardTitle>
          {title}
          <IconWithTooltip
            // @ts-ignore
            text={titleNote}
          />
        </EntityCardTitle>
        <EntityCardContent>
          {_.map(items, ({ color, name, note, value }: { color: any; name: any; note: any; value: any; }, index) => (
            <EntityCardRow
              color={color}
              key={index}
              note={note}
              title={name}
            >
              {_.isNil(value) ? `` : value}
            </EntityCardRow>
          ))}
        </EntityCardContent>
      </EntityCard>
  );
};

export default VectorCard;
