export const SUPER_ADMIN = `superAdmin`;
export const APPEAL_OPERATOR = `appealOperator`;
export const CLIENT_EDIT = `clientEdit`;
export const CLIENT_PROFILE_CLOSER = `clientProfileCloser`;
export const DICTIONARY_EDITOR = `dictionaryEditor`;
export const DICTIONARY_VIEWER = `dictionaryViewer`;
export const DOCUMENT_UPLOADER = `documentUploader`;
export const DOCUMENT_TEMPLATE_ADMIN = `documentTemplateAdmin`;
export const EMAIL_TESTER = `emailTester`;
export const OFFER_UPLOADER = `offerUploader`;
export const PAYMENT_GATEWAY_OPERATOR = `paymentGatewayOperator`;
export const PHONE_EDIT = `phoneEdit`;
export const SHORT_LINK_OPERATOR = `shortLinkOperator`;
export const SIGN_DOCUMENT_CREATOR = `signDocumentCreator`;
export const SMS_DOWNLOADER = `smsDownloader`;
export const SMS_GATEWAY_OPERATOR = `smsGatewayOperator`;
export const SMS_LIST_SENDER = `smsListSender`;
export const SMS_SENDER = `smsSender`;
export const TEMPLATE_EDIT = `templateEdit`;
export const VERIFICATION_ADMIN = `verificationAdmin`;
export const VERIFICATION_READ = `verificationRead`;
export const VERIFICATION_SEND = `verificationSend`;
export const VERIFICATION_WRITE = `verificationWrite`;

export const ROLE_NAMES = {
  APPEAL_OPERATOR,
  CLIENT_EDIT,
  CLIENT_PROFILE_CLOSER,
  DICTIONARY_EDITOR,
  DICTIONARY_VIEWER,
  DOCUMENT_TEMPLATE_ADMIN,
  DOCUMENT_UPLOADER,
  EMAIL_TESTER,
  OFFER_UPLOADER,
  PAYMENT_GATEWAY_OPERATOR,
  PHONE_EDIT,
  SHORT_LINK_OPERATOR,
  SIGN_DOCUMENT_CREATOR,
  SMS_DOWNLOADER,
  SMS_GATEWAY_OPERATOR,
  SMS_LIST_SENDER,
  SMS_SENDER,
  SUPER_ADMIN,
  TEMPLATE_EDIT,
  VERIFICATION_ADMIN,
  VERIFICATION_READ,
  VERIFICATION_SEND,
  VERIFICATION_WRITE,
} as const;

export type TRoleName = typeof ROLE_NAMES[keyof typeof ROLE_NAMES] | string;
