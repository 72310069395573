/* eslint-disable max-len */
import React, { useContext } from 'react';
import _ from 'lodash';

import { formatDate } from 'helper';

import { getOfferInsurance } from 'models/clients/helpers';

import { Descriptions } from 'antd';
import MobileContext from 'pages/Layout/MobileContext';

const DETAILS = [
  {
    prop : `limit`,
    label: `Лимит`,
  },
  {
    prop : `term`,
    label: `Срок`,
  },
  {
    prop : `status`,
    label: `Статус`,
  },
  {
    prop : `payment`,
    label: `Платеж`,
  },
  {
    prop : `supplementId`,
    label: `Тип страховки`,
  },
  {
    prop : `supplementLimit`,
    label: `Сумма страховки`,
  },
  {
    prop : `chosen`,
    label: `Выбран`,
  },
];

const OfferDescription = ({ offer }) => {
  const mobile = useContext(MobileContext);

  const column = mobile ? 1 : _.size(DETAILS);
  const layout = mobile ? `horizontal` : `vertical`;

  const supplement = getOfferInsurance(offer);

  const offerNormalized = {
    ...offer,
    chosen         : offer.chosenFlg ? `Да` : `Нет`,
    term           : offer.loanTerm,
    limit          : offer.limit,
    status         : offer.statusName || offer.status,
    supplementLimit: supplement?.limit,
  };
  const title = `${offer.id}${offer.endDtm ? ` актуален до ${formatDate(offer.endDtm, `dd.MM.yyyy`)}` : ``}`;

  if (_.isEmpty(offer)) return null;
  return (
    <Descriptions
      bordered
      column={column}
      layout={layout}
      size='small'
      title={title}
    >
      {_.map(DETAILS, ({ label, prop }) => (
        <Descriptions.Item key={label} label={label}>{_.get(offerNormalized, prop)}</Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default OfferDescription;
