export const PERSISTED_DICTIONARIES = [
  `messageStatus`,
  `offerStatus`,
  `phoneType`,
];

export const PERSIST_TIMEOUT = 60 * 60 * 1000; // 1 hour

export const NAMES = {
  applicationFallback    : `application-fallback`,
  clientCharacteristic   : `client-characteristic`,
  creditHistoryField     : `credit-history-field`,
  documentAlias          : `document-alias`,
  documentGroup          : `document-group`,
  notificationList       : `notification-list`,
  phoneType              : `phone-type`,
  telemetry              : `telemetry`,
  vectorTitle            : `vector-title`,
  verificationLimit      : `verification-limit`,
  verificationResult     : `verification-result`,
  verificationResultType : `verification-result-type`,
  verificationStatus     : `verification-status`,
  warranty               : `warranty`,
};

export const EXTERNAL = {
  city                  : `dictionaries/city`,
  clientCondition       : `dictionaries/client-condition`,
  contractCondition     : `dictionaries/contract-condition`,
  documentTemplate      : `dictionaries/document-template/meta`,
  documentType          : `dictionaries/document-type/v1`,
  fileStatus            : `dictionaries/file-status`,
  gender                : `dictionaries/gender/v1`,
  marketingOfferType    : `dictionaries/marketing-offer-type`,
  offerStatus           : `dictionaries/offer-status`,
  operationCode         : `dictionaries/payment-gateway/operation-code`,
  operationStatus       : `dictionaries/operation-status/v1`,
  paymentMethod         : `dictionaries/payment-method`,
  phoneTypeExternal     : `dictionaries/phone-type`,
  product               : `dictionaries/product/v1`,
  region                : `dictionaries/region`,
  smsTemplate           : `sms/sms-template`,
};

export const EXTERNAL_CREATE_URLS = {
  smsTemplate: `sms/sms-template/create`,
};

export const EXTERNAL_UPDATE_URLS = {
  marketingOfferType: `dictionaries/marketing-offer-type/update`,
  smsTemplate       : templateId => `sms/sms-template/update/${templateId}`,
};

export const EXTERNAL_LINK_URLS = {
};

export const REQUISITE_TYPES = [
  {
    label: `Реквизиты за авто`,
    value: `main`,
  },
  {
    label: `Реквизиты за страховку`,
    value: `insurance`,
  },
  {
    label: `Допы за авто`,
    value: `additional`,
  },
];

export const FIELD_TYPES = [
  {
    label: `Одиночный выбор`,
    value: 1,
  },
  {
    label: `Множественный выбор`,
    value: 2,
  },
  {
    label: `Текстовые поля`,
    value: 3,
  },
];

export const DOC_GROUP_UPLOAD_BLACKLIST = [4];

export const CAMPAIGN_RIGHT_COLUMNS_MAP = {
  admin     : [`roleAdminId`],
  read      : [`roleReadId`, `roleWriteId`, `roleBatchLoadId`, `roleBatchCloseId`, `roleAdminId`],
  write     : [`roleWriteId`, `roleAdminId`],
  batchLoad : [`roleBatchLoadId`, `roleAdminId`],
  batchClose: [`roleBatchCloseId`, `roleAdminId`],
};

export const ENTITIES_WITH_NOT_UNIQUE_NAMES = [
  `verificationLimit`,
];
