import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { parseResponse } from 'api/helpers';
import {
  b64toBlob,
  getFileSize,
} from 'helper';

import { getFile } from 'models/fileStorage/api';

import useOnScreen from 'hooks/useOnScreen';

import {
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { IMessageFile } from 'components/Messenger/helpers';

const Action = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -4px;
  right: -4px;
  border-radius: 40px;
  border: 1px solid ${palette.borderAccentColor};
  background: ${palette.backgroundColor};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;

  :hover {
    border: 1px solid ${palette.primary500Color};
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin-right: 8px;
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Action} {
    transition: all 100ms ease;
    opacity: 1;

    @media(min-width: ${breakpoints.md}) {
      opacity: 0;
    }
  }

  :hover {
    ${Action} {
      opacity: 1;
    }

    img {
      filter: brightness(0.8);
    }
  }
`;

const IconWrapper = styled.div`
  .anticon {
    font-size: 16px;
    color: ${palette.primary500Color};
  }
`;

const Size = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  color: ${palette.whiteColor};

`;

const ImageWrapper = styled.div`
  background: ${palette.backgroundColor};
  border: 1px solid ${palette.borderDefaultColor};

  height: 64px;
  width: 64px;
  min-width: 64px;

  transition: all 200ms ease;

  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${Size} {
    opacity: 0;
  }

  :hover {
    border: 1px solid ${palette.borderAccentColor};
    background: ${palette.black10Color};
    cursor: zoom-in;

    ${Size} {
      opacity: 1;
    }
  }
`;

interface IComponentProps {
  file: IMessageFile;
  onClick: () => void;
  onDownloadFile: () => void;
  onFileLoaded: (file: any) => void;
  url: string;
}

export const MessageImageThumbnail: React.FC<IComponentProps> = ({
  file,
  onClick,
  onDownloadFile,
  onFileLoaded,
  url,
}) => {
  const ref = React.useRef();
  const isOnScreen = useOnScreen(ref);
  const [isLoaded, setIsLoaded] = useState(false);

  const title = file?.name || ``;

  useEffect(() => {
    async function fetchData() {
      // @ts-ignore
      if (url && isOnScreen && !isLoaded && !file.url) {
        const blob = parseResponse({
          dataPath    : `data.file`,
          defaultError: `При загрузке файла произошла ошибка`,
          response    : await getFile(url),
          normalize   : b64 => b64toBlob(b64, file.type),
        });

        onFileLoaded({ ...file, url: URL.createObjectURL(blob) });
        setIsLoaded(true);
      }
    }

    // eslint-disable-next-line no-console
    fetchData().then().catch(e => console.error(e));
    // @ts-ignore
  }, [url, isOnScreen, file.type, isLoaded, file.url]); // eslint-disable-line react-hooks/exhaustive-deps

  const fileSize = getFileSize(file?.size);

  return (
    // @ts-ignore
    <Wrapper ref={ref}>
      {
        // @ts-ignore
        !file.url ? (
        <Spin indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
      ) : (
        <>
          <ImageWrapper onClick={onClick}>
            <img
              alt={title}
              // @ts-ignore
              src={file.url}
            />
            <Size>{fileSize}</Size>
          </ImageWrapper>

          <Action onClick={e => {
            e.stopPropagation();
            onDownloadFile();
          }}
          >
            <DefaultTooltip title='Скачать файл'>
              <IconWrapper>
                <DownloadOutlined />
              </IconWrapper>
            </DefaultTooltip>
          </Action>
        </>
      )}
    </Wrapper>
  );
};
